import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { EventsService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class EventsStoreEffects {
  constructor(
    private dataService: EventsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        return this.dataService.load(action.payload).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { propertyId, request, onSuccess, onFailure } = action.payload;
        return this.dataService.create(propertyId, request).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(({ data }: any) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('event'),
              }),
              type: 'success',
            });
            return new featureActions.CreateSuccessAction({
              event: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const { propertyId, eventId, request } = action.payload;
        return this.dataService.update(propertyId, eventId, request).pipe(
          map(({ data }: any) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('event'),
              }),
              type: 'success',
            });
            return new featureActions.UpdateSuccessAction({
              event: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertyId, eventId },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(propertyId, eventId).pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('event'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSuccessAction({
                eventId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
