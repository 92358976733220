import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MetasearchStoreEffects } from './effects';
import { metasearchReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('metasearch', metasearchReducer),
    EffectsModule.forFeature([MetasearchStoreEffects]),
  ],
})
export class MetasearchStoreModule {}
