import { Rateplan } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<Rateplan> = createEntityAdapter<
  Rateplan
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Rateplan> {
  isLoading?: boolean;
  error?: any;
  rateplansLookup: Rateplan[];
  allAccommodationsRateplans: { [accommodation_id: number]: Rateplan[] };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  rateplansLookup: null,
  allAccommodationsRateplans: null,
});
