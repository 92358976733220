import { Injectable } from '@angular/core';

@Injectable()
export class TextEditorService {
  removeMentions(textValue: string): string {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = textValue;
    const mentionsHtml = <HTMLElement[]>(
      Array.from(wrapper.getElementsByClassName('mention'))
    );
    mentionsHtml.forEach((mentionHtml) => {
      const mentionTextHtml = document.createTextNode(
        mentionHtml.textContent.replace('@', ''),
      );
      let mentionLinkHtml;
      // let mentionTextHtml;
      // let mentionTagHtml;

      if (mentionHtml.children.item(0).children.item(1)) {
        mentionLinkHtml = mentionHtml.children.item(0).children.item(1);
      }
      mentionHtml.parentNode.insertBefore(
        mentionLinkHtml ? mentionLinkHtml : mentionTextHtml,
        mentionHtml,
      );
      mentionHtml.parentNode.removeChild(mentionHtml);
      mentionLinkHtml = null;
    });

    return (wrapper.innerHTML || '').replace(/@ /g, '');
  }

  addMentions(textValue: string, skipLink = false): string {
    if (!textValue) {
      return '';
    }

    const replaceFn = (word: string) => {
      if (word.includes('{{% ')) {
        return `<span class="mention" data-index="4" data-denotation-char="@" data-id="4" data-value='${word}'><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${word}</span></span>`;
      }
      return word;
    };

    textValue = textValue.replace(
      /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g,
      replaceFn,
    );

    let result = textValue;

    result = textValue.replace(/\{\{\%.*?\%\}\}/g, (word: string) => {
      if (skipLink || !word.includes('link')) {
        return `<span class="mention" data-index="4" data-denotation-char="@" data-id="4" data-value='${word}'><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${word}</span></span>`;
      }

      return word;
    });

    return result;
  }
}
