export const preselectGuestType = (
  guests: {
    arrival_date: string;
    departure_date: string;
    guest_type_id: number;
  }[],
  date: string,
): number => {
  const guestsToCompare = (guests || []).filter(
    ({ arrival_date }) => arrival_date === date,
  );
  if (!guestsToCompare.length) {
    return 1;
  }

  const existsBossFamily = guests.find((guest) => guest.guest_type_id === 2);
  if (existsBossFamily) {
    return 4;
  }
  const existsBossGroup = guests.find((guest) => guest.guest_type_id === 3);

  if (existsBossGroup) {
    return 5;
  }
  const existsSomeFamilyMember = guests.some(
    (guest) => guest.guest_type_id === 4,
  );
  if (!existsBossFamily && existsSomeFamilyMember) {
    return 2;
  }

  const existsSomeGroupMember = guests.some(
    (guest) => guest.guest_type_id === 5,
  );
  if (!existsBossGroup && existsSomeGroupMember) {
    return 3;
  }
  return 1;
};
