import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MobileListItemActionComponent } from './mobile-list-item-action.component';

@NgModule({
  declarations: [MobileListItemActionComponent],
  imports: [CommonModule],
  exports: [MobileListItemActionComponent],
})
export class MobileListItemActionModule {}
