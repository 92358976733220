import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { isNil } from 'lodash';

@Directive({
  selector: '[byInsertTemplate]',
})
export class InsertTemplateDirective {
  @Input() set byInsertTemplate(content: TemplateRef<any>) {
    setTimeout(() => {
      if (!content) {
        this.removeTemplate();
        return;
      }
      this.insertTemplate(content);
    });
  }

  @Input() byInsertTemplateBefore = false;

  index: number;

  constructor(
    public container: ViewContainerRef,
    public template: TemplateRef<any>,
  ) {
    this.createEmbeddedView();
  }

  removeTemplate() {
    if (!isNil(this.index)) {
      this.container.remove(this.index);
      this.index = null;
    }
  }

  createEmbeddedView() {
    this.container.createEmbeddedView(this.template);
  }

  insertTemplate(template: TemplateRef<any>) {
    this.removeTemplate();
    const viewRef = template.createEmbeddedView({});

    this.container.insert(viewRef, this.byInsertTemplateBefore ? 0 : 1);
    this.index = this.container.indexOf(viewRef);
  }
}
