<div nz-row style="padding: 0 10px">
  <div nz-col nzFlex="auto">
    <by-page-header [titleTemplate]="title"></by-page-header>
    <ng-template #title>
      <span style="font-size: 14px">
        {{ 'edit_total_price' | translate | capitalize }}
      </span>
    </ng-template>
  </div>
</div>
<form nz-form [nzLayout]="'vertical'">
  <div nz-row *ngIf="discountExists">
    <div nz-col>
      <div class="alert-box__list">
        <div class="alert-box__item">
          <i class="alert-box__icon fas fa-exclamation-triangle"></i>
          <span>{{ 'discount_warning_message' | translate | upperfirst }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div
      nz-row
      *ngIf="shoAddonsTotal"
      nzJustify="space-between"
      nzAlign="middle"
      class="row old-total"
    >
      <div nz-col>
        <span class="bolder">{{ 'bills' | translate | upperfirst }}</span>
      </div>
      <div nz-col>
        <by-currency-format
          class="color--grey-dark"
          [currencySymbol]="currencySymbol"
          [currencyValue]="addonsTotal"
        ></by-currency-format>
      </div>
    </div>

    <div
      nz-row
      nzJustify="space-between"
      nzAlign="middle"
      class="row old-total"
    >
      <div nz-col>
        <span class="bolder">{{
          'accommodations' | translate | upperfirst
        }}</span>
      </div>
      <div nz-col>
        <nz-input-number
          [formControl]="totalControl"
          [nzParser]="parserCurrency"
          [nzFormatter]="formatterCurrency"
          style="width: 80px"
          byDecimalOnly
          [nzMax]="9999999"
          [nzPrecision]="2"
          nzSize="small"
          nzStep="1"
          nzMin="0"
        >
        </nz-input-number>
      </div>
    </div>
    <div
      nz-row
      nzJustify="space-between"
      nzAlign="middle"
      class="row new-total"
    >
      <div nz-col>
        <span class="bolder">{{ 'total' | translate | upperfirst }}</span>
      </div>
      <div nz-col>
        <span
          *ngIf="+totalPriceWithoutDiscount > +totalControl.value"
          class="price-through color--grey-dark"
        >
          <by-currency-format
            class="by-fs-12"
            [currencySymbol]="currencySymbol"
            [currencyValue]="totalPriceWithoutDiscount"
          ></by-currency-format>
        </span>
        <by-currency-format
          class="bolder"
          [currencySymbol]="currencySymbol"
          [currencyValue]="totalControl.value + addonsTotal"
        ></by-currency-format>
      </div>
    </div>
  </div>
</form>
