import { Facility, FacilityCategories, FacilityGeneral } from '@app/models';

export interface State {
  isLoading?: boolean;
  error?: any;
  general: {
    accommodation: FacilityGeneral[];
    property: FacilityGeneral[];
    both: FacilityGeneral[];
    categories: FacilityCategories[];
  };
  details: {
    accommodation: Facility[];
    property: Facility[];
  };
}

export const initialState: State = {
  isLoading: false,
  error: null,
  general: {
    accommodation: [],
    property: [],
    both: [],
    categories: [],
  },
  details: {
    accommodation: [],
    property: [],
  },
};
