import { Component, Input, OnInit } from '@angular/core';
import {
  FacilitiesForCategoryObj,
  FacilityCategoriesObj,
  Media,
} from '@app/models';
import { DEFAULT_FACILITIES_LEGEND_COLORS } from '../default-legend-color';

@Component({
  selector: 'by-facilities-summary',
  templateUrl: './facilities-summary.component.html',
  styleUrls: ['./facilities-summary.component.scss'],
})
export class FacilitiesSummaryComponent implements OnInit {
  @Input()
  resourceName = '';

  @Input()
  facilitiesResources: FacilitiesForCategoryObj;

  @Input()
  resourceId: number;

  @Input()
  facilityCategoriesObj: FacilityCategoriesObj;

  @Input()
  media: Media;

  defaultFacilityLegendColor = DEFAULT_FACILITIES_LEGEND_COLORS;

  constructor() {}

  scollToCategory(categoryId: number) {
    const el = document.getElementById('categoryId' + categoryId.toString());
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnInit() {}
}
