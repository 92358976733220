import {
  FacilitiesType,
  Facility,
  FacilityCategories,
  FacilityGeneral,
} from '@app/models';
import { CreateAccommodationFacilitiesRequest } from '@app/models/requests/create-accommodation-facilities-request';
import { CreatePropertyFacilitiesRequest } from '@app/models/requests/create-property-facilities-request';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_GENERAL_REQUEST = '[Facilities] Load General Request',
  LOAD_GENERAL_FAILURE = '[Facilities] Load General Failure',
  LOAD_GENERAL_SUCCESS = '[Facilities] Load General Success',

  LOAD_CATEGORIES_REQUEST = '[Facilities] Load Categories Request',
  LOAD_CATEGORIES_FAILURE = '[Facilities] Load Categories Failure',
  LOAD_CATEGORIES_SUCCESS = '[Facilities] Load Categories Success',

  LOAD_RESOUCE_FACILITIES_REQUEST = '[Facilities] Load Resource Facilities Request',
  LOAD_RESOUCE_FACILITIES_FAILURE = '[Facilities] Load Resource Facilities Failure',
  LOAD_RESOUCE_FACILITIES_SUCCESS = '[Facilities] Load Resource Facilities Success',

  CREATE_PROPERTY_FACILITIES_REQUEST = '[Facilities] Create Property Facilities Request',
  CREATE_PROPERTY_FACILITIES_FAILURE = '[Facilities] Create Property Facilities Failure',
  CREATE_PROPERTY_FACILITIES_SUCCESS = '[Facilities] Create Property Facilities Success',

  CREATE_ACCOMMODATION_FACILITIES_REQUEST = '[Facilities] Create Accommodation Facilities Request',
  CREATE_ACCOMMODATION_FACILITIES_FAILURE = '[Facilities] Create Accommodation Facilities Failure',
  CREATE_ACCOMMODATION_FACILITIES_SUCCESS = '[Facilities] Create Accommodation Facilities Success',

  RESET_STATE = '[Facilities] Reset State',
}

export class CreateAccommodationFacilitiesRequestAction implements Action {
  readonly type = ActionTypes.CREATE_ACCOMMODATION_FACILITIES_REQUEST;
  constructor(
    public payload: {
      accommodationId: number;
      data: CreateAccommodationFacilitiesRequest;
    },
  ) {}
}

export class CreateAccommodationFacilitiesSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_ACCOMMODATION_FACILITIES_SUCCESS;
}

export class CreateAccommodationFacilitiesFailureAction implements Action {
  readonly type = ActionTypes.CREATE_ACCOMMODATION_FACILITIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreatePropertyFacilitiesRequestAction implements Action {
  readonly type = ActionTypes.CREATE_PROPERTY_FACILITIES_REQUEST;
  constructor(public payload: { data: CreatePropertyFacilitiesRequest }) {}
}

export class CreatePropertyFacilitiesSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_PROPERTY_FACILITIES_SUCCESS;
}

export class CreatePropertyFacilitiesFailureAction implements Action {
  readonly type = ActionTypes.CREATE_PROPERTY_FACILITIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadResourceFacilitiesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_RESOUCE_FACILITIES_REQUEST;
  constructor(
    public payload: { id: number; type: 'accommodation' | 'property' },
  ) {}
}

export class LoadResourceFacilitiesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_RESOUCE_FACILITIES_SUCCESS;
  constructor(
    public payload: {
      facilities: Facility[];
      type: 'accommodation' | 'property';
    },
  ) {}
}

export class LoadResourceFacilitiesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_RESOUCE_FACILITIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadGeneralRequestAction implements Action {
  readonly type = ActionTypes.LOAD_GENERAL_REQUEST;
  constructor(public payload: { type: FacilitiesType }) {}
}

export class LoadGeneralSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_GENERAL_SUCCESS;
  constructor(
    public payload: {
      facilityCategories: FacilityGeneral[];
      type: FacilitiesType;
    },
  ) {}
}

export class LoadGeneralFailureAction implements Action {
  readonly type = ActionTypes.LOAD_GENERAL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCategoriesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_REQUEST;
}

export class LoadCategoriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_SUCCESS;
  constructor(public payload: { facilityCategories: FacilityCategories[] }) {}
}

export class LoadCategoriesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | CreateAccommodationFacilitiesRequestAction
  | CreateAccommodationFacilitiesSuccessAction
  | CreateAccommodationFacilitiesFailureAction
  | CreatePropertyFacilitiesRequestAction
  | CreatePropertyFacilitiesSuccessAction
  | CreatePropertyFacilitiesFailureAction
  | LoadGeneralRequestAction
  | LoadGeneralSuccessAction
  | LoadGeneralFailureAction
  | LoadResourceFacilitiesRequestAction
  | LoadResourceFacilitiesSuccessAction
  | LoadResourceFacilitiesFailureAction
  | LoadCategoriesRequestAction
  | LoadCategoriesSuccessAction
  | LoadCategoriesFailureAction
  | ResetSuccessAction;
