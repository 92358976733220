import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsInArrayPipe } from '@core/pipe/isInArray.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@ui/ui.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';

import { BottomSheetModule } from '../components/bottom-sheet/bottom-sheet.module';
import { LookupModule } from '../components/lookup/lookup.module';
import { MobileListItemActionModule } from '../components/mobile-list-item-action/mobile-list-item-action.module';
import { QuestionModule } from '../components/question/question.module';
import { ResendReservationEmailModalModule } from '../components/resend-reservation-email-modal/resend-reservation-email-modal.module';
import { ReservationTotalsModule } from '../components/reservation-totals/reservation-totals.module';
import { TextEditorNewModule } from '../components/text-editor-new/text-editor-new.module';
import { AgeWarningDirective } from '../core/directives/age-warning.directive';
import { DisableControlDirective } from '../core/directives/disabled-control';
import { InsertTemplateDirective } from '../core/directives/insert-templatedirective';
import { TypeOfPipe } from '../core/pipe/typeOfPipe.pipe';
import { CrmEditTotalPriceFormComponent } from '../features/commons/crm/crm-edit-total-price-form/crm-edit-total-price-form.component';
import { CrmTotalPercentageDiscountFormComponent } from '../features/commons/crm/crm-total-percentage-discount-form/crm-total-percentage-discount-form.component';
import { ControlIsRequiredModule } from '../pipes/control-is-required/control-is-required.module';
import { HighlightSearchModule } from '../pipes/highlight-search/highlight-search.module';
import { HighlightSearch } from '../pipes/highlight-search/highlight-search.pipe';

import { CustomMenuFormModalComponent } from './custom-menu-form-modal/custom-menu-form-modal.component';
import { CustomizableTableComponent } from './customizabe-table/customizable-table.component';
import { EditableLabelComponent } from './editable-label/editable-label.component';
import { FacilitiesContainerComponent } from './facilities/facilities-container/facilities-container.component';
import { FacilitiesFormComponent } from './facilities/facilities-form/facilities-form.component';
import { FacilitiesSummaryCategoryComponent } from './facilities/facilities-summary-category/facilities-summary-category.component';
import { FacilitiesSummaryComponent } from './facilities/facilities-summary/facilities-summary.component';
import { FacilityComponent } from './facilities/facility/facility.component';
import { FiltersModule } from './filters/filters.module';
import { ImageTextExtractComponent } from './image-text-extract/image-text-extract.component';
import { MediaPreviewModalComponent } from './media-preview-modal/media-preview-modal.component';
import { ModalInvoiceSendEmailComponent } from './modal-invoice-send-email/modal-invoice-send-email.component';
import { NameDescriptionLanguagesFormComponent } from './name-description-languages-form/name-description-languages-form.component';
import { NgPipesModule } from './ng-pipes/ng-pipes.module';
import { PermissionComponent } from './permission/permission.component';
import { PermissionsListComponent } from './permissions-list/permissions-list.component';
import { PlacesModule } from './places/places.module';
import { PropertiesSelectModule } from './properties-select/properties-select.module';

import { SendEmailModalService } from './send-email-modal/services/send-email-modal.service';
import { TableModule } from './table/table.module';
import { TextEditorService } from './tex-editor/services/text-editor.service';
import { TextEditorComponent } from './tex-editor/text-editor.component';
import { SectionBoxComponent } from '../ui/section-box/section-box.component';
import { CameraPermissionInfoComponent } from '@app/components/camera-permission-info/camera-permission-info.component';

const SHARED_COMPONENTS = [
  EditableLabelComponent,
  IsInArrayPipe,
  FacilitiesContainerComponent,
  FacilitiesFormComponent,
  FacilityComponent,
  FacilitiesSummaryComponent,
  NameDescriptionLanguagesFormComponent,
  PermissionComponent,
  PermissionsListComponent,
  ModalInvoiceSendEmailComponent,
  MediaPreviewModalComponent,
  DisableControlDirective,
  InsertTemplateDirective,
  AgeWarningDirective,
  TypeOfPipe,
  CrmEditTotalPriceFormComponent,
  CrmTotalPercentageDiscountFormComponent,
  ImageTextExtractComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    NgPipesModule,
    RouterModule,
    ReactiveFormsModule,
    PlacesModule,
    FormsModule,
    FiltersModule,
    ColorPickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DragDropModule,
    PropertiesSelectModule,
    LookupModule,
    CreditCardDirectivesModule,
    BottomSheetModule,
    MobileListItemActionModule,
    TableModule,
    QuestionModule,
    ReservationTotalsModule,
    ResendReservationEmailModalModule,
    WebcamModule,
    TextEditorNewModule,
    HighlightSearchModule,
    ControlIsRequiredModule,
    SectionBoxComponent,
    TextEditorComponent,
    CameraPermissionInfoComponent,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    FacilitiesSummaryCategoryComponent,
    NameDescriptionLanguagesFormComponent,
    CustomizableTableComponent,
    CustomMenuFormModalComponent,
    DisableControlDirective,
  ],
  exports: [
    ...SHARED_COMPONENTS,
    CustomizableTableComponent,
    PropertiesSelectModule,
    LookupModule,
    HighlightSearch,
  ],
  providers: [TextEditorService, SendEmailModalService, provideNgxMask()],
})
export class SharedModule {}
