import { AfterViewInit, Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements AfterViewInit {
  @Input() set disableControl(condition: boolean) {
    this.action = condition ? 'disable' : 'enable';
    this.action === 'disable'
      ? this.ngControl?.control?.disable()
      : this.ngControl?.control?.enable();
  }

  action: 'enable' | 'disable' = 'enable';

  ngAfterViewInit() {
    this.action === 'disable'
      ? this.ngControl?.control?.disable()
      : this.ngControl?.control?.enable();
  }

  constructor(private ngControl: NgControl) {}
}
