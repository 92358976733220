import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  ICoreState,
  selectAllProperties,
} from '../../core/+state/core.reducer';
import { CustomersService } from '../../services';
import { ReservationDetailsCustomActions } from '../reservation-details-custom-store';

import * as featureActions from './actions';
import { effectHooks } from '../../helpers';
import { Router } from '@angular/router';

@Injectable()
export class CustomersStoreEffects {
  constructor(
    private dataService: CustomersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private coreStore: Store<ICoreState>,
    private route: Router,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { properties, page, searchParams, order } = action.payload;
        return this.dataService
          .load(properties || [], searchParams || {}, page, order)
          .pipe(
            map(
              ({ data, meta }: any) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                  pagination: meta.pagination,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  loadDetailsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAILS_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) => {
        const { properties, customerId } = action.payload;
        return this.dataService.loadDetails(customerId, properties).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadDetailsSuccessAction({
                customerDetails: data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadDocumentTypesEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDocumentTypesRequestAction>(
        featureActions.ActionTypes.LOAD_DOCUMENT_TYPE_REQUEST,
      ),
      switchMap((action: featureActions.LoadDocumentTypesRequestAction) => {
        return this.dataService.loadDocumenetTypes().pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadDocumentTypesSuccessAction({
                customerDocumentTypes: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadDocumentTypesFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  loadCustomerTypesEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadCustomerTypesRequestAction>(
        featureActions.ActionTypes.LOAD_CUSTOMER_TYPES_REQUEST,
      ),
      switchMap((action: featureActions.LoadCustomerTypesRequestAction) => {
        return this.dataService.loadCustomerTypesRoot().pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadCustomerTypesSuccessAction({
                customerTypes: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadCustomerTypesFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  loadCategoriesEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadCustomerCategoriesRequestAction>(
        featureActions.ActionTypes.LOAD_CUSTOMER_CATEGORIES_REQUEST,
      ),
      switchMap(
        (action: featureActions.LoadCustomerCategoriesRequestAction) => {
          return this.dataService.loadCustomerCategories().pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadCustomerCategoriesSuccessAction({
                  customerCategories: data,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.LoadCustomerCategoriesFailureAction({
                  error,
                }),
              );
            }),
          );
        },
      ),
    ),
  );

  loadAddressTypesEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAddressTypesRequestAction>(
        featureActions.ActionTypes.LOAD_ADDRESS_TYPE_REQUEST,
      ),
      switchMap((action: featureActions.LoadAddressTypesRequestAction) => {
        return this.dataService.loadAddressTypesRoot().pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadAddressTypesSuccessAction({
                customerAddressTypes: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.LoadAddressTypesFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  searchRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SearchRequestAction>(
        featureActions.ActionTypes.SEARCH_REQUEST,
      ),
      withLatestFrom(this.coreStore.pipe(select(selectAllProperties))),
      switchMap(
        ([action, allProperties]: [
          featureActions.SearchRequestAction,
          any[],
        ]) => {
          const { properties, value, ...params } = action.payload;
          if (!value || value === '') {
            return of(
              new featureActions.SearchSuccessAction({
                items: [],
              }),
            );
          }
          return this.dataService
            .searchLookup(
              (properties ||
                allProperties.map(({ id }) => id) ||
                []) as number[],
              value as string,
              params,
            )
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.SearchSuccessAction({
                    items: data,
                  }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(new featureActions.SearchFailureAction({ error }));
              }),
            );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({ payload: { customerId } }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(customerId).pipe(
            map(
              () =>
                new featureActions.DeleteSuccessAction({
                  customerId,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const {
          request,
          customer_id,
          attachToReservation,
          onSuccess,
          onFailure,
        } = action.payload;

        return this.dataService
          .updateCustomer(request, customer_id || request?.id)
          .pipe(
            effectHooks({ onSuccess, onFailure }),
            mergeMap(({ data }: any) => {
              if (!attachToReservation) {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.update_success',
                    {
                      param: this.translate.instant('customer'),
                    },
                  ),
                  type: 'success',
                });
              }
              let effects = [
                new featureActions.UpdateSuccessAction({
                  customer: data[0],
                }),
              ] as Action[];

              if (attachToReservation) {
                effects = [
                  ...effects,
                  ReservationDetailsCustomActions.attachGuestToReservationRequest(
                    {
                      data: attachToReservation,
                      actionType: 'edit',
                    },
                  ),
                ];
              }

              return effects;
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  updateGuestTyoeRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateGuestTypeRequestAction>(
        featureActions.ActionTypes.UPDATE_GUEST_TYPE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateGuestTypeRequestAction) => {
        const { guest_type_id, res_acc_room_guest_id, city_tax_exemption_id } =
          action.payload;
        return this.dataService
          .updateGuestType(
            guest_type_id,
            res_acc_room_guest_id,
            city_tax_exemption_id,
          )
          .pipe(
            map(({ data }: any) => {
              // this.notifications.push({
              //   title: this.translate.instant('done'),
              //   content: this.translate.instant('notifications.update_success', {
              //     param: this.translate.instant('customer'),
              //   }),
              //   type: 'success',
              // });
              return new featureActions.UpdateGuestTypeSuccessAction();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.UpdateGuestTypeFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  updatePrivacyEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdatePrivacyRequestAction>(
        featureActions.ActionTypes.UPDATE_PRIVACY_REQUEST,
      ),
      switchMap((action: featureActions.UpdatePrivacyRequestAction) => {
        const { customer_id, privacy_check, promotion_check } = action.payload;
        return this.dataService
          .updatePrivacy(customer_id, privacy_check, promotion_check)
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('privacy'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdatePrivacySuccessAction();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.UpdatePrivacyFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  createCustomerEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateCustomerRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      withLatestFrom(this.coreStore.pipe(select(selectAllProperties))),
      switchMap(
        ([action, allProperties]: [
          featureActions.CreateCustomerRequestAction,
          any[],
        ]) => {
          const { customer, property_id, attachToReservation, onComplete } =
            action.payload;
          return this.dataService
            .createCustomer(
              customer,
              (property_id ||
                allProperties.map(({ id }) => id) ||
                []) as number[],
            )
            .pipe(
              mergeMap(({ data }: any) => {
                if (onComplete) {
                  onComplete(data[0]);
                }

                let effects = [
                  new featureActions.CreateCustomerSuccessAction({
                    newCustomer: data[0],
                  }),
                ] as Action[];

                if (attachToReservation) {
                  effects = [
                    ...effects,
                    ReservationDetailsCustomActions.attachGuestToReservationRequest(
                      {
                        actionType: 'new',
                        data: {
                          ...attachToReservation,
                          customer_id: data[0].id,
                        },
                      },
                    ),
                  ];
                }
                return effects;
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  new featureActions.CreateCustomerFailureAction({ error }),
                );
              }),
            );
        },
      ),
    ),
  );

  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.UPLOAD_REQUEST),
      switchMap(
        ({
          payload: { file, properties, selectedDateFormat, onSuccess, onError },
        }: featureActions.UploadRequestAction) => {
          return this.dataService
            .upload(properties, file, selectedDateFormat)
            .pipe(
              map(() => {
                onSuccess();
                return new featureActions.UploadSuccessAction();
              }),
              catchError((error) => {
                onError();
                return of(
                  new featureActions.UploadFailureAction({
                    error,
                    uploadErrors: error.error.meta.errors,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.EXPORT_FILE_REQUEST),
      switchMap(({ payload }) => {
        return this.dataService.exportFile(payload).pipe(
          map(({ data: { export_ids } }) => {
            return new featureActions.ExportFileSucces({
              exportIdFile: export_ids[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.ExportFileError());
          }),
        );
      }),
    ),
  );
}
