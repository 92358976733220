<div class="title-custom-link" nz-row>
  {{ 'custom_menu_link' | translate | capitalize }}
</div>
<div class="custom-link-set">
  <ng-container *ngIf="!(linkCustom && linkCustom.length)">
    <span style="font-size: 14px">{{
      'no_link_custom_set' | translate | upperfirst
    }}</span>
  </ng-container>
  <div
    *ngIf="linkCustom && linkCustom.length"
    class="example-list"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      (click)="editLink(link)"
      cdkDrag
      *ngFor="let link of linkCustom"
      class="example-box"
    >
      <div class="icon-container">
        <i
          nz-tooltip
          [nzTooltipTitle]="link?.label"
          nzTooltipPlacement="bottom"
          [ngStyle]="{ color: link?.color }"
          style="cursor: move !important"
          class="{{ link?.icon }}"
        ></i>
        <div style="font-size: 9px" class="by-flex">
          <div class="key-fake-small">B</div>
          <div class="by-pt-5">+</div>
          <div class="key-fake-small">
            {{ link?.keyCombination | uppercase }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="linkCustom.length >= 5 && !isEdit; else formCustomLink">
  <nz-form-label>
    {{ 'max_custom_link_created' | translate | upperfirst }}
  </nz-form-label>
</ng-container>
<ng-template #formCustomLink>
  <div class="form-custom-link">
    <span
      ><b>{{
        (isEdit ? 'edit_link_custom' : 'create_link_custom')
          | translate
          | upperfirst
      }}</b>
    </span>

    <div class="by-flex by-flex-column by-w-100">
      <div nz-row class="contentFormRow">
        <div nz-col nzXs="24" nzSm="12">
          <nz-form-label nzRequired>
            {{ 'select_link_menu' | translate | upperfirst }}
          </nz-form-label>
        </div>
        <div nz-col nzXs="24" [nzSm]="{ span: 8, offset: 4 }">
          <nz-form-control [formGroup]="form" class="by-w-100">
            <nz-select
              [nzDisabled]="isEdit"
              nzSize="small"
              style="width: 160px"
              formControlName="link"
              (ngModelChange)="changeLink($event)"
            >
              <ng-container *ngFor="let link of linkMenuArrayEdit">
                <nz-option
                  [nzDisabled]="link?.disabled"
                  nzCustomContent
                  [nzLabel]="link?.label | translate | upperfirst"
                  [nzValue]="link?.link"
                >
                  <i *ngIf="link?.icon" class="{{ link?.icon }}"></i>
                  {{ link.label | translate | upperfirst }}</nz-option
                >
              </ng-container>
            </nz-select>
          </nz-form-control>
        </div>
      </div>
      <div nz-row class="contentFormRow">
        <div nz-col nzXs="24" nzSm="12">
          <nz-form-label nzRequired>
            {{ 'color' | translate | upperfirst }}
          </nz-form-label>
        </div>
        <div nz-col nzXs="24" [nzSm]="{ span: 6, offset: 6 }">
          <nz-form-control>
            <div
              class="color-picker-style by-mr-2"
              (colorPickerChange)="onChangeColor($event)"
              [cpOutputFormat]="'hex'"
              [cpAlphaChannel]="'disabled'"
              [cpPosition]="'bottom'"
              [colorPicker]="form.get('color').value"
              [style.background]="form.get('color').value"
              [cpOKButton]="true"
              cpOKButtonClass="color-picker-button-ok"
            ></div>
          </nz-form-control>
        </div>
      </div>
      <div nz-row class="contentFormRow">
        <div nz-col nzXs="24" nzSm="12">
          <nz-form-label nzRequired>
            {{ 'label' | translate | upperfirst }}
          </nz-form-label>
        </div>
        <div nz-col nzXs="24" nzSm="12">
          <nz-form-control [formGroup]="form">
            <input
              type="text"
              formControlName="label"
              nz-input
              placeholder="{{
                'placeholder.insert'
                  | translate: { param: 'label' | translate }
                  | upperfirst
              }}"
              style="width: 100%"
            />
          </nz-form-control>
        </div>
      </div>
      <div nz-row class="contentFormRow">
        <div nz-col nzXs="24" nzSm="12">
          <nz-form-label nzRequired>
            {{ 'key_combination' | translate | upperfirst }}
          </nz-form-label>
        </div>
        <div nz-col nzXs="24" [nzSm]="{ span: 10, offset: 2 }">
          <div class="by-flex">
            <div class="key-fake">B</div>

            <div class="by-mr-2px by-ml-5 by-pt-5">+</div>

            <div class="key-fake" *ngIf="isEdit">
              {{ form.get('keyCombination').value | uppercase }}
            </div>

            <nz-form-control nz-form [formGroup]="form">
              <input
                *ngIf="!isEdit"
                style="width: 100%"
                formControlName="keyCombination"
                nz-input
                type="text"
                maxlength="1"
                placeholder="{{
                  'placeholder.insert'
                    | translate: { param: 'key' | translate }
                    | upperfirst
                }}"
              />
            </nz-form-control>
          </div>
        </div>
      </div>
    </div>

    <div class="by-mt-2" nz-row>
      <nz-alert
        nzType="info"
        [nzMessage]="'info-key-combination' | translate | upperfirst"
        nzShowIcon
      ></nz-alert>
    </div>
    <div nz-row class="by-mt-2" nzAlign="middle" nzJustify="space-between">
      <div nz-col>
        <button
          *ngIf="isEdit"
          nz-button
          class="by-mt-2 by-mr-2"
          [disabled]="false"
          (click)="onDelete()"
          nzDanger
          nzType="primary"
        >
          {{ 'delete' | translate | upperfirst }}
        </button>
      </div>
      <div nz-col>
        <button
          *ngIf="isEdit"
          nz-button
          class="by-mt-2 by-mr-2"
          [disabled]="false"
          (click)="onCancel()"
          nzType="defautl"
        >
          {{ 'cancel' | translate | upperfirst }}
        </button>
        <button
          nz-button
          class="by-mt-2"
          [disabled]="isSubmitDisabled"
          (click)="onSubmit()"
          nzType="primary"
        >
          {{ 'save' | translate | upperfirst }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
