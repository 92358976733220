import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return featureAdapter.setAll(action.payload.items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_SUCCESS: {
      return featureAdapter.setAll(action.payload.items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_RATEPLANS_ACCOMMODATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_SUCCESS: {
      const { rateplans } = action.payload;
      return {
        ...state,
        allAccommodationsRateplans: rateplans,
      };
    }

    case ActionTypes.LOAD_ALL_ACCOMMODATIONS_RATEPLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_LOOKUP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_LOOKUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        rateplansLookup: action.payload.items,
        error: null,
      };
    }

    case ActionTypes.LOAD_LOOKUP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.rateplanId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.ORDER_RATEPLANS_ACCOMMODATIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.ORDER_RATEPLANS_ACCOMMODATIONS_SUCCESS: {
      return featureAdapter.map(
        (rateplan) => {
          const foundOrder = action.payload.rateplansIDS.findIndex(
            (id) => id === rateplan.id,
          );
          return {
            ...rateplan,
            order: foundOrder,
          };
        },
        {
          ...state,
          isLoading: false,
        },
      );
    }

    case ActionTypes.ORDER_RATEPLANS_ACCOMMODATIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
