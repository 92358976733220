import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { add } from 'date-fns';
import {
  castArray,
  flatten,
  flattenDeep,
  get,
  isEqual,
  map,
  omitBy,
  orderBy,
  uniq,
  values,
  without,
} from 'lodash';
import moment from 'moment';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { map as rxjsMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import {
  LoadAccommodationsRequest,
  LoadChannelsRequest,
} from '../+state/filters.actions';
import { selectFiltersFeature } from '../+state/filters.reducer';
import { selectAllProperties } from '../../../core/+state/core.reducer';
import { nzSelectAll } from '../../../core/helpers/nz-option-select-all';
import { generateParamArray } from '../../../core/helpers/params-generator';
import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { TokenService } from '../../../core/services/token.service';
import { IUser } from '../../../features/commons/users/models/user.model';
import { replaceAll } from '../../../helpers/replace-all';
import { BookingWidget, PlacesInputIds } from '../../../models';
import { SearchParams } from '../../../models/objects/search-params';
import { EventLabelPipe } from '../../../pipes/event-label/event-label.pipe';
import { BookingWidgetsLinksStoreSelectors } from '../../../root-store/booking-widgets-links-store';
import {
  CompaniesStoreActions,
  CompaniesStoreSelectors,
} from '../../../root-store/companies-store';
import {
  CustomersStoreActions,
  CustomersStoreSelectors,
} from '../../../root-store/customers-store';
import { DealersStoreSelectors } from '../../../root-store/dealers-store';
import {
  EventsStoreActions,
  EventsStoreSelectors,
} from '../../../root-store/events-store';
import {
  ExpensesCategoriesStoreActions,
  ExpensesCategoriesStoreSelectors,
} from '../../../root-store/expenses-categories-store';
import {
  InvoiceTypeStoreActions,
  InvoiceTypeStoreSelectors,
} from '../../../root-store/invoice-type-store';
import {
  InvoicesLayoutsStoreActions,
  InvoicesLayoutsStoreSelectors,
} from '../../../root-store/invoices-layouts-store';
import {
  MetasearchStoreActions,
  MetasearchStoreSelectors,
} from '../../../root-store/metasearch';
import { ModulesStoreSelectors } from '../../../root-store/modules-store';
import { PaymentMethodsStoreSelectors } from '../../../root-store/payment-methods-store';
import {
  PosStoreActions,
  PosStoreSelectors,
} from '../../../root-store/pos-store';
import { PropertyTypesStoreSelectors } from '../../../root-store/property-types-store';
import {
  RateplansStoreActions,
  RateplansStoreSelectors,
} from '../../../root-store/rateplans-store';
import {
  ResellersStoreActions,
  ResellersStoreSelectors,
} from '../../../root-store/resellers-store';
import {
  ReservationFromsStoreActions,
  ReservationFromsStoreSelectors,
} from '../../../root-store/reservation-froms-store';
import {
  ReservationTagStoreActions,
  ReservationTagStoreSelectors,
} from '../../../root-store/reservation-tag-store';
import { RootState } from '../../../root-store/root-state';
import {
  UsersStoreActions,
  UsersStoreSelectors,
} from '../../../root-store/users-store';
import { PlacesFormComponent } from '../../places/places-form/places-form.component';
import { PropertiesSelectService } from '../../properties-select/services/properties-select.service';
import { ICustomFilter } from '../models/custom-filter';

export type IFilterType =
  | 'accommodation'
  | 'accommodations'
  | 'rateplan'
  | 'payment_method_id'
  | 'payment_method_ids'
  | 'reservation_from'
  | 'property_pos_id'
  | 'rateplans'
  | 'rateplansByProperty'
  | 'channel_id'
  | 'module_id'
  | 'property_type_id'
  | 'tag_id'
  | 'dealers'
  | 'dealer'
  | 'dealerWithoutCustomer'
  | 'user_id'
  | 'events_id'
  | 'months'
  | 'customer_id'
  | 'company_id'
  | 'reseller_id'
  | 'extra'
  | 'invoice_layout_id'
  | 'expense_category_id'
  | 'property_id'
  | 'booking_widget_id'
  | 'place'
  | 'document_type_code_id'
  | 'document_type_id'
  | 'metasearch_id';

@Component({
  selector: 'by-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations: [
    trigger('animateFiltersBox', [
      state(
        'opened',
        style({
          transform: 'rotateX(0deg)',
          transformOrigin: 'top',
        }),
      ),
      state(
        'closed',
        style({
          transform: 'rotateX(90deg)',
          transformOrigin: 'top',
        }),
      ),
      transition('opened => closed', [animate(250)]),
      transition('closed => opened', [animate(250)]),
    ]),
  ],
  providers: [EventLabelPipe],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() filterSelectIsAbsolute;

  @Input() paramGetUrl: string;

  @Input('showFilter') set _showFilter(status: boolean) {
    this.filtersAreVisible = status;
    this.showFilter = status;
    this.filtersAnimationState = status ? 'opened' : 'closed';
  }

  @Input() skipSameSearch = false;

  @Input() toggleFilter = false;

  /**
   * @author Alessandro Rinaldi
   * @description l'input addWebsiteChannelFilter server per aggiungere alla select dei channel il canale Sito web per filtrare le prenotazioni di beddy
   * con Channel ID 10 e source: websitw .
   */
  @Input() addWebsiteChannelFilter = false;

  @Input('filters')
  set _filters(filters: IFilterType[]) {
    if (filters && filters.length) {
      this.filterSelect = [...this.filterSelect, ...filters];
    }

    const areRateplansRequired =
      filters.findIndex(
        (filter) => filter === 'rateplan' || filter === 'rateplans',
      ) !== -1;

    const isAccommodationRequired =
      filters.findIndex(
        (filter) => filter === 'accommodation' || filter === 'accommodations',
      ) !== -1;

    if (areRateplansRequired && !isAccommodationRequired) {
      filters.push('accommodation');
    }
    this.filters = uniq(filters || []);
    this.filters.forEach((filter: IFilterType) => {
      this.addFilterControl(filter);
    });

    this.onFilterSelectDefaultSetFunction(this.filterSelectDefault);
    this.setCallsMap();
    this.loadRequiredData();
  }

  @Input('propertiesIds')
  set _propertiesIds(propertiesIds: number[]) {
    this.propertiesIds = propertiesIds || [];
    this.setCallsMap();
    this.loadRequiredData();
  }

  @Input() set enabledExtras(extras: string[]) {
    this.form.patchValue({
      extra: extras,
    });
  }

  @Input() url: string;

  @Input('customFilters')
  set _customFilters(customFilters: ICustomFilter[]) {
    if (customFilters && customFilters.length) {
      this.filterSelect = [
        ...this.filterSelect,
        ...customFilters.map((val) => {
          const { label, label_translate } = val;
          return label_translate || label;
        }),
      ];
    }

    this.rangePickerKeys = (customFilters || [])
      .filter(({ type }) => type === 'range')
      .map(({ label }) => label);

    this.customFilters = uniq(customFilters || []);
    this.customFilters.forEach((filter: ICustomFilter) => {
      this.addCustomFilterControl(filter);
    });
    this.onFilterSelectDefaultSetFunction(this.filterSelectDefault);
  }

  @Input()
  enabledPlacesInputs = {
    country: true,
    state: true,
    county: true,
    city: true,
  };

  @Input()
  required: string[] = [];

  @Input('filterSelectDefault') set _filterSelectDefault(
    filterSelectedDefault,
  ) {
    this.filterSelectDefault = filterSelectedDefault;
    this.onFilterSelectDefaultSetFunction(filterSelectedDefault);
  }

  @Input() queryParamsToExcluded: string[] = [];

  @Input() documentTypesToInclude: number[] = [1, 5, 6, 7];

  @Output() reset = new EventEmitter();

  @Output() openChange = new EventEmitter<boolean>();

  @ViewChild(PlacesFormComponent)
  placesComponent: PlacesFormComponent;

  showValueInput = {};
  selectValue: any;

  filterSelect = [];
  filterSelectObj = {};
  noReload = false;

  months = [];

  selectForm: UntypedFormGroup;

  filtersAreVisible = true;

  showFilter = true;

  filtersAnimationState: 'opened' | 'closed' = 'opened';

  filterSelectDefault;

  keyAsArray = {
    accommodations: true,
    property_id: true,
    booking_widget_id: true,
    rateplans: true,
    channel_id: true,
    type: true,
    status: true,
    module_id: true,
    property_type_id: true,
  };

  keyAsNumber = {
    used: true,
    deposit: true,
    expired: true,
    is_group: true,
    payment_method_id: true,
    property_pos_id: true,
    tag_id: true,
    expense_category_id: true,
    invoice_layout_id: true,
    dealerWithoutCustomer: true,
  };

  readonly dataSelectValueChange = [
    {
      type: 'number',
      label: 'max_stay',
      id: 'max_stay',
    },
    {
      type: 'number',
      label: 'min_stay',
      id: 'min_stay',
    },
    {
      type: 'select',
      valueSelect: [
        { label: 'open', value: 0 },
        { label: 'close', value: 1 },
      ],
      label: 'cta',
      id: 'cta',
    },
    {
      type: 'select',
      valueSelect: [
        { label: 'open', value: 0 },
        { label: 'close', value: 1 },
      ],
      label: 'ctd',
      id: 'ctd',
    },
    {
      type: 'number',
      label: 'price',
      id: 'price',
    },
    {
      type: 'select',
      valueSelect: [
        { label: 'open', value: 0 },
        { label: 'close', value: 1 },
      ],
      label: 'room_barred',
      id: 'room_barred',
    },
    {
      type: 'number',
      label: 'availability',
      id: 'availability',
    },
  ];

  rangePickerKeys = [];

  customFilters: ICustomFilter[] = [];
  customFiltersView: ICustomFilter[] = [];

  propertiesIds: number[] = [];

  searchValueCompany: number = null;
  searchValueCustomer: number = null;
  searchValueCompanyName: string = null;
  searchValueCustomerName: string = null;

  customers: any;

  companies: any;

  @Output()
  search = new EventEmitter<any>();

  form: UntypedFormGroup;

  filtersData = new Map<string, any>();

  filters: IFilterType[] = [];

  filtersView: IFilterType[];

  private filtersLoaderMap: {};

  private parmAsUrl: any;
  private parmAsKey: any;

  dealers$ = this.store.pipe(select(DealersStoreSelectors.selectAllItems));

  modules$ = this.store.pipe(select(ModulesStoreSelectors.selectAllItems));

  propertyTypes$ = this.store.pipe(
    select(PropertyTypesStoreSelectors.selectAllItems),
  );

  reservationFroms$ = this.store.pipe(
    select(ReservationFromsStoreSelectors.selectAllReservationFromsItems),
  );

  dealerWithoutCustomer$ = this.store.pipe(
    select(DealersStoreSelectors.selectAllItemsWithoutCustomer),
  );

  bookingWidgets$ = this.store.pipe(
    select(BookingWidgetsLinksStoreSelectors.selectAllItems),
  );

  users$ = this.store.pipe(
    select(UsersStoreSelectors.selectAllUsersItems),
    rxjsMap((users: IUser[]) =>
      users.map(({ id, full_name, email }) => ({
        id,
        name: `${full_name} (${email})`,
      })),
    ),
  );

  resellers$ = this.store.pipe(
    select(ResellersStoreSelectors.selectAllItems),
    rxjsMap((resellers) =>
      resellers.map(({ id, name, company_name, surname }) => ({
        id,
        name,
        company_name,
        surname,
      })),
    ),
  );

  events$ = this.store.pipe(
    select(EventsStoreSelectors.selectAllEventsItems),
    rxjsMap((events) =>
      events?.map((event) => ({
        ...event,
        label: this.eventLabelPipe.transform(event),
      })),
    ),
  );

  invoiceTypes$ = this.store.pipe(
    select(InvoiceTypeStoreSelectors.selectAllItems),
  );

  paymentMethods$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
  );

  posStoreData$ = this.store.select(PosStoreSelectors.selectAllPosItems);

  invoiceLayouts$ = this.store.pipe(
    select(InvoicesLayoutsStoreSelectors.selectInvoicesLayouts),
  );

  expensesCategories$ = this.store.pipe(
    select(ExpensesCategoriesStoreSelectors.selectAll),
  );

  reservationTags$ = this.store.pipe(
    select(ReservationTagStoreSelectors.selectAllReservationTagItems),
  );

  allRateplans$ = this.store.pipe(
    select(RateplansStoreSelectors.selectRateplansLookup),
    rxjsMap((rateplans) => {
      if (!rateplans) {
        return {};
      }
      return rateplans.reduce((obj, rateplan) => {
        const type = rateplan.type;
        return (obj = { ...obj, [type]: [...get(obj, type, []), rateplan] });
      }, {});
    }),
  );

  metasearch$ = this.store.pipe(
    select(MetasearchStoreSelectors.selectAllItems),
  );

  oldSearchRequest;

  private subs = new SubSink();

  isAdmin = true;

  properties: Array<{ id: number; name: string }>;

  bookingWidgets: BookingWidget[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private store: Store<RootState>,
    private _dateFormatter: DateFormatterService,
    private tokenService: TokenService,
    private propertiesSelect: PropertiesSelectService,
    private eventLabelPipe: EventLabelPipe,
  ) {
    this.subs.add(
      this.propertiesSelect.propertiesChanges.subscribe((properties) => {
        this.properties = (properties || []).map(({ id, name }) => ({
          id,
          name,
        }));
      }),
    );
    this.isAdmin = !!this.tokenService.isAdmin();

    this.selectForm = this._formBuilder.group({
      filterSelected: [],
    });
    this.form = this._formBuilder.group({
      extra: [null],
      max_stay: [null],
      min_stay: [null],
      cta: [null],
      ctd: [null],
      price: [null],
      room_barred: [null],
      availability: [null],
    });
  }
  propertiesNames: { [id: number]: string } = {};

  hidePlacesFn = ({
    countryId,
  }: PlacesInputIds): {
    country: boolean;
    state: boolean;
    county: boolean;
    city: boolean;
  } => {
    return {
      country: true,
      state: !!countryId,
      county: !!countryId,
      city: !!countryId,
    };
  };

  onFilterSelectDefaultSetFunction(filterSelectedDefault) {
    if (!filterSelectedDefault || this.noReload) {
      return;
    }
    this.selectAllFilter(filterSelectedDefault);
    this.onSearch();
  }

  ngOnInit() {
    this.subs.add(
      this._activatedRoute.queryParams.subscribe((params) => {
        if (this.router.getCurrentNavigation()?.extras?.state?.fromInternal) {
          return;
        }
        this.parmAsUrl = params;
        this.parmAsKey = Object.keys(params).filter((param) => {
          param = param.replace('[]', '').replace('_from', '');

          return (
            !this.queryParamsToExcluded.includes(param) &&
            (this.filters.includes(param as IFilterType) ||
              this.customFilters.find(({ label }) => label === param))
          );
        });
        if (this.parmAsKey.length) {
          this.clear(false);
          this.setPatchValue();
          this.noReload = true;
          this.filtersAreVisible = this.showFilter;
          this.filtersAnimationState = 'opened';
        }
      }),
    );

    this.subs.add(
      this.store
        .pipe(select(CompaniesStoreSelectors.selectAllCompaniesItems))
        .subscribe((companies) => {
          if (this.searchValueCompany) {
            return;
          }
          this.companies = companies || [];
        }),
    );

    this.subs.add(
      this.bookingWidgets$.subscribe((data) => {
        this.bookingWidgets = data || [];
      }),
    );

    this.subs.add(
      this.store
        .pipe(select(CustomersStoreSelectors.selectAllCustomersItems))
        .subscribe((customers) => {
          if (this.searchValueCustomer) {
            return;
          }
          this.customers = customers || [];
        }),
    );

    this.subs.add(
      this.store
        .pipe(
          distinctUntilChanged((a, b) => isEqual(a, b)),
          select(selectFiltersFeature),
        )
        .subscribe((filtersData) =>
          map(filtersData, (value: any, key) => {
            if (key === 'rateplans') {
              const _allRateplan = value.filter(
                (offer) => offer.type !== 'bundle',
              );
              this.filtersData.set('_allRateplan', _allRateplan);
              const _rateplans = value.filter(
                (rateplan) => rateplan.type === 'plan',
              );
              const _offers = value.filter((offer) => offer.type === 'offer');
              this.filtersData.set('_rateplans', _rateplans);
              this.filtersData.set('_offers', _offers);
            }
            return this.filtersData.set(key, value);
          }),
        ),
    );

    this.handleAccommodationsChanges();

    this.handleChannelsChanges();

    this.createMonths();

    this.subs.add(
      this.store
        .pipe(select(selectAllProperties))
        .subscribe((propertiesNames) => {
          this.propertiesNames = propertiesNames.reduce(
            (properties, { id, name }: any) => {
              properties[+id] = name;
              return properties;
            },
            {},
          );
        }),
    );
  }

  onChangeRange(rangeName: string, customFilter: ICustomFilter) {
    const { ranges } = customFilter;
    this.form.get(customFilter.label).patchValue(ranges[rangeName]);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  resetCustomerCompany() {
    this.searchValueCompany = null;
    this.searchValueCustomer = null;
    this.searchValueCompanyName = null;
    this.searchValueCustomerName = null;
  }

  clear(emitEvent = true) {
    this.form.reset();

    if (this.placesComponent) {
      this.placesComponent.form.reset();
    }
    this.showValueInput = {};
    this.resetCustomerCompany();

    if (emitEvent) {
      this.onSearch();
      this.reset.emit();
    }
  }

  checkConditionQueryString(
    query: string,
    includesValue: string,
    replaceValue: string,
  ) {
    if (this.addWebsiteChannelFilter && query.includes(includesValue)) {
      return query.replace(includesValue, replaceValue);
    }
    return query;
  }

  onSearch(skipSearch = false) {
    const removeNullQueries = ([value]: [any]) =>
      get(value, 'length', false) === false || get(value, 'length', 0) > 0;

    const mapToQuery = ([value, filter]: [any, string]) => {
      if (value instanceof Date) {
        if (filter === 'date_month') {
          value = moment(value).month() + 1;
        } else if (filter === 'date_year') {
          value = moment(value).year();
        } else {
          value = this._dateFormatter.toServerFormat(value);
        }
      }

      if (this.rangePickerKeys.indexOf(filter) !== -1) {
        return `${filter}_from=${this._dateFormatter.toServerFormat(
          value[0],
        )}&${filter}_to=${this._dateFormatter.toServerFormat(value[1])}`;
      }

      return this.isFilterMultiselect(filter)
        ? `${generateParamArray(filter, flatten([value]))}`
        : `${filter}=${value}`;
    };
    const makeQueryStrings = (filters: string[]) =>
      filters
        .map((filter) => [this.form.get(filter).value, filter])
        .filter(([value]) => value !== null)
        .filter(removeNullQueries)
        .map(mapToQuery)
        .filter((filter) => filter !== null);

    const queries = [
      ...(this.filters || []).filter((filter) => filter !== 'extra'),
      ...this.customFilters
        .map(({ label }) => label)
        .filter((lab) => lab !== 'expired_from'),
      ...Object.keys(this.showValueInput),
      ...(this.isPlaceEnabled && this.form.get('city_id').value
        ? ['country_id', 'county_id', 'state_id', 'city_id']
        : []),
    ];

    const channelIdWithSource = 'channel_id[]=10&source=website';

    let queryString = makeQueryStrings(queries).reduce(
      (acc, query) =>
        `${acc}${acc.length === 0 ? '?' : '&'}${this.checkConditionQueryString(
          query,
          'channel_id[]=website',
          channelIdWithSource,
        )}`,
      '',
    );

    const dataFilter = omitBy(
      this.form.value,
      (i) => i === null || i === undefined || i === '',
    );

    const { expired_from, channel_id, dealerWithoutCustomer, ...newData } =
      dataFilter;

    let data = newData;

    if (dealerWithoutCustomer) {
      data = {
        ...data,
        dealer_id: dealerWithoutCustomer,
      };
    }

    if (channel_id) {
      if (channel_id.find((channelId) => channelId === 'website')) {
        data = { ...data, source: 'website' };
      }
      data = {
        ...data,
        channel_id: channel_id.map((channelId) =>
          channelId === 'website' ? 10 : channelId,
        ),
      };
    }

    let addQueryString = '';
    let addUrlString = '';
    if (expired_from && expired_from.length) {
      const expiredFrom = expired_from.split(',');
      const start_day = expiredFrom[1];
      const end_day = expiredFrom[0];
      data = {
        ...data,
        start_day,
        end_day,
      };
      if (queryString) {
        addQueryString += `&start_day=${start_day}&end_day=${end_day}`;
        addUrlString += `&expired_from=${expired_from}`;
      } else {
        addQueryString += `?start_day=${start_day}&end_day=${end_day}`;
        addUrlString += `?&expired_from=${expired_from}`;
      }
    }
    const result: any = map(data, (value, prop) => {
      if (value instanceof Date) {
        if (prop === 'date_month') {
          value = moment(value).month() + 1;
        } else if (prop === 'date_year') {
          value = moment(value).year();
        } else {
          value = this._dateFormatter.toServerFormat(value);
        }
      }
      return { prop, value };
    }).reduce((acc, curr) => {
      if (curr.value instanceof Array && curr.value.length === 0) {
        return acc;
      }
      acc[curr.prop] = curr.value;
      return acc;
    }, {});
    const { extra } = result;

    let newExtra;
    if (typeof extra === 'string') {
      newExtra = [extra];
    } else {
      newExtra = extra;
    }

    const extraParam = (newExtra || []).reduce(
      (acc: string, key: string) => (acc += `&extra[]=${key}`),
      '',
    );
    let urlString = replaceAll(
      `${queryString}${addUrlString}${extraParam}`,
      '#',
      '',
    );
    if (this.paramGetUrl) {
      urlString = `${urlString}${this.paramGetUrl}`;
    }
    if (this.searchValueCustomerName) {
      urlString = `${urlString}&searchValueCustomerName=${this.searchValueCustomerName}`;
    }
    if (this.searchValueCompanyName) {
      urlString = `${urlString}&searchValueCompanyName=${this.searchValueCompanyName}`;
    }
    const { filterSelected } = this.selectForm.value;
    if (filterSelected && filterSelected.length) {
      if (urlString) {
        urlString = `${urlString}&filterSelected=${filterSelected}`;
      } else {
        urlString = `?filterSelected=${filterSelected}`;
      }
    }

    if (this.url) {
      const newUrl = `${this.url}${urlString}`;
      this.router.navigateByUrl(newUrl, {
        replaceUrl: true,
        state: { fromInternal: true },
      });

      this._location.replaceState(newUrl);
    }

    if (addQueryString) {
      queryString += addQueryString;
    }

    const dataToSend = {
      queryString,
      data: result,
      urlString,
    };

    this.oldSearchRequest = dataToSend;

    if (
      (isEqual(this.oldSearchRequest, dataToSend) && this.skipSameSearch) ||
      skipSearch
    ) {
      this.oldSearchRequest = dataToSend;
      return dataToSend;
    }

    this.oldSearchRequest = dataToSend;
    this.search.emit(dataToSend);
    return dataToSend;
  }

  showValueSelected(value) {
    let newValue;
    if (typeof value === 'string') {
      newValue = [value];
    } else {
      newValue = value;
    }
    this.showValueInput = (newValue || []).reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    return Object.keys(this.showValueInput || {});
  }

  get isExtraActivated() {
    return (
      (this.filters || []).findIndex((filter) => filter === 'extra') !== -1
    );
  }

  get isPlaceEnabled() {
    return (this.filters || []).find((f) => f === 'place');
  }

  onPlaceChange({ countryId, stateId, countyId, cityId }) {
    this.form.patchValue({
      country_id: countryId,
      state_id: stateId,
      county_id: countyId,
      city_id: cityId,
    });
  }

  private setRequiredFields(): void {
    this.required.forEach((filter) => {
      if (this.form && this.form.get(filter)) {
        this.form.get(filter).setValidators(Validators.required);
      }
    });
  }

  private setPatchValue(): void {
    this.parmAsKey.forEach((key: string, index) => {
      let newKey = key;
      let value = this.parmAsUrl[key];

      if (key === 'filterSelected') {
        if (value) {
          this.selectAllFilter(value.split(','));
        }
      }
      if (key === 'searchValueCompanyName') {
        this.searchValueCompanyName = value;
        this.searchValueCompany = +this.parmAsUrl['customer_id'];
        this.companies = [{ id: this.searchValueCompany, name: value }];
        return;
      }
      if (key === 'searchValueCustomerName') {
        this.searchValueCustomerName = value;
        this.searchValueCustomer = +this.parmAsUrl['customer_id'];
        this.customers = [{ id: this.searchValueCustomer, name: value }];
        return;
      }
      if (key.includes('[]')) {
        newKey = key.replace('[]', '');
        if (key === 'extra[]') {
          value = this.showValueSelected(this.parmAsUrl[key]);
        }

        if (this.keyAsArray[newKey]) {
          value = this.parmAsUrl[key];
          value = +value || value;
          if (value instanceof Array) {
            value = value.map(Number);
          } else {
            value = [value];
          }
        } else {
          value = castArray(value).map((item) => +item || item);
        }
      }

      const rangeKey = newKey.replace('_from', '');
      if (this.rangePickerKeys.indexOf(rangeKey) !== -1) {
        const dateTo = this.parmAsUrl[rangeKey + '_to'];
        this.form
          .get(rangeKey)
          .patchValue([moment(value).toDate(), moment(dateTo).toDate()]);
        return;
      }

      if (
        this.keyAsNumber[newKey] ||
        !!get(this.parmAsUrl, 'extra[]', []).find((extra) => extra === newKey)
      ) {
        this.form.get(newKey).patchValue(+value);
        return;
      }

      if (this.form.get(newKey) && this.form.get(newKey)) {
        this.form.get(newKey).patchValue(value);
      }

      if (key === 'source' && this.form.get('channel_id')) {
        this.form.get('channel_id').patchValue([value]);
      }
    });

    this.onSearch();
  }

  private handleAccommodationsChanges() {
    const formControl =
      this.form.get('accommodations') || this.form.get('accommodation');
    if (!formControl) {
      return;
    }
    this.subs.add(
      formControl.valueChanges.pipe(debounceTime(150)).subscribe(() => {
        this.setCallsMap();
        this.loadRequiredData();
      }),
    );
  }

  private handleChannelsChanges() {
    const formControl = this.form.get('channel_id');
    if (!formControl) {
      return;
    }

    this.subs.add(
      formControl.valueChanges.subscribe((value) => {
        const channelWebsiteSelected = value?.findIndex(
          (channelId) => channelId === 'website',
        );
        if (channelWebsiteSelected > 0) {
          formControl.patchValue(['website'], { emitEvent: false });
        }

        if (channelWebsiteSelected === 0 && value.length > 1) {
          formControl.patchValue(
            value.filter((channelId) => channelId !== 'website'),
            { emitEvent: false },
          );
        }
      }),
    );
  }

  private loadRequiredData(): void {
    if (!this.propertiesIds || !this.propertiesIds.length) {
      return;
    }

    if (this.filters?.includes('metasearch_id')) {
      this.store.dispatch(
        MetasearchStoreActions.loadRequest({ properties: this.propertiesIds }),
      );
    }

    if (this.filters?.includes('events_id')) {
      this.loadEventsDefault();
    }

    if (
      (this.filters || []).findIndex(
        (filter) => filter === 'property_pos_id',
      ) !== -1
    ) {
      this.store.dispatch(
        new PosStoreActions.LoadRequestAction({
          propertyIDS: this.propertiesIds,
        }),
      );
    }

    if (
      (this.filters || []).findIndex(
        (filter) => filter === 'invoice_layout_id',
      ) !== -1
    ) {
      this.store.dispatch(
        new InvoicesLayoutsStoreActions.LoadRequestAction({
          propertiesIds: this.propertiesIds,
        }),
      );
    }

    if (
      (this.filters || []).findIndex(
        (filter) => filter === 'expense_category_id',
      ) !== -1
    ) {
      this.store.dispatch(
        ExpensesCategoriesStoreActions.loadRequest({
          propertiesIds: this.propertiesIds,
        }),
      );
    }

    if (
      (this.filters || []).findIndex((filter) => filter === 'reseller_id') !==
      -1
    ) {
      this.store.dispatch(ResellersStoreActions.loadRequest(null));
    }

    if (
      (this.filters || []).findIndex(
        (filter) => filter === 'reservation_from',
      ) !== -1
    ) {
      this.store.dispatch(new ReservationFromsStoreActions.LoadRequestAction());
    }

    if (
      (this.filters || []).findIndex((filter) => filter === 'tag_id') !== -1
    ) {
      this.store.dispatch(
        new ReservationTagStoreActions.LoadRequestAction({
          propertiesIDS: this.propertiesIds,
        }),
      );
    }

    if (
      (this.filters || []).findIndex(
        (filter) => filter === 'rateplans' || filter === 'rateplansByProperty',
      ) !== -1 &&
      (this.filters || []).findIndex(
        (filter) => filter === 'accommodations' || filter === 'accommodation',
      ) === -1
    ) {
      this.store.dispatch(
        new RateplansStoreActions.LoadLookupRequestAction({
          propertyIds: this.propertiesIds,
        }),
      );
    }
    (this.filters || [])
      .map((filter: IFilterType) => this.filtersLoaderMap[filter])
      .filter((action) => action)
      .forEach((action) => {
        const isActionValid = values(action.payload).every(
          ({ length }) => length,
        );
        return !isActionValid ? null : this.store.dispatch(action);
      });
  }

  private addFilterControl(filter: IFilterType) {
    if (this.filterAlreadyExists(filter)) {
      return;
    }
    if (filter === 'place') {
      this.form.addControl('country_id', this._formBuilder.control(''));
      this.form.addControl('state_id', this._formBuilder.control(''));
      this.form.addControl('county_id', this._formBuilder.control(''));
      this.form.addControl('city_id', this._formBuilder.control(''));
    }
    const isMultiSelect = this.isFilterMultiselect(filter);
    const filterControl = this._formBuilder.control(isMultiSelect ? [] : null);
    this.form.addControl(filter, filterControl);
  }

  private addCustomFilterControl(filter: ICustomFilter) {
    if (this.filterAlreadyExists(filter.label)) {
      if (!this.form.get(filter.label).dirty && filter?.data) {
        if (Array.isArray(filter?.data) && !filter?.data?.length) {
          return;
        }

        this.form.get(filter.label).setValue(filter?.data);
        this.form.get(filter.label).markAsDirty();
      }

      return;
    }

    const filterControl = this._formBuilder.control(filter.data);
    this.form.addControl(filter.label, filterControl);
  }

  private filterAlreadyExists(filter: string): boolean {
    return !!this.form.get(filter);
  }

  private isFilterMultiselect(filter: string): boolean {
    const foundInCustomFilters = this.customFilters.find(
      (customFilter) => customFilter.label === filter,
    );

    if (
      (get(this.form.get('extra'), 'value') || []).find(
        (extra) => extra === filter,
      )
    ) {
      return false;
    }

    if (
      filter.match(
        'dealerWithoutCustomer|country_id|state_id|county_id|city_id|customer_id|payment_method_id|property_pos_id|invoice_layout_id|expense_category_id|tag_id|user_id|reseller_id|events_id|document_type_code_id | document_type_id',
      ) &&
      filter !== 'payment_method_ids' &&
      filter !== 'metasearch_id'
    ) {
      return false;
    }

    if (
      foundInCustomFilters &&
      (foundInCustomFilters.selectMode === 'multiple' ||
        foundInCustomFilters.type === 'by-multiselect')
    ) {
      return true;
    }
    if (!foundInCustomFilters) {
      return true;
    }
    if (filter.match('status')) {
      return false;
    }
    return filter.slice(-1) === 's';
  }

  private setCallsMap() {
    this.setRequiredFields();
    let accommodationsIds: number[] =
      get(this.form.get('accommodation'), 'value', null) ||
      get(this.form.get('accommodations'), 'value', false);
    if (!accommodationsIds) {
      accommodationsIds = flattenDeep(
        this.propertiesIds.map((propertyId) =>
          get(this.filtersData.get('accommodations'), propertyId, []),
        ),
      ).map((accommodation) => accommodation.id);
    }
    this.filtersLoaderMap = {
      accommodation: new LoadAccommodationsRequest({
        propertiesIds: this.propertiesIds,
      }),
      accommodations: new LoadAccommodationsRequest({
        propertiesIds: this.propertiesIds,
      }),
      channel_id: new LoadChannelsRequest({
        propertiesIds: this.propertiesIds,
      }),
      reseller_id: ResellersStoreActions.loadRequest(null),
    };
  }

  public selectAllAccommodations(event) {
    const allAccommodation = [];

    Object.keys(this.filtersData.get('accommodations') || []).forEach(
      (propertyId) => {
        this.filtersData
          .get('accommodations')
          [propertyId].forEach((accommodation) => {
            allAccommodation.push(accommodation);
          });
      },
    );
    nzSelectAll(event, this.form, allAccommodation, 'accommodations', 'id');
  }
  public selectAllMonth(event) {
    nzSelectAll(event, this.form, this.months, 'months', 'value');
  }

  searchCustomerCompany(query: string, isCompany: boolean) {
    // this.resetCustomerCompany();
    if (isCompany) {
      this.searchValueCustomer = null;
      this.searchValueCustomerName = null;
      this.store.dispatch(
        new CompaniesStoreActions.SearchRequestAction({
          properties: this.propertiesIds,
          value: query,
        }),
      );
      return;
    }
    this.searchValueCompany = null;
    this.searchValueCompanyName = null;
    if (!query) {
      return;
    }
    this.store.dispatch(
      new CustomersStoreActions.SearchRequestAction({
        properties: this.propertiesIds,
        value: query,
      }),
    );
  }

  onSelectCustomer(customerId: number) {
    if (customerId === null) {
      this.resetCustomerCompany();
    } else {
      const customer = this.customers.find(({ id }) => id === customerId);

      const { name, surname, detail } = customer;
      this.resetCustomerCompany();
      this.searchValueCustomerName = name + ' ' + surname;
      this.companies = [];
    }
    this.form.patchValue({
      customer_id: customerId,
    });
  }

  onSelectCompnay(companyId: number) {
    let newCustomerId = companyId;
    if (companyId === null) {
      this.resetCustomerCompany();
    } else {
      const company = this.companies.find(
        (companyData) => companyData.id === companyId,
      );
      const { name, customer_id } = company;
      newCustomerId = customer_id;
      this.resetCustomerCompany();
      this.searchValueCompanyName = name;
      this.customers = [];
    }
    this.form.patchValue({ customer_id: newCustomerId });
  }

  createMonths() {
    this.months = [];
    for (let i = 1; i <= 12; i++) {
      let value = `0${i}`;
      if (i > 9) {
        value = `${i}`;
      }

      const label = this.translate.instant('month_code.' + value);
      this.months.push({ value, label });
    }
  }

  selectAllFilter(event) {
    if (!event) {
      return;
    }
    const newEvent = without(event, undefined);
    nzSelectAll(
      newEvent,
      this.selectForm,
      this.filterSelect.map((val) => ({ value: val })),
      'filterSelected',
      'value',
    );

    this.generateFilter();
  }

  generateFilter() {
    this.customFiltersView = [];
    this.filtersView = [];
    this.selectForm.get('filterSelected').value.forEach((filter) => {
      if ((this.filters || []).find((filterData) => filterData === filter)) {
        this.filtersView.push(filter);
      } else {
        const customFilterFind = this.customFilters.find(
          (filterData) =>
            filterData.label === filter ||
            filterData.label_translate === filter,
        );
        if (customFilterFind) {
          this.customFiltersView.push(customFilterFind);
        }
      }
    });
    if (!this.selectForm.get('filterSelected').value.length) {
      this.clear();
      this.onSearch();
    }
  }

  onChangeFiltersVisibility(status: boolean) {
    this.filtersAnimationState = status ? 'opened' : 'closed';
    this.openChange.emit(status);
  }

  animationHandler(animation, action: 'start' | 'done') {
    if (action === 'start' && animation.toState === 'opened') {
      this.filtersAreVisible = true;
    }
    if (action === 'done' && animation.toState === 'closed') {
      this.filtersAreVisible = false;
    }
  }

  onUsersSearch(value: string) {
    this.store.dispatch(
      new UsersStoreActions.SearchRequestAction({
        searchParams: { full_name: value },
      }),
    );
  }

  onEventSearch(searchParams: SearchParams) {
    this.store.dispatch(
      new EventsStoreActions.LoadRequestAction({
        property_id: this.propertiesIds,
        ...searchParams,
      }),
    );
  }

  loadEventsDefault() {
    const date_from = this._dateFormatter.toServerFormat(new Date());
    const date_to = this._dateFormatter.toServerFormat(
      add(new Date(), { years: 1 }),
    );

    this.onEventSearch({ date_to, date_from });
  }
}
