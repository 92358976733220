import { Rateplan } from '@app/models';
import { Dictionary } from '@ngrx/entity';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { groupBy, isEmpty } from 'lodash';

import { featureAdapter, State } from './state';
import { isAfter, parseISO } from 'date-fns';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getRateplansLookup = (state: State): Rateplan[] =>
  state.rateplansLookup;

export const selectRateplanState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('rateplans');

export const selectAllRateplansItems: (state: object) => Rateplan[] =
  featureAdapter.getSelectors(selectRateplanState).selectAll;

export const selectActivedRateplans = createSelector(
  selectAllRateplansItems,
  (rateplans: Rateplan[]) => {
    return rateplans.filter(({ periods }) => {
      return (
        isEmpty(periods) ||
        periods.some((period) => {
          return isAfter(parseISO(period.to), new Date());
        })
      );
    });
  },
);

export const selectRateplanById = (id: string) =>
  createSelector(selectAllRateplansItems, (rateplans: Rateplan[]) => {
    if (rateplans) {
      return rateplans.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectRateplansError: MemoizedSelector<object, any> =
  createSelector(selectRateplanState, getError);

export const selectRateplansIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectRateplanState, getIsLoading);

export const selectRateplansLookup: MemoizedSelector<object, Rateplan[]> =
  createSelector(selectRateplanState, getRateplansLookup);

export const selectRateplansPropertyLookup: MemoizedSelector<
  object,
  Dictionary<Rateplan[]>
> = createSelector(selectRateplanState, (state) => {
  return Object.keys(groupBy(getRateplansLookup(state), 'type')).reduce(
    (rateplans, type) => {
      return {
        ...rateplans,
        [type]: groupBy(
          getRateplansLookup(state).filter((r) => r?.type === type),
          'type',
        ),
      };
    },
    {},
  );
});

export const getRateplansLookupTypeByProperty =
  (propertyId: number) => (state: State) => {
    getRateplansLookup(state);
  };

export const selectAllAccommodationsRateplans: MemoizedSelector<
  object,
  { [accommodation_id: number]: Rateplan[] }
> = createSelector(
  selectRateplanState,
  (state: State) => state.allAccommodationsRateplans,
);
