import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { uniqBy } from 'lodash';

import {
  IReservationDataCustom,
  IReservationDetail,
} from '../../features/commons/reservation-details/models/reservation.model';
import { ReservationTotals } from '../../models';
import { ReservationGuest } from '../../models/objects/reservation-guest';

import { State } from './state';

/**
 * State selector
 */
export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('reservationDetailsCustomStore');

/**
 * Errors selector
 */
export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  (state: State) => state.error,
);

/**
 * Logs selector
 */
export const selectLogs: MemoizedSelector<object, any> = createSelector(
  selectState,
  (state: State) => state.logs,
);

/**
 * Logs warnings
 */
export const selectWarnings = createSelector(
  selectState,
  (state: State) => state.warnings,
);

export const selectGuestTypePreselect: MemoizedSelector<object, any> =
  createSelector(selectState, (state: State) => state.guestTypePreselected);

export const selectLogsActions: MemoizedSelector<object, any> = createSelector(
  selectState,
  (
    state: State,
  ): {
    name: string;
    value: string;
  }[] => {
    return uniqBy(
      state.logs.map((log) => {
        return {
          name: log.field_translate || 'operation_system',
          value: log.field || 'operation_system',
        };
      }),
      'value',
    );
  },
);

/**
 * Reservation totals selector
 */
export const selectReservationTotals: MemoizedSelector<
  object,
  ReservationTotals
> = createSelector(selectState, (state: State) => state.reservationTotals);

/**
 * Reservation accommodations medias
 */
export const selectAccommodationsMedias: MemoizedSelector<
  object,
  { [accommodationId: number]: any }
> = createSelector(selectState, (state: State) => state.accommodationsMedias);

/**
 * Reservation details selector
 */
export const selectReservationDetails: MemoizedSelector<
  object,
  IReservationDataCustom
> = createSelector(selectState, (state: State) => state.reservationDetails);

/**
 * Reservation all guests selector
 */
export const selectAllReservationGuests: MemoizedSelector<
  object,
  ReservationGuest[]
> = createSelector(selectState, (state: State) => state.allGuest);

/**
 * Reservation booker id selector
 */
export const selectReservationBookerId: MemoizedSelector<object, number> =
  createSelector(selectState, (state: State) => state.bookerId);

/**
 * Reservation tabs selector
 */
export const selectReservationTabs: MemoizedSelector<
  object,
  IReservationDetail[]
> = createSelector(selectState, (state: State) => state.tabsData);

/**
 * Select credit card details
 */
export const selectCrediCardDetails = createSelector(
  selectState,
  (state: State) => state.creditCard,
);

/**
 * Select original reservation_accommodations array from API.
 */
export const selectReservationAccommodations = createSelector(
  selectState,
  (state: State) => state.reservationAccommodations,
);

/**
 * START LOADING SELECTORS
 */

export const selectReservationTotalsLoading: MemoizedSelector<object, boolean> =
  createSelector(
    selectState,
    (state: State): boolean => state.reservationTotalsIsLoading,
  );

export const selectReservationLogsLoading: MemoizedSelector<object, boolean> =
  createSelector(
    selectState,
    (state: State): boolean => state.reservationLogsLoading,
  );

export const selectReservationDetailsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectState,
  (state: State): boolean => state.reservationDetailsLoading,
);

export const selectReservationDetailsAddRoomLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectState,
  (state: State): boolean => state.reservationDetailsAddRoomLoading,
);

/**
 * END LOADING SELECTORS
 */

/**
 * START EXPAND GUEST MAP SELECTOR
 */

export const selectExpandGuest: MemoizedSelector<
  object,
  Record<number | string, boolean>
> = createSelector(selectState, (state: State) => state.guestsExpandMap);

/**
 * END EXPAND GUEST MAP SELECTOR
 */

/**
 * START  GUEST TO SCROLL SELECTOR
 */

export const selectGuestToScroll: MemoizedSelector<object, number | string> =
  createSelector(selectState, (state: State) => state.guestToScroll);

/**
 * END GUEST TO SCROLL SELECTOR
 */
