<by-spin [nzSpinning]="emailTemplatesCustomIsLoading">
  <ng-container *ngIf="smsTemplateCustom?.length; else noTemplateEmail">
    <nz-table
      #billsData
      [nzData]="smsTemplateCustom"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="5"
    >
      <thead>
        <tr>
          <th>{{ 'name' | translate }}</th>
          <th [attr.colspan]="languages.length">{{ 'options' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of billsData.data">
          <td>{{ data.label }}</td>
          <ng-container *ngFor="let language of languages">
            <td
              class="pointer"
              (click)="loadTemplatePreview(data, language.iso_code)"
            >
              <span
                class="flag-icon flag-icon-{{ language.iso_3361_country_code }}"
              ></span>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</by-spin>

<ng-template #noTemplateEmail>
  <div class="text-align--center">
    <nz-alert
      nzType="warning"
      [nzMessage]="
        'no_template_message'
          | translate: { param: 'e-mail' | translate }
          | upperfirst
      "
      [nzDescription]="
        'warning_no_template_message'
          | translate: { param: 'e-mail' | translate }
          | upperfirst
      "
      nzShowIcon
    >
    </nz-alert>
    <div class="by-mt-4"></div>
    <a
      *ngIf="createTemplateEmail"
      href="configuration/email-templates?tab=1"
      target="_blank"
      class="ant-btn ant-btn-primary"
      (click)="onCreateTemplate()"
    >
      {{ 'warning_create_one_button' | translate | upperfirst }}
    </a>
    <button
      *ngIf="!createTemplateEmail"
      nz-button
      nzType="primary"
      (click)="onUpdateTemplateList()"
    >
      {{ 'update_list' | translate | upperfirst }}
    </button>
  </div>
</ng-template>
