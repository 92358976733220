import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SubSink } from 'subsink';

import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-crm-total-percentage-discount-form',
  templateUrl: './crm-total-percentage-discount-form.component.html',
  styleUrls: ['./crm-total-percentage-discount-form.component.scss'],
})
export class CrmTotalPercentageDiscountFormComponent
  implements OnInit, OnDestroy
{
  readonly nzDataModal: Partial<CrmTotalPercentageDiscountFormComponent> =
    inject(NZ_MODAL_DATA);

  @Input() totalPrice: number = this.nzDataModal.totalPrice || 0;

  @Input() totalPriceWithoutDiscount: number =
    this.nzDataModal.totalPriceWithoutDiscount || 0;

  @Input() currencySymbol: string = this.nzDataModal.currencySymbol || '';

  @Input() discountExists: boolean = this.nzDataModal.discountExists || false;

  @Input() set discount(data: {
    discount_value: number;
    discount_type_id: number;
  }) {
    this.form.patchValue(data, { emitEvent: false });
  }

  subs = new SubSink();

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      discount_value: [0, Validators.required],
      discount_type_id: [5, Validators.required],
    });
    this.discount = this.nzDataModal.discount;
  }

  ngOnInit() {
    this.subs.add(
      this.form.get('discount_value').valueChanges.subscribe(() => {
        this.totalPrice = this.newTotalPrice;
      }),
    );
    this.subs.add(
      this.form.get('discount_type_id').valueChanges.subscribe(() => {
        this.form.patchValue({ discount_value: 0 });
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  get discountType() {
    return this.form.get('discount_type_id').value;
  }

  get discountValue() {
    return this.form.get('discount_value').value;
  }

  get newTotalPrice() {
    const totalprice =
      this.discountType === 5
        ? this.totalPriceWithoutDiscount -
          this.totalPriceWithoutDiscount * (this.discountValue / 100)
        : this.totalPriceWithoutDiscount - this.discountValue;

    return +totalprice.toFixed(2);
  }
}
