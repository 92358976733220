import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  CustomerApiData,
  CustomerListItem,
  IAddressTypeRoot,
  ICustomerTypeRoot,
  IDocumentTypeRoot,
} from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getExportFileId = (state: State) => state.exportFileId;

export const getIsLoadingDetails = (state: State): boolean =>
  state.isLoadingDetails;

export const getDocumentTypes = (state: State): IDocumentTypeRoot[] =>
  state.documentTypes;

export const getCustomerTypes = (state: State): ICustomerTypeRoot[] =>
  state.customerTypes;

export const getCustomerCategories = (
  state: State,
): { id: number; name: string }[] => state.customerCategories;

export const getCustomerPagination = (state: State): IPagination =>
  state.pagination;

export const getCustomerDetails = (state: State): CustomerApiData =>
  state.customerDetails;

export const getUploadErrors = (state: State) => state.uploadErrors;

export const selectCustomerState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('customers');

export const selectAllCustomersItems: (state: object) => CustomerListItem[] =
  featureAdapter.getSelectors(selectCustomerState).selectAll;

export const selectCustomerById = (id: string) =>
  createSelector(selectAllCustomersItems, (customers: CustomerListItem[]) => {
    if (customers) {
      return customers.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectCustomersError: MemoizedSelector<object, any> =
  createSelector(selectCustomerState, getError);

export const selectCustomersIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCustomerState, getIsLoading);

export const selectCustomerDetailsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCustomerState, getIsLoadingDetails);

export const selectCustomersPagination: MemoizedSelector<object, IPagination> =
  createSelector(selectCustomerState, getCustomerPagination);
export const selectDocumnetTypes: MemoizedSelector<
  object,
  IDocumentTypeRoot[]
> = createSelector(selectCustomerState, getDocumentTypes);
export const selectCustomerTypes: MemoizedSelector<
  object,
  ICustomerTypeRoot[]
> = createSelector(selectCustomerState, getCustomerTypes);

export const selectAddressTypes: MemoizedSelector<object, IAddressTypeRoot[]> =
  createSelector(selectCustomerState, (state: State) => state.addressTypes);

export const selectCustomerCategories: MemoizedSelector<
  object,
  { id: number; name: string }[]
> = createSelector(selectCustomerState, getCustomerCategories);

export const selectCustomerDetails: MemoizedSelector<object, CustomerApiData> =
  createSelector(selectCustomerState, getCustomerDetails);

export const selectExportFileId = createSelector(
  selectCustomerState,
  getExportFileId,
);

export const selectUploadErrors: MemoizedSelector<
  object,
  { [row: string]: string[] }
> = createSelector(selectCustomerState, getUploadErrors);
