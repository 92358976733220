import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FacilityObj } from '@app/models';

@Component({
  selector: 'by-facilities-summary-category',
  templateUrl: './facilities-summary-category.component.html',
  styleUrls: ['./facilities-summary-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilitiesSummaryCategoryComponent implements OnInit {
  @Input()
  facilities: FacilityObj;

  observer: IntersectionObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.renderer.addClass(entry.target.firstElementChild, 'selected');
          setTimeout(() => {
            this.renderer.removeClass(
              entry.target.firstElementChild,
              'selected',
            );
            this.observer.unobserve(entry.target);
          }, 1000);
        }
      });
    },
  );

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  scollToFacility(facilityId: number) {
    const el = document.getElementById('facilityId' + facilityId.toString());
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.observer.observe(el);
  }
}
