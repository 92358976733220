import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILanguage } from '@app/core/models/api/generics/languages/language.model';
import { IEmailTemplateCustom } from '@app/models';
import { ILoadTemplatePreviewEventData } from '@app/shared/whatsapp-modal/models/load-template-preview-event-data.model';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgPipesModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SpinModule } from '../../../ui/spin/spin.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@Component({
  selector: 'by-send-email-modal-list',
  templateUrl: './send-email-modal-list.component.html',
  styleUrls: ['./send-email-modal-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgPipesModule,
    NzTableModule,
    SpinModule,
    NzGridModule,
    NzFormModule,
    NzEmptyModule,
    NgUpperFirstPipeModule,
    NzButtonModule,
    NzAlertModule,
  ],
})
export class SendEmailModalListComponent implements OnInit {
  @Input()
  propertySelectedLangIsoCode: string;

  @Input()
  smsTemplateCustom: IEmailTemplateCustom[];

  @Input()
  languages: ILanguage[];

  @Input()
  emailTemplatesCustomIsLoading: boolean;

  @Output()
  loadTemplatePreviewEvent: EventEmitter<ILoadTemplatePreviewEventData> =
    new EventEmitter<ILoadTemplatePreviewEventData>();

  @Output() reloadTemplateList = new EventEmitter();

  createTemplateEmail = true;

  constructor() {}

  ngOnInit() {}

  loadTemplatePreview(template: IEmailTemplateCustom, lang_iso_code: string) {
    const newData: ILoadTemplatePreviewEventData = {
      template,
      lang_iso_code,
      propertySelectedLangIsoCode: this.propertySelectedLangIsoCode,
    };
    this.loadTemplatePreviewEvent.emit(newData);
  }

  onCreateTemplate() {
    this.createTemplateEmail = false;
  }

  onUpdateTemplateList() {
    this.reloadTemplateList.emit();
  }
}
