import {
  FacilitiesForCategoryObj,
  FacilitiesType,
  Facility,
  FacilityCategories,
  FacilityCategoriesObj,
  FacilityGeneral,
  FacilityObj,
} from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

const facilityToObj = (facilities: Facility[]): FacilityObj => {
  return facilities.reduce((_acc, _curr) => {
    if (!_acc) {
      _acc = {};
    }
    const { id: _id } = _curr;
    _acc[_id] = <Facility>{
      ..._curr,
    };
    return _acc;
  }, {});
};


export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectFacilitiesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('facilities');

export const selectFacilitiesGeneral = (
  type: FacilitiesType,
): MemoizedSelector<object, FacilityGeneral[]> =>
  createSelector(
    selectFacilitiesState,
    (state: State) => {
      return state.general[type];
    },
  );

export const selectFacilitiesForCategoryObj = (
  type: FacilitiesType,
): MemoizedSelector<object, FacilitiesForCategoryObj> =>
  createSelector(
    selectFacilitiesGeneral(type),
    (facilitiesGeneral: FacilityGeneral[]) => {
      return <FacilitiesForCategoryObj>facilitiesGeneral
        .filter((item) => item.id)
        .reduce((acc, curr) => {
          const { id, facilities } = curr;
          acc[id] = <FacilityGeneral>{
            ...curr,
            facilities: facilityToObj(facilities),
          };
          return acc;
        }, {});
    },
  );

export const selectFacilitiesFromResource = (
  type: 'accommodation' | 'property',
): MemoizedSelector<object, Facility[]> =>
  createSelector(
    selectFacilitiesState,
    (state: State) => state.details[type],
  );

export const selectFacilityCategories: MemoizedSelector<
  object,
  FacilityCategories[]
> = createSelector(
  selectFacilitiesState,
  (state: State) => {
    return state.general.categories;
  },
);

export const selectFacilityCategoriesObj: MemoizedSelector<
  object,
  FacilityCategoriesObj
> = createSelector(
  selectFacilitiesState,
  (state: State) => {
    return state.general.categories.reduce((acc, category) => {
      acc = {
        ...acc,
        [category.id]: {
          ...category,
        },
      };
      return acc;
    }, {});
  },
);

export const selectFacilitiesFromResourceObj = (
  type: 'accommodation' | 'property',
): MemoizedSelector<object, FacilitiesForCategoryObj> =>
  createSelector(
    selectFacilitiesState,
    (state: State) => {
      return state.details[type].reduce(
        (acc, curr, index, facilities) => {
          const { category_id: id } = curr;
          acc[id] = <FacilitiesForCategoryObj>{
            id,
            facilities: facilityToObj(
              facilities.filter((facility) => facility.category_id === id),
            ),
          };
          return acc;
        },
        <FacilitiesForCategoryObj>{},
      );
    },
  );

export const selectFacilitiesError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectFacilitiesState,
  getError,
);

export const selectFacilitiesLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectFacilitiesState,
  getIsLoading,
);