<ng-container *ngIf="translations && permissionsForm">
  <by-permission
    *ngFor="let permission of permissionsForm | keyvalue; let first = first"
    [fieldToDisabled]="_permissions[permission.key]"
    [permissionName]="translations[permission.key]"
    [permission]="permission"
    [isFirstRow]="first"
    (valueChangeEvent)="checkAllPermissionsType()"
    (cascadeSelectionEvent)="cascadeSelection($event)"
  ></by-permission>
</ng-container>
<div nz-row  nzJustify="end">
  <button
    nz-button
    nzType="primary"
    (click)="save()"
  >
    {{ 'save' | translate | capitalize }}
  </button>
</div>
