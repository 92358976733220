import { AttachDetachRequest, IUserRoot, SearchParams } from '@app/models';
import { Action } from '@ngrx/store';

type SearchBy = 'full_name' | 'email';

export enum ActionTypes {
  LOAD_REQUEST = '[Users] Load Request',
  LOAD_FAILURE = '[Users] Load Failure',
  LOAD_SUCCESS = '[Users] Load Success',

  SEARCH_REQUEST = '[Users] Search Request',
  SEARCH_FAILURE = '[Users] Search Failure',
  SEARCH_SUCCESS = '[Users] Search Success',

  LOAD_ACL_REQUEST = '[Users] Load Acl Request',
  LOAD_ACL_FAILURE = '[Users] Load Acl Failure',
  LOAD_ACL_SUCCESS = '[Users] Load Acl Success',

  DELETE_REQUEST = '[Users] Delete Request',
  DELETE_FAILURE = '[Users] Delete Failure',
  DELETE_SUCCESS = '[Users] Delete Success',

  ATTACH_PROPERTY_REQUEST = '[Users] Attach Property Request',
  ATTACH_PROPERTY_FAILURE = '[Users] Attach Property Failure',
  ATTACH_PROPERTY_SUCCESS = '[Users] Attach Property Success',

  DETACH_PROPERTY_REQUEST = '[Users] Detach Property Request',
  DETACH_PROPERTY_FAILURE = '[Users] Detach Property Failure',
  DETACH_PROPERTY_SUCCESS = '[Users] Detach Property Success',

  RESET_STATE = '[Users] Reset State',
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { userId: number; propertyId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { userId: number }) {}
}

export class DetachPropertyRequestAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_REQUEST;
  constructor(
    public payload: { data: AttachDetachRequest[]; showNotification?: boolean },
  ) {}
}

export class DetachPropertyFailureAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DetachPropertySuccessAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_SUCCESS;
  constructor(
    public payload: {
      data?: AttachDetachRequest[];
      showNotification?: boolean;
    },
  ) {}
}

export class AttachPropertyRequestAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_REQUEST;
  constructor(
    public payload: { data: AttachDetachRequest[]; showNotification?: boolean },
  ) {}
}

export class AttachPropertyFailureAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AttachPropertySuccessAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_SUCCESS;
}

export class LoadAclRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ACL_REQUEST;
  constructor(public payload: { propertyId: number }) {}
}

export class LoadAclFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ACL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadAclSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ACL_SUCCESS;
  constructor(public payload: { items: IUserRoot[] }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IUserRoot[] }) {}
}

export class SearchRequestAction implements Action {
  readonly type = ActionTypes.SEARCH_REQUEST;
  constructor(public payload: { searchParams: SearchParams }) {}
}

export class SearchFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SearchSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { items: IUserRoot[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | DetachPropertyRequestAction
  | DetachPropertySuccessAction
  | DetachPropertyFailureAction
  | AttachPropertyRequestAction
  | AttachPropertySuccessAction
  | AttachPropertyFailureAction
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | SearchRequestAction
  | SearchFailureAction
  | SearchSuccessAction
  | LoadAclRequestAction
  | LoadAclSuccessAction
  | LoadAclFailureAction
  | DeleteRequestAction
  | DeleteSuccessAction
  | DeleteFailureAction
  | ResetSuccessAction;
