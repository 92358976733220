import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ICoreState,
  selectLanguages,
  selectPropertySelectedLanguage,
} from '@app/core/+state/core.reducer';
import { ILanguage } from '@app/core/models/api/generics/languages/language.model';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'by-name-description-languages-form',
  templateUrl: './name-description-languages-form.component.html',
  styleUrls: ['./name-description-languages-form.component.scss'],
})
export class NameDescriptionLanguagesFormComponent
  implements OnInit, OnDestroy
{
  @Input() set inputActvive(inputActvive: string[]) {
    if (!inputActvive) {
      return;
    }
    this._inputActvive = inputActvive;
    this.nameActive = inputActvive.findIndex((key) => key === 'name') !== -1;
    this.descriptionActive =
      inputActvive.findIndex((key) => key === 'description') !== -1;
  }

  @Input() set translations(
    translations: Array<{
      locale: string;
      name: string;
      description: string;
    }>,
  ) {
    if (!translations || translations.length) {
      return;
    }
    this._translations = translations;
    this.patchDescriptionForm(translations);
  }
  @Input() set languagesObject(languagesObject: {
    [isoCode: string]: {
      locale: string;
      name: string;
      description: string;
    };
  }) {
    if (!languagesObject || !Object.keys(languagesObject || {}).length) {
      return;
    }
    const translations: any = [];
    Object.keys(languagesObject || {}).forEach((isoCode) => {
      translations.push({
        locale: isoCode,
        ...languagesObject[isoCode],
      });
    });
    this._translations = translations;
    this.patchDescriptionForm(translations);
  }

  @Output() changeValueForm = new EventEmitter<any>();

  _inputActvive = ['description', 'name'];
  update = false;

  propertyLanguageSubscription: Subscription;
  languagesSubscription: Subscription;
  propertyLanguage: string;
  _translations: any;
  nameActive = true;
  descriptionActive = true;
  languages: ILanguage[];
  supportedLangs: string[];
  translationsForms: { [lang: string]: UntypedFormGroup };
  formSubscription: Subscription;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private coreStore: Store<ICoreState>,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.propertyLanguageSubscription = this.coreStore
      .select(selectPropertySelectedLanguage)
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.propertyLanguage = data;
        if (this.languages && !this.translationsForms) {
          this.supportedLangsData(this.languages);
        }
      });
    this.languagesSubscription = this.coreStore
      .select(selectLanguages)
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.languages = data;
        if (this.propertyLanguage) {
          this.supportedLangsData(this.languages);
        }
      });
    if (this._translations) {
      this.patchDescriptionForm(this._translations);
    }
  }

  supportedLangsData(supportedLangsData: ILanguage[]) {
    if (
      !supportedLangsData ||
      !supportedLangsData.length ||
      this.translationsForms
    ) {
      return;
    }
    this.supportedLangs = supportedLangsData.map((lang) => lang.iso_code);
    this.translationsForms = this.supportedLangs.reduce(
      (forms, currentLang) => {
        forms[currentLang] = this.formBuilder.group(
          {
            name: [''],
            description: [''],
          },
          { updateOn: 'blur' },
        );
        return forms;
      },
      {},
    );
    this.subscribeAllForm();
    this.setLanguageValidator(
      this.supportedLangs,
      this._inputActvive,
      [this.propertyLanguage || 'it'],
      this.translationsForms,
    );
  }

  subscribeAllForm() {
    Object.keys(this.translationsForms).forEach((key) => {
      this.translationsForms[key].valueChanges
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((change) => {
          this.changeValue();
        });
    });
  }

  setLanguageValidator(
    langsIsoCode: string[],
    constrols: string[],
    langsToBeValidated: string[],
    translationsForms: any,
  ): void {
    langsIsoCode.forEach((lang) => {
      langsToBeValidated.forEach((langToBeValidated) => {
        if (lang === langToBeValidated) {
          constrols.forEach((control) => {
            translationsForms[lang]
              .get(control)
              .setValidators([Validators.required]);
          });
        }
      });
    });
  }

  private patchDescriptionForm(
    translations: Array<{
      locale: string;
      description: string;
      name: string;
    }>,
  ) {
    if (
      !this.supportedLangs ||
      !this.supportedLangs.length ||
      !translations ||
      this.update
    ) {
      return;
    }
    this.update = true;
    this.supportedLangs.forEach((supportedLang) => {
      const foundTranslation = translations.find(
        (translation) => translation.locale === supportedLang,
      );
      if (!foundTranslation) {
        return;
      }
      this.translationsForms[supportedLang].patchValue({
        name: foundTranslation.name,
        description: foundTranslation.description,
      });
    });
  }

  get Languages() {
    return this.createLanguages();
  }

  createLanguages() {
    const languages: any = Object.keys(this.translationsForms).reduce(
      (langs, key) => {
        langs[key] = this.translationsForms[key].value;
        return langs;
      },
      {},
    );
    return languages;
  }

  public changeValue() {
    const languages = this.createLanguages();
    this.changeValueForm.emit(languages);
  }

  ngOnDestroy() {
    this.languagesSubscription.unsubscribe();
    this.propertyLanguageSubscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
