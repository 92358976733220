import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Reservation } from '../../models';

export const featureAdapter: EntityAdapter<Reservation> =
  createEntityAdapter<Reservation>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<Reservation> {
  isLoading?: boolean;
  isDashboardLoading?: boolean;
  isLoadingTotalDetails?: boolean;
  error?: any;
  pagination: IPagination;
  reservationsDashboard?: any[];
  reservationsDashboardPagination?: IPagination;
  exportFileId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  isDashboardLoading: false,
  isLoadingTotalDetails: false,
  error: null,
  pagination: null,
  reservationsDashboard: null,
  reservationsDashboardPagination: null,
  exportFileId: null,
});
