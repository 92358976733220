import { CustomerListItem } from '../../models';

import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

const customersMapper = (customer: CustomerListItem): CustomerListItem => {
  return {
    ...customer,
    residence_detail: (customer.details || []).find(
      ({ customer_address_type_id }) => customer_address_type_id === 1,
    ),
  };
};

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportFileId: action.payload.exportIdFile,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items, pagination } = action.payload;
      return featureAdapter.setAll(items.map(customersMapper), {
        ...state,
        isLoading: false,
        error: null,
        pagination,
      });
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPLOAD_REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPLOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPLOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        uploadErrors: action.payload.uploadErrors,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_GUEST_TYPE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.UPDATE_GUEST_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPDATE_GUEST_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      const { items } = action.payload;
      const newItem = <any[]>items;
      return featureAdapter.setAll(newItem, {
        // IMPLEMENTATO NELLO ROOT-STORE, DA RIMUOVERE APPENA POSSIBILE
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.SEARCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.customerId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_DOCUMENT_TYPE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        documentTypes: null,
      };
    }

    case ActionTypes.LOAD_DOCUMENT_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        documentTypes: action.payload.customerDocumentTypes,
      };
    }

    case ActionTypes.LOAD_DOCUMENT_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_CATEGORIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        customerCategories: action.payload.customerCategories,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_TYPES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        customerTypes: null,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_TYPES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        customerTypes: action.payload.customerTypes,
      };
    }

    case ActionTypes.LOAD_CUSTOMER_TYPES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_ADDRESS_TYPE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        customerTypes: null,
      };
    }

    case ActionTypes.LOAD_ADDRESS_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        addressTypes: action.payload.customerAddressTypes,
      };
    }

    case ActionTypes.LOAD_ADDRESS_TYPE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_DETAILS_REQUEST: {
      return {
        ...state,
        error: null,
        isLoadingDetails: true,
      };
    }

    case ActionTypes.LOAD_DETAILS_SUCCESS: {
      const { customerDetails } = action.payload;
      return {
        ...state,
        error: null,
        customerDetails,
        isLoadingDetails: false,
      };
    }

    case ActionTypes.LOAD_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoadingDetails: false,
      };
    }

    case ActionTypes.RESET_UPLOAD_ERRORS: {
      return {
        ...state,
        uploadErrors: {},
      };
    }

    case ActionTypes.RESET_STATE: {
      return featureAdapter.getInitialState({
        isLoading: false,
        error: null,
        documentTypes: state.documentTypes,
        customerTypes: state.customerTypes,
        addressTypes: state.addressTypes,
        pagination: null,
        customerDetails: null,
        isLoadingDetails: null,
        customerCategories: [],
        uploadErrors: {},
        exportFileId: null,
      });
    }

    default: {
      return state;
    }
  }
}
