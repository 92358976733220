import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { Metasearch } from '../../models';

export const featureAdapter = createEntityAdapter<Metasearch>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Metasearch> {
  isLoading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
