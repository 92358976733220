<ng-container>
  <div class="pointer" (click)="showModal()">
    <ng-container *ngIf="!buttonTpl; else buttonTpl">
      <button nz-button nzType="primary">
        <i class="fal fa-file-search"></i>
        <span>{{ 'document_scan' | translate | upperfirst }}</span>
      </button>
    </ng-container>
  </div>
</ng-container>
<nz-modal
  [nzWidth]="600"
  [(nzVisible)]="isModalVisible"
  [nzTitle]="'document_scan' | translate | upperfirst"
  (nzOnCancel)="closeModal()"
>
  <div id="webcamContainer" *nzModalContent>
    <by-spin [nzSpinning]="loading$ | async">
      <div class="by-w-100" nz-row>
        <by-places-form
          class="by-w-100"
          [allowClear]="false"
          [enabledInputs]="{
            country: true,
            state: false,
            county: false,
            city: false
          }"
          [place]="{ countryId: countryId }"
          [required]="true"
          (changePlace)="onChangePlace($event)"
        ></by-places-form>
      </div>
      <div class="by-mb-2" nz-row>
        <span>{{ 'document_type' | translate | upperfirst }} </span>
        <nz-select
          style="width: 100%"
          [(ngModel)]="documentTypeId"
          nzShowSearch
          nzPlaceHolder="{{
            'placeholder.select' | translate: { param: 'document' | translate }
          }}"
        >
          <nz-option
            *ngFor="let documentType of documentTypesFiltered"
            [nzValue]="documentType.id"
            [nzLabel]="documentType.name"
          ></nz-option>
        </nz-select>
      </div>

      @if (!isMobile) {
        <nz-radio-group
          [(ngModel)]="radioValue"
          (ngModelChange)="changeRadioValue($event)"
          [nzButtonStyle]="'solid'"
        >
          <label nz-radio-button nzValue="webcam">{{
            'webcam_photo' | translate | capitalize
          }}</label>
          <label nz-radio-button nzValue="device">{{
            'device_upload' | translate | capitalize
          }}</label>
        </nz-radio-group>
      }

      <div class="by-mb-2"></div>
      <div nz-row>
        <div *ngIf="radioValue === 'webcam'" nz-col [nzXs]="24" [nzMd]="24">
          <ng-container *ngIf="showWebcam && !webcamImage; else noWebcam">
            <div class="webcamContainer">
              <i
                (click)="triggerSnapshot()"
                class="iconshowphoto fas fa-camera-alt"
              ></i>
              <div class="webcam">
                <div class="animationwebcam div-help-ocr"></div>
                <!-- <div class="animationwebcam div-help-picture-ocr"></div>
                <div class="animationwebcam div-help-aling-ocr"></div> -->
                <webcam
                  [imageQuality]="1"
                  (imageClick)="triggerSnapshot()"
                  [mirrorImage]="'never'"
                  [allowCameraSwitch]="isMobile"
                  [trigger]="triggerObservable"
                  (imageCapture)="handleImage($event)"
                  (initError)="handleInitError($event)"
                ></webcam>
              </div>
            </div>
          </ng-container>
          <ng-template #noWebcam>
            <div class="showWebcam">
              <div class="snapshot" *ngIf="webcamImage">
                <img [src]="webcamImage.imageAsDataUrl" />
              </div>
              <div *ngIf="!webcamImage">
                <span class="by-mt-2 by-mb-2">{{
                  'capture_image_webcam' | translate | upperfirst
                }}</span>
                <i
                  (click)="toggleWebcam()"
                  class="webcamClass fad fa-webcam"
                ></i>
                @if (!hasCameraPermission) {
                  <by-camera-permission-info></by-camera-permission-info>
                }
              </div>
            </div>
          </ng-template>
          <div nz-row nzJustify="center" nzAlign="middle">
            <button
              *ngIf="showWebcam && !webcamImage"
              nz-button
              nzType="primary"
              class="actionBtn"
              (click)="triggerSnapshot()"
            >
              <i class="fas fa-camera-alt"></i>
              {{ 'take_snapshot' | translate | upperfirst }}
            </button>
            <button
              *ngIf="showWebcam"
              nz-button
              nzType="default"
              class="actionBtn"
              (click)="toggleWebcam()"
            >
              <i class="fad fa-webcam-slash"></i>
              {{ 'disable_webcam' | translate | upperfirst }}
            </button>
            <button
              *ngIf="showWebcam && webcamImage"
              nz-button
              nzDanger
              nzType="primary"
              class="actionBtn"
              (click)="deleteWebcamFile()"
            >
              <i class="fas fa-trash-alt"></i>
              {{ 'delete' | translate | upperfirst }}
            </button>
          </div>
        </div>
        <div nz-col *ngIf="radioValue === 'device'" [nzXs]="24" [nzMd]="24">
          <by-image-uploader
            [media]="file"
            [filterDefault]="fileTypes"
            (upload)="onUpload($event)"
          ></by-image-uploader>
        </div>
      </div>
    </by-spin>
  </div>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="closeModal()">
      {{ 'cancel' | translate | upperfirst }}
    </button>
    <button
      nz-button
      nzType="primary"
      [disabled]="!file || !countryName"
      (click)="extractText()"
      [nzLoading]="loading$ | async"
    >
      {{ 'extract_text' | translate | upperfirst }}
    </button>
  </div>
</nz-modal>
