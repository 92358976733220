import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { handleFormSubmit } from '../../core/helpers/handleFormSubmit';
import { ReservationsStoreActions } from '../../root-store/reservations-store';
import { RootState } from '../../root-store/root-state';

import { ResendReservationEmailModalComponent } from './resend-reservation-email-modal.component';

@Injectable()
export class ResendReservationEmailModalService {
  private ref: NzModalRef = null;

  constructor(
    private modalService: NzModalService,
    private translate: TranslateService,
    private store: Store<RootState>,
  ) {}

  open(
    propertyId: number,
    reservationId: number,
    disableReservationLoad = false,
    roomReservationIDS?: string[],
    extraComponentParams?: Partial<ResendReservationEmailModalComponent>,
  ) {
    this.ref = this.modalService.create<
      ResendReservationEmailModalComponent,
      Partial<ResendReservationEmailModalComponent>
    >({
      nzContent: ResendReservationEmailModalComponent,
      nzData: {
        reservationId,
        propertyId,
        disableReservationLoad,
        ...extraComponentParams,
      },
      nzFooter: [
        {
          label: capitalize(this.translate.instant('close')),
          onClick: () => this.ref.close(),
        },
        {
          label: capitalize(this.translate.instant('send_emails')),
          type: 'primary',
          disabled: ({ form }) => form.disabled || form.invalid,
          onClick: ({ form }) => {
            handleFormSubmit([form], () => {
              this.store.dispatch(
                ReservationsStoreActions.resendEmailRequest({
                  reservationId,
                  roomReservationIDS,
                  ...form.value,
                }),
              );
            });

            this.ref.close();
          },
        },
      ],
    });
  }

  close() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
