import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'by-editable-label',
  templateUrl: './editable-label.component.html',
  styleUrls: ['./editable-label.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EditableLabelComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableLabelComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Output()
  newValue = new EventEmitter();
  @Output()
  searchLookupEvent = new EventEmitter();
  @Output()
  closeEvent = new EventEmitter();
  @Output()
  clickEvent = new EventEmitter();
  @Output()
  clickEditEvent = new EventEmitter();
  @Output()
  saveValue = new EventEmitter();
  @Output()
  blurEvent = new EventEmitter();
  @Input()
  type: 'input' | 'select' | 'grouped-select' | 'lookup' | 'multiple';
  @Input()
  nameViewSelect: string;
  @Input()
  placeHolderLookup = 'customer';
  @Input()
  confirmRequest: boolean;
  @Input()
  confirmRequestMessage: string;
  @Input()
  nzAllowClear = true;
  @Input()
  activeFocus = false;
  @Input()
  value: any | [{ label: string; value: any }];
  @Input()
  edit = false;
  @Input()
  listOfOption;
  @Input()
  mode: 'multiple' | 'default';
  @Input()
  classLabel: string;
  @Input()
  placeholder: string;
  @Input()
  canWrite = true;
  @Input()
  groupsNames = {};
  @Input()
  styleCustom = {};
  @Input()
  showPenAlways = false;

  public inputRef: ElementRef;
  private initialValue;
  public flagX = true;
  valueRequestChange = null;

  valueToShow: any;
  showPen: boolean;

  @ViewChild('input') set _inputRef(inputRef: ElementRef) {
    if (!inputRef) {
      return;
    }
    this.inputRef = inputRef;
    if (this.activeFocus) {
      inputRef.nativeElement.focus();
    }
  }
  @ViewChild('select') selectRef: ElementRef;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // if (changes && changes.value && !this.initialValue) {
    //   this.initialValue = changes.value.currentValue;
    // }
    if (
      changes &&
      changes.value &&
      changes.value.currentValue !== changes.value.previousValue
    ) {
      this.buildValueToShow();
    }
    if (
      changes &&
      changes.listOfOption &&
      changes.listOfOption.currentValue !== changes.listOfOption.previousValue
    ) {
      this.buildValueToShow();
    }
  }

  keyEvent(e) {
    this.close();
    this.onSaveValue(e.target.value);
  }

  onChange = (e) => {};

  writeValue(_value) {
    if (_value) {
      this.value = _value;
      this.buildValueToShow();
    }
  }

  pushChanges(value: any) {
    // this.value = value;
    this.onChange(value);
    this.buildValueToShow();
    this.newValue.emit(value);
  }

  onSaveValue(newValue) {
    this.saveValue.emit(newValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {}

  selectHandle(e: boolean) {
    if (!e && !this.confirmRequest) {
      this.close();
    }
  }

  mapValueMulti(): string[] {
    if (this.value && this.value.length) {
      const listOfOption =
        this.type === 'grouped-select'
          ? Object.keys(this.listOfOption || {}).reduce(
              (newListOptions, groupValue) =>
                (newListOptions = [
                  ...newListOptions,
                  ...this.listOfOption[groupValue],
                ]),
              [],
            )
          : this.listOfOption;

      return this.value.map((item) => {
        return listOfOption.find((_item) => _item.value === item).label;
      });
    }
  }

  mapValue(): any {
    if (!this.listOfOption) {
      return;
    }

    const listOfOption =
      this.type === 'grouped-select'
        ? Object.keys(this.listOfOption || {}).reduce(
            (newListOptions, groupValue) =>
              (newListOptions = [
                ...newListOptions,
                ...this.listOfOption[groupValue],
              ]),
            [],
          )
        : this.listOfOption;

    const findOption = listOfOption.find((item) => item.value === this.value);
    if (findOption) {
      return findOption.label;
    }
  }

  close() {
    this.showPen = false;
    this.edit = false;
    this.closeEvent.emit(true);
  }

  onSelectChange(event) {
    if (!this.confirmRequest) {
      this.pushChanges(event);
      this.onSaveValue(event);
    }
    this.valueRequestChange = event;
  }

  onLookupChange(event) {
    if (event === this.value) {
      return;
    }
    this.onSelectChange(event);
    this.close();
  }

  open(event) {
    if (this.canWrite) {
      this.edit = true;
    }
  }

  buildValueToShow(): void {
    switch (this.mode) {
      case 'default': {
        this.valueToShow = this.mapValue();
        break;
      }
      case 'multiple': {
        this.valueToShow = this.mapValueMulti();
        break;
      }
      default: {
        this.valueToShow = this.value;
        break;
      }
    }
  }

  onSearchCustomer(input: string) {
    this.searchLookupEvent.emit(input);
  }

  constructor() {}
}
