import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'by-mobile-list-item-action',
  templateUrl: './mobile-list-item-action.component.html',
  styleUrls: ['./mobile-list-item-action.component.scss'],
})
export class MobileListItemActionComponent implements OnInit {
  @Output() trigger = new EventEmitter();

  @Input() byStyle = {
    padding: '20px',
    'font-weight': 700,
    'font-size': '15px',
    'white-space': 'initial',
  };

  constructor() {}

  ngOnInit() {}

  onTriggerAction(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.trigger.emit();
  }
}
