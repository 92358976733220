import { createAction, props } from '@ngrx/store';

import { Metasearch } from '../../models';

export const loadRequest = createAction(
  '[Metasearch] Load Request',
  props<{ properties: number[] }>(),
);
export const loadSuccess = createAction(
  '[Metasearch] Load Success',
  props<{ metasearch: Metasearch[] }>(),
);
export const loadFailure = createAction(
  '[Metasearch] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Metasearch] Reset State');
