import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray',
})
export class IsInArrayPipe implements PipeTransform {
  transform(
    item: string | number,
    items: any,
    falseIfEmpty?: boolean,
  ): boolean {
    if (!items || !items.length) {
      return falseIfEmpty ? false : true;
    }

    return items.findIndex((_item) => _item === item) !== -1;
  }
}
