import { Component, Input } from '@angular/core';
import { ReservationTotals } from '../../models';

@Component({
  selector: 'by-reservation-totals',
  templateUrl: './reservation-totals.component.html',
  styleUrls: ['./reservation-totals.component.scss'],
})
export class ReservationTotalsComponent {
  @Input() loadingTotalDetails: boolean;

  @Input()
  reservationTotals: ReservationTotals;

  @Input()
  currencySymbol: string;

  @Input()
  currentColor: string;

  @Input()
  byClass = ['price-details-popover'];

  @Input()
  highlightsPaymentStatus = false;

  constructor() {}

  get totalPrice(): number {
    return (
      +this.reservationTotals?.total_price +
      +this.reservationTotals?.total_discount
    );
  }

  get addonsTotalPrice(): number {
    return (
      +this.reservationTotals?.total_addons +
      +this.reservationTotals?.total_manual
    );
  }

  get background(): string {
    return `linear-gradient(${
      this.currentColor || ''
    }4d, rgba(255, 0, 0, 0) 30.71%)`;
  }

  get color(): string {
    return this.currentColor || '';
  }
}
