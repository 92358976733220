import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentScanService {
  dataAPI = {
    processParam: {
      scenario: 'FullProcess',
      resultTypeOutput: [],
      doublePageSpread: true,
      log: true,
    },
    List: [{ ImageData: { image: '' }, light: 6, page_idx: 0 }],
  };

  constructor(private http: HttpClient) {}

  request(file, countryName: string) {
    const data = {
      ...this.dataAPI,
      List: [{ ImageData: { image: file }, light: 6, page_idx: 0 }],
    };
    return this.http.post(
      `general/document_scan/regulaforensics?country_code=${countryName}`,
      { data },
    );
  }
}
