import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { DateFormatterService } from '@app/core/services/date-formatter.service';
import {
  AbstractControlData,
  AddGuestForm,
  AgencyDetails,
  Company,
  CreateCustomer,
  CreateCustomerData,
  CreateCustomerDetail,
  CustomerBirthData,
  CustomerDetail,
  CustomerDocument,
  GuestType,
  GuestTypeOption,
  ICityTaxExemptionItem,
  IDocumentTypeRoot,
  IstatAccommodation,
  IstatAccommodationForm,
  IstatGuest,
  IstatGuestForm,
  IstatGuestFormsObj,
  IstatReservation,
  IstatValidations,
  Places,
  SendToExternalData,
  SendToExternalRequest,
} from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import {
  capitalize,
  get,
  isNil,
  merge,
  omit,
  omitBy,
  upperFirst,
} from 'lodash';
import moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { DEFAULT_COLORS } from '../../core/helpers/default-colors';
import { handleFormSubmit } from '../../core/helpers/handleFormSubmit';
import { ILanguage } from '../../core/models/api/generics/languages/language.model';
import { ICustomerReservationAttach } from '../../features/commons/reservation-details/models/reservation.guests.model';
import { getNullableDate } from '../../helpers/nullable-date';
import { AttchGuestToReservationRequest } from '../../models/requests/attach-guest-to-reservation-data.request';
import { AddCustomerComponent } from '../add-customer/add-customer.component';

import {
  sendableForm,
  sendableFormArray,
  sendableGuest,
} from './sendable.validator';
import { IstatGuestModalData } from '../../models/objects/istat-guest-list-modal';

export const guestTypeIdsWithDocumentDisabled = [4, 5];

@Component({
  selector: 'by-istat-guest-list',
  templateUrl: './istat-guest-list.component.html',
  styleUrls: ['./istat-guest-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IstatGuestListComponent implements OnInit, OnDestroy {
  @Input() set entries(data: {
    entries: IstatReservation[];
    validations: IstatValidations;
  }) {
    const { entries, validations } = data;

    this.istatValidations = validations;

    this.form = this.formBuilder.group({
      reservations: this.buildReservationFormArray(entries),
    });

    this.setExpiredDocumentRecords();
  }

  @Input() agencySpecificationData: AgencyDetails;

  @Input() availableGuestMap: Record<number, number[]>;

  @Input() languages: ILanguage[] = [];

  @Input('customersOptions') set _customersOptions(
    customersOptions: Array<{
      name: string;
      id: number;
      type?: 'customer' | 'guest' | 'company';
      company?: Company;
    }>,
  ) {
    this.customersOptions = customersOptions;
    if (!this.addGuestComponent) {
      return;
    }
    this.addGuestComponent.options = this.customersOptions;
  }
  customersOptions: Array<{
    name: string;
    id: number;
    type?: 'customer' | 'guest' | 'company';
    company?: Company;
  }> = [];

  @Input() referenceDate = new Date();

  @Input() agencyCurrentDate: string;

  @Input() documentTypes: IDocumentTypeRoot[];

  @Input() customerTypes: GuestType[];

  @Input() agencyId: number;

  @Input() isIstatReporting: boolean;

  @Input() showSentGuest = false;

  @Input()
  cityTaxExemptionData: ICityTaxExemptionItem[];

  @Input()
  saveGuestLoading = false;

  @Output() updateGuestEvent = new EventEmitter<{
    customerData: CreateCustomer;
    guestData?: {
      guest_type_id: number;
      res_acc_room_guest_id: number;
      city_tax_exemption_id: number;
    };
  }>();

  @Output() createCustomerEvent = new EventEmitter<CreateCustomerData>();

  @Output() loadAccommodationAvailableGuestTypes = new EventEmitter<{
    accommodationRoomId: number;
    groupHousedWebId: number;
  }>();

  @Output() attachGuestToReservationEvent = new EventEmitter<{
    data: ICustomerReservationAttach;
    actionType: 'new' | 'delete';
  }>();

  @Output() sendToExternal = new EventEmitter<
    Pick<SendToExternalRequest, 'entries'>
  >();

  @Output() checkAllGuestStatus = new EventEmitter();

  @Output() deleteGuestSent = new EventEmitter<number>();

  guestFormsObject: IstatGuestFormsObj;

  guestDetails: Partial<CustomerDetail[]> = [];

  birthPlaces: Places;
  show = {};
  panelActivate: { [roomreservationID: string]: boolean } = {};
  panelActivateReservation: { [reservationId: number]: boolean } = {};

  activePanel = true;

  documentPlaces: Places;

  colorsFlatten = DEFAULT_COLORS;

  istatValidations: IstatValidations;

  checkinIconStyle = {
    width: '16px',
    height: '16px',
    'border-radius': '4px',
    'text-align': 'center',
  };

  customerId: number;

  addGuestComponent: AddCustomerComponent;

  private subs = new SubSink();

  private emptyPlaces: Places = {
    countryId: 1,
    stateId: null,
    countyId: null,
    cityId: null,
    country: null,
    state: null,
    county: null,
    city: null,
  };

  public scrollData: {
    reservationIndex: number;
    accommodationIndex: number;
  };

  expiredDocumentRecords: {
    [reservationId: number]: {
      [accommodationId: number]: { [customerId: number]: boolean };
    };
  } = {};

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateFormatter: DateFormatterService,
    private translateService: TranslateService,
    private modalService: NzModalService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSendToExternla(data: SendToExternalData) {
    const dataRequest: Pick<SendToExternalRequest, 'entries'> = {
      entries: [data],
    };
    this.sendToExternal.emit(dataRequest);
  }

  onReservationSwitch(
    send_to_external: boolean,
    reservationForm: UntypedFormGroup,
  ) {
    /**
     *  Questo setTimeout consente di leggere i valori dei vari controls solo quando sono stati aggiornati,
     *  quindi al thread successivo.
     *  https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/
     */

    setTimeout(() => {
      const { reservationId: id } = reservationForm.value as IstatReservation;
      const accommodationsForm = reservationForm.get(
        'accommodations',
      ) as UntypedFormArray;
      const accommodations = accommodationsForm.value as IstatAccommodation[];

      accommodations.forEach(({ guests, atLeastOneGuestSent }, accIndex) => {
        const accommodationForm = accommodationsForm.controls[
          accIndex
        ] as UntypedFormGroup;
        const guestsForm = accommodationForm.get('guests') as UntypedFormArray;

        if (!send_to_external) {
          const guestForm: UntypedFormGroup = get(
            guestsForm,
            'controls[0]',
          ) as UntypedFormGroup;
          if (guestForm) {
            const { customer_id } = guestForm.value as IstatGuestForm;
            if (!customer_id) {
              this.onRemoveNewGuest(guestsForm, 0);
              guests.shift();
            }
          }
        }

        if (!atLeastOneGuestSent) {
          accommodationForm.patchValue(
            { send_to_external },
            { emitEvent: false },
          );
          guestsForm.value.forEach((_, index) => {
            const guestForm: UntypedFormGroup = guestsForm.controls[
              index
            ] as UntypedFormGroup;

            const { reportedStatus } = guestForm.value as IstatGuestForm;
            if (
              reportedStatus !== 'sync' &&
              reportedStatus !== 'needs_update'
            ) {
              guestForm.patchValue({ send_to_external }, { emitEvent: false });
            }
          });
        }
      });

      this.onSendToExternla({
        id,
        type: 'reservation',
        value: send_to_external,
      });
      this.checkAllGuestSent();
    });
  }

  onAccommodationSwitch(
    send_to_external: boolean,
    accommodationForm: UntypedFormGroup,
  ) {
    /**
     *  Questo setTimeout consente di leggere i valori dei vari controls solo quando sono stati aggiornati,
     *  quindi al thread successivo.
     *  https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/
     */

    setTimeout(() => {
      const { id: accommodationId } =
        accommodationForm.value as IstatAccommodationForm;

      const accommodationsForms = accommodationForm.parent as UntypedFormArray;
      const accommodations = accommodationsForms.value as IstatAccommodation[];
      const reservationForm = accommodationForm.parent
        .parent as UntypedFormGroup;
      const reservation = reservationForm.value as IstatReservation;
      const guestsForms: UntypedFormArray = accommodationForm.get(
        'guests',
      ) as UntypedFormArray;
      const guests: IstatGuestForm[] = guestsForms.value;

      const { send_to_external: reservationSendToExternal } = reservation;

      if (!send_to_external) {
        const guestForm: UntypedFormGroup = get(
          guestsForms,
          'controls[0]',
        ) as UntypedFormGroup;
        if (guestForm) {
          const { customer_id } = guestForm.value as IstatGuestForm;
          if (!customer_id) {
            this.onRemoveNewGuest(guestsForms, 0);
            guests.shift();
          }
        }
      }

      guests.forEach((_, index) => {
        const guestForm: UntypedFormGroup = guestsForms.controls[
          index
        ] as UntypedFormGroup;
        const { reportedStatus, send_to_external: guestSendToExternal } =
          guestForm.value as IstatGuestForm;
        if (
          reportedStatus !== 'sync' &&
          reportedStatus !== 'needs_update' &&
          guestSendToExternal !== send_to_external
        ) {
          guestForm.patchValue({ send_to_external }, { emitEvent: false });
        }
      });

      const isAllAccommodationsEqual = accommodations.every(
        ({ send_to_external: sendToexternal, atLeastOneGuestSent }) =>
          sendToexternal === send_to_external && !atLeastOneGuestSent,
      );

      if (isAllAccommodationsEqual) {
        if (reservationSendToExternal !== send_to_external) {
          reservationForm.patchValue({ send_to_external });
        } else {
          this.onSendToExternla({
            id: accommodationId,
            type: 'accommodation',
            value: send_to_external,
          });
          this.checkAllGuestSent();
        }
      } else {
        this.onSendToExternla({
          id: accommodationId,
          type: 'accommodation',
          value: send_to_external,
        });
        this.checkAllGuestSent();
      }
    });
  }

  onGuestSwitch(send_to_external: boolean, guestForm: UntypedFormGroup) {
    /**
     *  Questo setTimeout consente di leggere i valori dei vari controls solo quando sono stati aggiornati,
     *  quindi al thread successivo.
     *  https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/
     */

    setTimeout(() => {
      const { res_acc_room_guest_id: id } = guestForm.value as IstatGuestForm;
      const guestsForms = guestForm.parent as UntypedFormArray;
      const guests: IstatGuestForm[] = guestsForms.value;
      const isAllGuestsEqual = guests.every(
        ({ send_to_external: sendToexternal, reportedStatus }) =>
          sendToexternal === send_to_external &&
          reportedStatus !== 'sync' &&
          reportedStatus !== 'needs_update',
      );

      if (isAllGuestsEqual) {
        const accommodationForm = guestsForms.parent as UntypedFormGroup;
        const { send_to_external: accommodationSendToExternal } =
          accommodationForm.value as IstatAccommodationForm;
        if (accommodationSendToExternal !== send_to_external) {
          accommodationForm.patchValue({ send_to_external });
        } else {
          this.onSendToExternla({
            id,
            type: 'guest',
            value: send_to_external,
          });
          this.checkAllGuestSent();
        }
      } else {
        this.onSendToExternla({
          id,
          type: 'guest',
          value: send_to_external,
        });
        this.checkAllGuestSent();
      }
    });
    guestForm.get('send_to_external').markAsPristine();
    guestForm
      .get('send_to_external')
      .updateValueAndValidity({ emitEvent: false });
  }

  buildGuestAttachData(
    guestForm: UntypedFormGroup,
  ): AttchGuestToReservationRequest {
    const {
      guest_type_id,
      customer_id,
      city_tax_exemption_id,
      res_acc_room_guest_id,
      arrival_date,
      departure_date,
    } = guestForm.value as IstatGuestForm;

    const accommodationForm: UntypedFormGroup = guestForm.parent
      .parent as UntypedFormGroup;
    const { reservation_accommodation_room_id: reservation_acc_room_id } =
      accommodationForm.value as IstatAccommodation;

    const reservationForm: UntypedFormGroup = accommodationForm.parent
      .parent as UntypedFormGroup;
    const { reservationId: reservation_id } =
      reservationForm.value as IstatReservation;

    return {
      reservation_acc_room_id,
      arrival_date: this.dateFormatter.toServerFormat(arrival_date),
      departure_date: this.dateFormatter.toServerFormat(departure_date),
      reservation_id,
      guest_type_id,
      customer_id,
      city_tax_exemption_id,
      res_acc_room_guest_ids: [res_acc_room_guest_id],
      res_acc_room_guest_id,
    };
  }

  onSaveGuest(guestForm: UntypedFormGroup) {
    const {
      document_number,
      document_type_id,
      expire_date,
      documentPlaces,
      domicilePlaces,
      birthday_date,
      birthPlaces,
      citizenshipPlaces,
      name,
      surname,
      sex,
      language_id,
      guest_type_id,
      customer_id,
      res_acc_room_guest_id,
      city_tax_exemption_id,
    } = guestForm.value as IstatGuest;

    const document: Omit<CustomerDocument, 'released_by' | 'released_on'> = {
      ...documentPlaces,
      document_type_id,
      document_number,
      expire_date: this.dateFormatter.toServerFormat(expire_date),
    };

    const domicile: CreateCustomerDetail = {
      country: domicilePlaces.country,
      county: domicilePlaces.county,
      state: domicilePlaces.state,
      city: domicilePlaces.city,
    };

    const birth: CustomerBirthData = {
      birthday_date: this.dateFormatter.toServerFormat(birthday_date),
      birth_country: birthPlaces.country,
      birth_county: birthPlaces.county,
      birth_state: birthPlaces.state,
      birth_city: birthPlaces.city,
    };

    const citizenship: Partial<CreateCustomer> = {
      citizenship: citizenshipPlaces.country,
    };

    let newCustomer: CreateCustomer = {
      ...(omitBy(birth, isNil) as CustomerBirthData),
      type: 'guest',
      citizenship: citizenship.citizenship,
      category_id: 1,
      name,
      surname,
      sex,
      language_id,
      customer_id,
    };

    if (!guestTypeIdsWithDocumentDisabled.includes(guest_type_id)) {
      newCustomer = {
        ...newCustomer,
        documents: [document],
      };
    }

    newCustomer = {
      ...newCustomer,
      details: {
        1: domicile,
      },
    };

    if (!customer_id) {
      const attachData = this.buildGuestAttachData(guestForm);
      this.createCustomerEvent.emit({
        customer: omit(newCustomer, 'customer_id'),
        attachToReservation: attachData,
      });
      return;
    }

    this.updateGuestEvent.emit({
      customerData: newCustomer,
      guestData: {
        res_acc_room_guest_id,
        guest_type_id,
        city_tax_exemption_id,
      },
    });

    this.setExpiredDocumentRecords();
  }

  stopPropagation(event: Event) {
    if (!event) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  disableButtonSend(): boolean {
    return this.form.get('reservations').hasError('notSendable');
  }

  expandChange(index, event) {
    this.show = { ...this.show, [index]: event };
  }

  expandGuests(roomreservationID: string, open: boolean) {
    this.panelActivate = {
      ...this.panelActivate,
      [roomreservationID]: open,
    };
  }

  getAvailableGuestTypesForExpandedReservation = () => {
    this.reservations.forEach((reservation) => {
      if (!this.panelActivateReservation[reservation.reservationId]) {
        return;
      }

      reservation.accommodations.forEach(
        ({ reservation_accommodation_room_id, alloggiatiweb_group_id }) => {
          this.loadAccommodationAvailableGuestTypes.emit({
            accommodationRoomId: reservation_accommodation_room_id,
            groupHousedWebId: alloggiatiweb_group_id,
          });
        },
      );
    });
  };

  getAvailableGuestTypes(reservationId: number) {
    this.reservations
      .find((reservation) => reservation.reservationId == reservationId)
      .accommodations.forEach(
        ({ reservation_accommodation_room_id, alloggiatiweb_group_id }) => {
          this.loadAccommodationAvailableGuestTypes.emit({
            accommodationRoomId: reservation_accommodation_room_id,
            groupHousedWebId: alloggiatiweb_group_id,
          });
        },
      );
  }
  expandReservation(reservationId: number, opened: boolean) {
    this.panelActivateReservation = {
      ...this.panelActivateReservation,
      [reservationId]: opened,
    };

    if (opened) {
      this.getAvailableGuestTypes(reservationId);
    }
  }

  createNewGuest(guests: UntypedFormArray, newGuestData: AddGuestForm) {
    const { roomreservation_id } = guests.parent.value;

    const {
      customer_id,
      guest_type_id,
      language_id,
      city_tax_exemption_id,
      departure_date,
      arrival_date,
    } = newGuestData;

    const guestData: IstatGuestForm = {
      customer_id,
      city_tax_exemption_id,
      departure_date,
      arrival_date,
      birthday_date: null,
      checkin: null,
      document_number: null,
      expire_date: null,
      document_type_id: null,
      guest_type_id,
      isDifferentDomicile: false,
      language_id,
      name: null,
      reportedStatus: 'not_reported',
      res_acc_room_guest_id: null,
      send_to_external: false,
      sex: 'male',
      surname: null,
      type: 'guest',
      citizenshipPlaces: this.emptyPlaces,
      birthPlaces: this.emptyPlaces,
      domicilePlaces: this.emptyPlaces,
      documentPlaces: this.emptyPlaces,
    };

    const guestControls = this.buildAbstractControlData(
      guestData,
      'customerForm',
    );

    const newGuestFomr = this.formBuilder.group({
      ...guestControls,
    });

    guests.insert(0, newGuestFomr);

    this.panelActivate = {
      ...this.panelActivate,
      [roomreservation_id]: true,
    };

    this.changeDetectionRef.markForCheck();
  }

  onRemoveGuest(guestForm: UntypedFormGroup, guestIndex: number) {
    this.stopPropagation(event);
    const guests: UntypedFormArray = guestForm.parent as UntypedFormArray;
    const { customer_id } = guestForm.value as IstatGuestForm;

    if (!customer_id) {
      this.onRemoveNewGuest(guests, guestIndex);
    } else {
      this.onRemoveExistingGuest(guestForm);
    }
  }

  onRemoveExistingGuest(guestForm: UntypedFormGroup) {
    const attachData = this.buildGuestAttachData(guestForm);
    this.attachDetachGuest('delete', attachData);
  }

  onRemoveNewGuest(guests: UntypedFormArray, guestIndex: number) {
    guests.removeAt(guestIndex);
    this.changeDetectionRef.markForCheck();
  }

  onDeleteGuestSent(customerId: number) {
    this.deleteGuestSent.emit(customerId);
  }

  attachDetachGuest(
    actionType: 'new' | 'delete',
    attachData: AttchGuestToReservationRequest,
  ) {
    this.attachGuestToReservationEvent.emit({
      data: attachData,
      actionType,
    });
  }

  attachCustomer(actionType: 'new' | 'delete', guestForm?: UntypedFormGroup) {
    this.stopPropagation(event);
    const attachData = this.buildGuestAttachData(guestForm);

    this.attachGuestToReservationEvent.emit({
      data: attachData,
      actionType,
    });
  }

  setGuestTypedisabled(guests: IstatGuest[], currentGuestTypeId: number) {
    // if (!guests.length) {
    //   return currentGuestTypeId === 4 || currentGuestTypeId === 5;
    // }
    // const existsBossGuest = guests.find(
    //   (guest) => guest.guest_type_id === 2 || guest.guest_type_id === 3,
    // );

    // return existsBossGuest
    //   ? currentGuestTypeId === 2 || currentGuestTypeId === 3
    //   : false;
    return false;
  }

  setGuestTypeId(guests: IstatGuest[], totalGuests: number) {
    if (totalGuests === 1) {
      return 1;
    }
    if (!guests.length) {
      return 1;
    }

    const existsBossFamily = guests.find((guest) => guest.guest_type_id === 2);
    if (existsBossFamily) {
      return 4;
    }
    const existsBossGroup = guests.find((guest) => guest.guest_type_id === 3);

    if (existsBossGroup) {
      return 5;
    }
    const existsSomeFamilyMember = guests.some(
      (guest) => guest.guest_type_id === 4,
    );
    if (!existsBossFamily && existsSomeFamilyMember) {
      return 2;
    }

    const existsSomeGroupMember = guests.some(
      (guest) => guest.guest_type_id === 5,
    );
    if (!existsBossGroup && existsSomeGroupMember) {
      return 3;
    }
    return 1;
  }

  createAddGuestModal(accommodationForm: UntypedFormGroup) {
    this.stopPropagation(event);

    const {
      arrival_date,
      departure_date,
      reservation_accommodation_room_id: reservation_acc_room_id,
    } = accommodationForm.value as IstatAccommodation;

    const reservationForm = accommodationForm.parent.parent
      .value as IstatReservation;
    const { reservationId: reservation_id, accommodations } = reservationForm;

    const reservationGuests = accommodations.reduce(
      (acc, curr) => [...acc, ...curr.guests],
      [],
    );
    const reservationTolatGuest = accommodations.reduce(
      (tot, curr) => tot + curr.totalGuests,
      0,
    );

    const formGuests = accommodationForm.get('guests') as UntypedFormArray;

    const guest_type_id = this.setGuestTypeId(
      reservationGuests,
      reservationTolatGuest,
    );

    const customerTypes: GuestTypeOption[] = this.customerTypes.map((type) => {
      return {
        ...type,
        disabled: this.setGuestTypedisabled(reservationGuests, type.id),
      };
    });

    const modal = this.modalService.create<
      AddCustomerComponent,
      Pick<
        IstatGuestModalData,
        | 'customerTypes'
        | 'languages'
        | 'cityTaxExemptionData'
        | 'defaultData'
        | 'dateForValidation'
      >
    >({
      nzTitle: upperFirst(this.translateService.instant('add_guest')),
      nzContent: AddCustomerComponent,
      nzData: {
        customerTypes: customerTypes.filter((customerType) =>
          this.availableGuestMap[
            accommodationForm?.value?.reservation_accommodation_room_id
          ].some((id) => customerType.id === id),
        ),
        languages: this.languages,
        cityTaxExemptionData: this.cityTaxExemptionData,
        defaultData: {
          arrival_date: new Date(arrival_date),
          departure_date: new Date(departure_date),
          guest_type_id,
        },
        dateForValidation: {
          arrival_date: this.isIstatReporting
            ? this.referenceDate
            : new Date(this.referenceDate),
          departure_date: new Date(departure_date),
        },
      },
      nzFooter: [
        {
          label: capitalize(this.translateService.instant('cancel')),
          onClick: () => modal.close(),
        },
        {
          label: capitalize(this.translateService.instant('ok')),
          type: 'primary',
          show: true,
          onClick: (component) => {
            const { createGuest, guestDataForm } = component;
            const {
              arrival_date: arrivalDate,
              city_tax_exemption_id,
              customer_id,
              departure_date: departureDate,
              guest_type_id: guestTypeId,
              language_id,
            } = guestDataForm.value as AddGuestForm;
            handleFormSubmit([guestDataForm], () => {
              const guestData: AddGuestForm = {
                city_tax_exemption_id,
                customer_id,
                guest_type_id: guestTypeId,
                language_id,
                arrival_date: arrivalDate,
                departure_date: departureDate,
              };
              if (createGuest) {
                this.createNewGuest(formGuests, guestData);
              } else {
                const attachData: AttchGuestToReservationRequest = {
                  reservation_acc_room_id,
                  arrival_date: this.dateFormatter.toServerFormat(arrivalDate),
                  departure_date:
                    this.dateFormatter.toServerFormat(departureDate),
                  reservation_id,
                  guest_type_id: guestTypeId,
                  customer_id,
                  city_tax_exemption_id,
                  res_acc_room_guest_ids: [],
                  res_acc_room_guest_id: null,
                };
                this.attachDetachGuest('new', attachData);
              }
              modal.close();
            });
          },
        },
      ],
    });
  }

  // COMMENTATO PROVVISORIAMENTE DA REIMPLEMENTARE
  // scroll() {
  //   setTimeout(() => {
  //     if (!this.scrollData) {
  //       return;
  //     }
  //     const guests = this.getAccommodationData(
  //       this.scrollData.reservationIndex,
  //       this.scrollData.accommodationIndex,
  //       'guests',
  //     );
  //     const id = guests[guests.length - 1].id.toString();
  //     const firstElement = document.getElementById(id);
  //     if (firstElement) {
  //       firstElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     }
  //     this.scrollData = null;
  //   }, 100);
  // }

  buildReservationFormArray(entries: IstatReservation[]): UntypedFormArray {
    return this.formBuilder.array(
      entries.map((reservation) => {
        const reservationForm: UntypedFormGroup = this.formBuilder.group(
          {
            ...reservation,
            accommodations: this.buildAccommodationsFormArray(
              reservation.accommodations,
            ),
          },
          { validators: sendableForm('accommodations') },
        );

        this.subs.add(
          reservationForm
            .get('send_to_external')
            .valueChanges.subscribe((send_to_external) => {
              this.onReservationSwitch(send_to_external, reservationForm);
            }),
        );
        return reservationForm;
      }),
      { validators: sendableFormArray },
    );
  }

  buildAccommodationsFormArray(
    accommodations: IstatAccommodation[],
  ): UntypedFormArray {
    return this.formBuilder.array(
      accommodations.map((accommodation) => {
        const accommodationForm: UntypedFormGroup = this.formBuilder.group(
          {
            ...accommodation,
            guests: this.buildGuestsFormArray(accommodation.guests),
          },
          { validators: sendableForm('guests') },
        );

        this.subs.add(
          accommodationForm
            .get('send_to_external')
            .valueChanges.subscribe((send_to_external) => {
              this.onAccommodationSwitch(send_to_external, accommodationForm);
            }),
        );
        return accommodationForm;
      }),
      { validators: sendableFormArray },
    );
  }

  buildGuestsFormArray(guests: IstatGuest[]): UntypedFormArray {
    return this.formBuilder.array(
      guests.map((guest) => {
        const { ...newGuest } = guest;

        const guestControls = this.buildAbstractControlData(
          {
            ...newGuest,
            city_tax_exemption_id: newGuest?.city_tax_exemption_id || 0,
            arrival_date: new Date(newGuest.arrival_date),
            departure_date: new Date(newGuest.departure_date),
            birthday_date:
              newGuest.birthday_date && new Date(newGuest.birthday_date),
          },
          'customerForm',
        );

        const guestForm = this.formBuilder.group(
          {
            ...guestControls,
          },
          { validators: sendableGuest },
        );

        this.subs.add(
          guestForm
            .get('send_to_external')
            .valueChanges.subscribe((send_to_external) => {
              this.onGuestSwitch(send_to_external, guestForm);
            }),
        );

        return guestForm;
      }),
      { validators: sendableFormArray },
    );
  }

  addCityTaxControl(form: UntypedFormGroup): void {
    form.addControl('city_tax_exemption_id', null);
  }

  buildAbstractControlData(
    data: IstatGuestForm,
    validationType: keyof IstatValidations,
  ): { [controlName: string]: AbstractControlData } {
    return Object.keys(data).reduce((acc, curr) => {
      const validationData = get(this.istatValidations, [validationType, curr]);

      if (this.istatValidations && validationData) {
        const value = validationData[0];
        const validators = validationData[1];

        acc = {
          ...acc,
          [curr]: [data[curr] ? data[curr] : value, validators],
        };
      } else {
        acc = {
          ...acc,
          [curr]: data[curr],
        };
      }

      return acc;
    }, {});
  }

  setExpiredDocumentRecords() {
    const { value } = this.form;

    this.expiredDocumentRecords = {};

    value.reservations?.forEach(({ reservationId, accommodations }) => {
      accommodations.forEach(({ roomreservation_id, guests }) => {
        guests.forEach(({ customer_id, expire_date }) => {
          const expireDate = getNullableDate(expire_date);

          if (expireDate && moment(expireDate).isBefore(moment(), 'days')) {
            this.expiredDocumentRecords = merge(
              {},
              { ...this.expiredDocumentRecords },
              {
                [reservationId]: {
                  [roomreservation_id]: {
                    [customer_id]: true,
                  },
                },
              },
            );
          }
        });
      });
    });
  }

  get reservationsControl(): UntypedFormArray {
    return this.form.get('reservations') as UntypedFormArray;
  }

  get reservations(): IstatReservation[] {
    return this.reservationsControl.value as IstatReservation[];
  }

  get isAtLeastOneGuestNew(): boolean {
    if (!this.reservations) {
      return false;
    }
    return this.reservations.some(({ accommodations }) =>
      accommodations.some(({ guests }) =>
        guests[0] ? !guests[0].customer_id : false,
      ),
    );
  }

  checkAllGuestSent() {
    this.checkAllGuestStatus.emit();
  }
}
