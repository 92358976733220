import moment from 'moment';

import { ReservationGuestFactory } from '../../../models';

export function totalGuestFactory(
  allAccommodations,
  arrival_date: string,
  departure_date: string,
  guestType: 'adults' | 'children',
): ReservationGuestFactory {
  let numAdults = 0;
  let numChildren = 0;
  let totalChildren = [];
  const arrival = moment(arrival_date, 'YYYY-MM-DD');
  const departure = moment(departure_date, 'YYYY-MM-DD');
  const toDay = moment();

  const sortDays = (rooms): any[] => {
    return rooms.map((room) => {
      return {
        ...room,
        days: room.days
          .slice()
          .sort((a, b) =>
            moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD'), 'day'),
          ),
      };
    });
  };
  const allRooms = allAccommodations.reduce((acc, curr) => {
    acc = [...acc, ...sortDays(curr.rooms)];
    return acc;
  }, []);
  let totalGuestFordays = allRooms.reduce((acc, curr) => {
    curr.days.forEach((day) => {
      const { date } = day;
      acc[date] = {
        adults: acc[date]
          ? acc[date].adults + day.adults_number
          : day.adults_number,
        children: acc[date]
          ? acc[date].children + day.children_number
          : day.children_number,
        total_children: acc[date]
          ? [
              ...(acc[date]?.total_children || []),
              ...(day?.total_children || []),
            ]
          : day?.total_children,
      };
    });
    return acc;
  }, {});

  // il seguente IF è necessario nel caso in cui ci sia una prenotazione con camere
  // con date diverse e all'interno della timeline della prenotazione ci sia un day senza alcuna camera
  if (!totalGuestFordays[toDay.format('YYYY-MM-DD')]) {
    totalGuestFordays = {
      ...totalGuestFordays,
      [toDay.format('YYYY-MM-DD')]: {
        adults: 0,
        children: 0,
        total_children: [],
      },
    };
  }

  if (toDay.isBetween(arrival, departure, 'day', '[)')) {
    numAdults = totalGuestFordays[toDay.format('YYYY-MM-DD')].adults;
    numChildren = totalGuestFordays[toDay.format('YYYY-MM-DD')].children;
    totalChildren =
      totalGuestFordays[toDay.format('YYYY-MM-DD')]?.total_children;
  } else if (toDay.isBefore(arrival, 'day')) {
    numAdults = numAdults + totalGuestFordays[arrival_date].adults;
    numChildren = numChildren + totalGuestFordays[arrival_date].children;
    totalChildren = [
      ...totalChildren,
      ...(totalGuestFordays[arrival_date]?.total_children || []),
    ];
  } else {
    numAdults =
      numAdults +
      totalGuestFordays[
        moment(departure_date, 'YYYY-MM-DD')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      ].adults;
    numChildren =
      numChildren +
      totalGuestFordays[
        moment(departure_date, 'YYYY-MM-DD')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      ].children;
    totalChildren = [
      ...totalChildren,
      ...(totalGuestFordays[
        moment(departure_date, 'YYYY-MM-DD')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      ]?.total_children || []),
    ];
  }
  if (guestType === 'adults') {
    return { numAdults };
  } else {
    return { numChildren, totalChildren: totalChildren || [] };
  }
}
