<div
  [ngStyle]="styleCustom"
  class="editable-label-container"
  [ngClass]="{ notAllowed: !canWrite }"
>
  <span
    *ngIf="!edit"
    (click)="
      open($event.stopPropagation());
      clickEvent.emit(edit);
      clickEditEvent.emit('edit')
    "
    (mouseenter)="canWrite && (showPen = true)"
    (mouseleave)="canWrite && (showPen = false)"
  >
    <span
      style="word-break: break-word"
      *ngIf="!nameViewSelect"
      class="{{
        (type === 'multiple' && value && value.length === 0) ||
        (type !== 'multiple' && !value)
          ? 'placeholder'
          : classLabel
      }}"
      >{{
        valueToShow
          ? (valueToShow | translate | capitalize)
          : placeholder
          ? placeholder
          : ('placeholder.editor_placeholder' | translate)
      }}</span
    >
    <span
      *ngIf="nameViewSelect"
      class="{{
        (type === 'multiple' && value && value.length === 0) ||
        (type !== 'multiple' && !value)
          ? 'placeholder'
          : classLabel
      }}"
      >{{ nameViewSelect }}</span
    >
    <i *ngIf="showPen || showPenAlways" class="fas fa-pen by-pen"></i>
  </span>
  <ng-container *ngIf="edit">
    <div (click)="open($event.stopPropagation()); clickEvent.emit(edit)">
      <div
        (focusout)="blurEvent.emit($event)"
        style="display: flex; align-items: center"
      >
        <input
          (keyup.enter)="keyEvent($event)"
          #input
          [ngClass]="{ 'editable-input': !showPenAlways }"
          *ngIf="type === 'input'"
          (input)="pushChanges($event.target.value)"
          nz-input
          placeholder="{{ placeholder }}"
          [ngModel]="value"
        />
        <nz-select
          class="editable-select"
          #select
          [nzMode]="mode"
          *ngIf="type === 'select' || type === 'grouped-select'"
          (ngModelChange)="onSelectChange($event)"
          [ngModel]="value"
          nzSize="small"
          (nzOpenChange)="selectHandle($event)"
          [nzPlaceHolder]="placeholder"
          [nzDropdownMatchSelectWidth]="false"
        >
          <ng-container *ngIf="type === 'select'">
            <ng-container *ngFor="let option of listOfOption">
              <nz-option
                [nzHide]="option.hide"
                [nzLabel]="option.label | translate"
                [nzValue]="option.value"
                [nzDisabled]="option?.disabled"
              ></nz-option>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="type === 'grouped-select'">
            <nz-option-group
              *ngFor="let group of listOfOption || {} | keys"
              [nzLabel]="groupsNames[group]"
            >
              <nz-option
                *ngFor="let option of listOfOption[group]"
                [nzHide]="option.hide"
                [nzLabel]="option.label"
                [nzValue]="option.value"
                [nzDisabled]="option?.disabled"
              ></nz-option>
            </nz-option-group>
          </ng-container>
        </nz-select>
        <ng-container *ngIf="type === 'lookup'">
          <by-lookup
            class="editable-select"
            [byStyle]="{
              display: 'inline-block',
              'min-width': 0,
              width: '100%'
            }"
            [byClass]="null"
            [ngModel]="value"
            [nzAllowClear]="nzAllowClear"
            (ngModelChange)="onLookupChange($event)"
            [options]="listOfOption"
            [dropdownMatchWidth]="false"
            [showArrow]="false"
            size="small"
            [placeholder]="
              'placeholder.insert'
                | translate: { param: placeHolderLookup | translate }
            "
            (search)="onSearchCustomer($event)"
          ></by-lookup>
        </ng-container>
        <ng-container *ngIf="type === 'input'">
          <ng-container *ngIf="!confirmRequest">
            <i
              class="fas fa-check close-edit"
              (click)="close(); onSaveValue(value)"
            ></i>
          </ng-container>
          <ng-container *ngIf="confirmRequest">
            <i
              class="fas fa-check close-edit"
              nz-popconfirm
              [nzPopconfirmTitle]="confirmRequestMessage"
              (nzOnConfirm)="close(); onSaveValue(valueRequestChange)"
              (nzOnCancel)="close()"
            ></i>
          </ng-container>
        </ng-container>

        <i
          class="fas fa-times close-edit nosave by-ml-10"
          (click)="close(); closeEvent.emit()"
        ></i>
      </div>
    </div>
  </ng-container>
</div>
