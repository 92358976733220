import { Action } from '@ngrx/store';

import { IFilter } from '../models/filter';

export enum FiltersActionTypes {
  LoadAccommodationsRequest = '[Filters] Load Accommodations Request',
  LoadAccommodationsSuccess = '[Filters] Load Accommodations Success',
  LoadAccommodationsFailure = '[Filters] Load Accommodations Failure',

  LoadRateplansRequest = '[Filters] Load Rateplans Request',
  LoadRateplansSuccess = '[Filters] Load Rateplans Success',
  LoadRateplansFailure = '[Filters] Load Rateplans Failure',

  LoadChannelsRequest = '[Filters] Load Channels Request',
  LoadChannelsSuccess = '[Filters] Load Channels Success',
  LoadChannelsFailure = '[Filters] Load Channels Failure',
}

export class LoadAccommodationsRequest implements Action {
  readonly type = FiltersActionTypes.LoadAccommodationsRequest;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadAccommodationsSuccess implements Action {
  readonly type = FiltersActionTypes.LoadAccommodationsSuccess;
  constructor(
    public payload: {
      propertiesIds?: number[];
      accommodations: { [propertyId: number]: IFilter[] };
    },
  ) {}
}

export class LoadAccommodationsFailure implements Action {
  readonly type = FiltersActionTypes.LoadAccommodationsFailure;
  constructor(public error: any) {}
}

export class LoadRateplansRequest implements Action {
  readonly type = FiltersActionTypes.LoadRateplansRequest;
  constructor(
    public payload: { propertiesIds: number[]; accommodationsIds: number[] },
  ) {}
}

export class LoadRateplansSuccess implements Action {
  readonly type = FiltersActionTypes.LoadRateplansSuccess;
  constructor(
    public payload: {
      accommodationsIds: number[];
      rateplans: { [propertyId: number]: IFilter[] };
    },
  ) {}
}

export class LoadRateplansFailure implements Action {
  readonly type = FiltersActionTypes.LoadRateplansFailure;
  constructor(public error: any) {}
}

export class LoadChannelsRequest implements Action {
  readonly type = FiltersActionTypes.LoadChannelsRequest;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadChannelsSuccess implements Action {
  readonly type = FiltersActionTypes.LoadChannelsSuccess;
  constructor(
    public payload: { channels: { [propertyId: number]: IFilter[] } },
  ) {}
}

export class LoadChannelsFailure implements Action {
  readonly type = FiltersActionTypes.LoadChannelsFailure;
  constructor(public error: any) {}
}

export type FiltersActions =
  | LoadAccommodationsRequest
  | LoadAccommodationsSuccess
  | LoadAccommodationsFailure
  | LoadRateplansRequest
  | LoadRateplansSuccess
  | LoadRateplansFailure
  | LoadChannelsRequest
  | LoadChannelsSuccess
  | LoadChannelsFailure;
