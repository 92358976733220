import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  CustomerApiData,
  CustomerListItem,
  IAddressTypeRoot,
  ICustomerTypeRoot,
  IDocumentTypeRoot,
} from '../../models';

export const featureAdapter: EntityAdapter<CustomerListItem> =
  createEntityAdapter<CustomerListItem>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<CustomerListItem> {
  isLoading?: boolean;
  error?: any;
  documentTypes: IDocumentTypeRoot[];
  customerTypes: ICustomerTypeRoot[];
  customerCategories: { id: number; name: string }[];
  addressTypes: IAddressTypeRoot[];
  pagination: IPagination;
  customerDetails: CustomerApiData;
  isLoadingDetails: boolean;
  uploadErrors: {
    [row: number]: string[];
  };
  exportFileId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  documentTypes: null,
  customerTypes: null,
  customerCategories: null,
  addressTypes: null,
  pagination: null,
  customerDetails: null,
  isLoadingDetails: false,
  uploadErrors: {},
  exportFileId: null,
});
