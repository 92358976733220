import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_CATEGORIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_CATEGORIES_SUCCESS: {
      const { facilityCategories } = action.payload;
      return {
        ...state,
        general: {
          ...state.general,
          categories: facilityCategories,
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.LOAD_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_GENERAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_GENERAL_SUCCESS: {
      const { facilityCategories, type } = action.payload;
      return {
        ...state,
        general: {
          ...state.general,
          [type]: facilityCategories,
        },
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_GENERAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_RESOUCE_FACILITIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_RESOUCE_FACILITIES_SUCCESS: {
      const { facilities, type } = action.payload;
      return {
        ...state,
        details: {
          ...state.details,
          [type]: facilities,
        },
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_RESOUCE_FACILITIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
