<div nz-row style="padding: 0 10px">
  <div nz-col nzFlex="auto">
    <by-page-header [titleTemplate]="title"></by-page-header>
    <ng-template #title>
      <span style="font-size: 14px">
        {{ 'apply_a_discount' | translate | capitalize }}
      </span>
    </ng-template>
  </div>
</div>
<div class="container" [formGroup]="form">
  <div nz-row nzJustify="space-between" nzAlign="middle" class="row old-total">
    <div nz-col>
      <span class="bolder">{{ 'total' | translate | upperfirst }}</span>
    </div>
    <div nz-col>
      <by-currency-format
        class="color--grey-dark"
        [currencySymbol]="currencySymbol"
        [currencyValue]="totalPriceWithoutDiscount"
      ></by-currency-format>
    </div>
  </div>
  <div nz-row nzJustify="space-between" nzAlign="middle" class="row discount">
    <div nz-col>
      <span class="bolder">{{ 'discount' | translate | upperfirst }}</span>
    </div>
    <div nz-col>
      <nz-input-group nzCompact class="discount-input-group">
        <nz-select formControlName="discount_type_id" [nzShowArrow]="false">
          <nz-option [nzValue]="4" [nzLabel]="currencySymbol"></nz-option>
          <nz-option [nzValue]="5" nzLabel="%"></nz-option>
        </nz-select>
        <nz-input-number
          byDecimalOnly
          formControlName="discount_value"
          [nzMin]="0"
          [nzMax]="
            form.value.discount_type_id === 4 ? totalPriceWithoutDiscount : 100
          "
          [nzStep]="1"
        ></nz-input-number>
      </nz-input-group>
    </div>
  </div>
  <div nz-row nzJustify="space-between" nzAlign="middle" class="row new-total">
    <div nz-col>
      <span class="bolder">{{ 'total' | translate | upperfirst }}</span>
    </div>
    <div nz-col>
      <by-currency-format
        class="bolder"
        [currencySymbol]="currencySymbol"
        [currencyValue]="newTotalPrice"
      ></by-currency-format>
    </div>
  </div>
</div>
