<div nz-row>
  <by-page-header
    class="by-w-100 by-pt-5"
    [title]="'email_templates' | translate"
  >
    <by-click-to-search [formControl]="search"></by-click-to-search>
  </by-page-header>
</div>

<div *ngIf="propertiesIds && propertiesIds.length > 1" nz-row>
  <div nz-col>
    <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzRequired>
          {{ 'select_property' | translate | upperfirst }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            (ngModelChange)="load($event)"
            nzSize="small"
            style="min-width: 200px"
            [ngModel]="propertyId"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="let property of propertiesIds"
              [nzValue]="property"
              [nzLabel]="propertiesNames[property]"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

<div nz-row>
  <by-send-email-modal-list
    class="by-w-100"
    [propertySelectedLangIsoCode]="propertySelectedLanguage$ | async"
    [smsTemplateCustom]="emailTemplatesCustom$ | async"
    [languages]="languages$ | async"
    [emailTemplatesCustomIsLoading]="emailTemplatesCustomIsLoading$ | async"
    (loadTemplatePreviewEvent)="loadEmailTemplateCustomPreview($event)"
    (reloadTemplateList)="onReloadTemplateList()"
  ></by-send-email-modal-list>
  <div nz-row>
    <by-send-email-modal-preview-message
      [emailTemplatePreviewDefaultLanguage]="
        emailTemplatePreviewDefaultLanguage$ | async
      "
      [emailTemplatePreviewContent]="emailTemplatePreviewContent$ | async"
    ></by-send-email-modal-preview-message>
  </div>
</div>
