import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOfPipe',
})
export class TypeOfPipe implements PipeTransform {
  transform(value: any): string {
    return typeof value;
  }
}
