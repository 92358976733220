import { Action } from '@ngrx/store';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CreateEventRequest, Event, UpdateEventRequest } from '../../models';
import { LoadEventRequest } from '../../models/requests/load-event-request';

export enum ActionTypes {
  LOAD_REQUEST = '[Events] Load Request',
  LOAD_FAILURE = '[Events] Load Failure',
  LOAD_SUCCESS = '[Events] Load Success',

  DELETE_REQUEST = '[Events] Delete Request',
  DELETE_FAILURE = '[Events] Delete Failure',
  DELETE_SUCCESS = '[Events] Delete Success',

  CREATE_REQUEST = '[Events] Create Request',
  CREATE_FAILURE = '[Events] Create Failure',
  CREATE_SUCCESS = '[Events] Create Success',

  UPDATE_REQUEST = '[Events] Upadte Request',
  UPDATE_FAILURE = '[Events] Upadte Failure',
  UPDATE_SUCCESS = '[Events] Upadte Success',

  RESET_STATE = '[Events] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: LoadEventRequest) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Event[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertyId: number; eventId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { eventId: number }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      request: CreateEventRequest;
      onSuccess?: (response: IResponseSuccess) => void;
      onFailure?: () => void;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      eventId: number;
      request: UpdateEventRequest;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | ResetSuccessAction;
