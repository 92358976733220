import { createAction, props } from '@ngrx/store';

import { IEmailTemplateSystem } from '../../features/commons/email-templates/models/email-template-system.model';
import { SystemTemplateTypes } from '../../models';

export const loadRequest = createAction(
  '[Email Templates System] Load Request',
  props<{
    propertyId: number;
    params?: Partial<{
      type: SystemTemplateTypes[];
    }>;
  }>(),
);
export const loadSuccess = createAction(
  '[Email Templates System] Load Success',
  props<{
    templates: IEmailTemplateSystem[];
  }>(),
);
export const loadFailure = createAction(
  '[Email Templates System] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Email Templates System] Reset State');
