import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ICoreState } from '@app/core/+state/core.reducer';
import { nzSelectAll } from '@app/core/helpers/nz-option-select-all';
import { IPreferenceUpdate } from '@app/core/models/api/generics/preferences/preference.model';
import {
  ExportTable,
  TableAction,
  TableColumn,
  TableOptions,
} from '@app/models';
import {
  UserPreferencesStoreActions,
  UserPreferencesStoreSelectors,
} from '@app/root-store/user-preferences-store';
import { IPagination } from '@core/models/api/pagination/pagination.model';
import { Store } from '@ngrx/store';
import { filter, get, isEqual, isNil, pick, without } from 'lodash';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, take } from 'rxjs/operators';

import { DEFAULT_PAYMENTS_COLORS } from '../../core/helpers/default-colors';

@Component({
  selector: 'by-customizable-table',
  templateUrl: './customizable-table.component.html',
  styleUrls: ['./customizable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizableTableComponent implements OnInit, OnDestroy {
  @Input() noSelect = false;
  @Input('data') set _data(data: any[]) {
    this.data = data;
    if (!(data && data.length)) {
      return;
    }
    data
      .filter((d) => d.id)
      .forEach((d) => {
        this.dataChecked = { ...this.dataChecked, [d.id]: false };
      });
  }

  @Input() set columns(columns: TableColumn[]) {
    this.originalColumns = columns;
    this.filteredColumns = columns;
    this.filterChange.emit(this.filteredColumns);
  }

  @Input() loading = false;

  @Input() set defaultColums(defaultColums: string[]) {
    if (!(defaultColums && defaultColums.length)) {
      this._defaultColums = [];
      return;
    }
    this._defaultColums = defaultColums;
    this.selectAllActions(defaultColums, true);
  }

  @Input() canWrite: { [propertyId: number]: boolean };

  @Input() set updateDefaultColums(updateDefaultColums: string[]) {
    if (!(updateDefaultColums && updateDefaultColums.length)) {
      this._defaultColums = [];
      return;
    }
    this._defaultColums = updateDefaultColums;
    this.selectAllActions(updateDefaultColums, false);
  }

  @Input() pagination: IPagination;

  @Input() options: TableOptions;

  @Input() currencySymbol: string;

  @Input() actions: TableAction[] | null = null;

  @Input() enableCheckBox = false;

  @Input() buttonNewPath = '';

  @Input() topLeftTemplate: TemplateRef<any>;

  @Output() pageChange = new EventEmitter<number>();

  @Output() filterChange = new EventEmitter<TableColumn[]>();

  @Output() actionTriggered = new EventEmitter<{
    emitKey: string;
    props: object;
  }>();

  @Output() export = new EventEmitter<ExportTable>();

  @Output() quickSearch = new EventEmitter<string>();

  @Output() expand = new EventEmitter<{ expandStatus: boolean; item: any }>();

  @Input() isExpandTable = false;

  @Input() exportLink = '';

  @ContentChild('exapandTmpl')
  public exapandTmpl: TemplateRef<any>;

  filtersControl = this.formBuilder.control([]);

  preferences: IPreferenceUpdate = { options: {} };

  quickSearchSubscription: Subscription;
  preferenceSubscriptions: Subscription;

  formFilter: UntypedFormGroup;

  colorsDefaultPayment = DEFAULT_PAYMENTS_COLORS;

  _quickSearch: UntypedFormGroup;

  _defaultColums: string[] = [];

  filteredColumns: TableColumn[] = [];
  originalColumns: TableColumn[] = [];
  indeterminate = false;
  dataChecked: { [id: number]: boolean } = {};
  allChecked = false;
  data: any[];

  @ViewChild('table', { static: true }) public tplTable: NzTableComponent<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _coreStore: Store<ICoreState>,
  ) {
    this.formFilter = this.formBuilder.group({ filters: [[]] });
    this._quickSearch = this.formBuilder.group({ query: [''] });
  }

  ngOnInit() {
    this.preferenceSubscriptions = this._coreStore
      .select(UserPreferencesStoreSelectors.selectUserPreferencesData)
      .pipe(take(1))
      .subscribe((preference) => {
        if (!Object.keys(preference || {}).length) {
          return;
        }
        const filters =
          preference['tablecolumnView'] &&
          preference['tablecolumnView'][this.options.uniqKey];
        this.selectAllActions(filters, true);
      });

    this.quickSearchSubscription = this._quickSearch
      .get('query')
      .valueChanges.pipe(debounceTime(300))
      .subscribe((query: string) => {
        this.quickSearch.emit(query);
      });
  }

  ngOnDestroy() {
    try {
      this.preferenceSubscriptions.unsubscribe();
    } catch (error) {}
    try {
      this.quickSearchSubscription.unsubscribe();
    } catch (error) {}
  }

  onPageChange(page: number) {
    this.tableScrollInToView();
    this.pageChange.emit(page);
  }

  tableScrollInToView() {
    const el = get(
      this.tplTable,
      'itemRender.elementRef.nativeElement.parentElement',
    ) as HTMLElement;
    if (!el) {
      return;
    }
    el.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  sort(sort: { key: string; value: string }): void {
    this.data = [
      ...this.data.sort((a, b) =>
        sort.value === 'ascend'
          ? get(a.data, sort.key) > get(b.data, sort.key)
            ? 1
            : -1
          : get(b.data, sort.key) > get(a.data, sort.key)
          ? 1
          : -1,
      ),
    ];
  }

  onActionTrigger(
    emitKey: string,
    index: number,
    isDisable = false,
    emitProsData?: any,
  ) {
    if (isDisable) {
      return;
    }

    if (emitProsData) {
      this.actionTriggered.emit({
        emitKey,
        props: pick(this.data[index], emitProsData),
      });
      return;
    }

    const { emitProps, checkboxList } = this.actions.find(
      (action) => action.emitKey === emitKey,
    );

    if (checkboxList) {
      const dataChecked = Object.keys(this.dataChecked).filter(
        (id) => this.dataChecked[id],
      );
      if (dataChecked.length <= 1) {
        return;
      }
      this.actionTriggered.emit({
        emitKey: 'merge',
        props: { dataChecked, dataEvent: this.data[index] },
      });
      this.checkAll(false);
      return;
    }

    this.actionTriggered.emit({
      emitKey,
      props: pick(this.data[index], emitProps),
    });
  }

  onFiltersChange(filteredKeys: string[]) {
    if (!filteredKeys) {
      return;
    }
    if (filteredKeys.length === 0) {
      if (this._defaultColums && this._defaultColums.length) {
        this.filteredColumns = filter(this.originalColumns, (column) => {
          return this._defaultColums.indexOf(column.label) !== -1;
        }) as TableColumn[];
      } else {
        this.filteredColumns = [...this.originalColumns];
      }

      return;
    }
    this.filteredColumns = filter(this.originalColumns, (column) => {
      return filteredKeys.indexOf(column.label) !== -1;
    }) as TableColumn[];
    this.filterChange.emit(this.filteredColumns);
  }

  notNil(column: TableColumn) {
    return !isNil(column.label);
  }

  selectAllActions(event, noUpdate?: boolean) {
    if (!event) {
      return;
    }
    const { filters } = this.formFilter.value;

    let newEvent = without(event, undefined);
    if (!newEvent.length) {
      newEvent = this._defaultColums;
    }
    if (isEqual(filters, newEvent)) {
      noUpdate = true;
    }
    nzSelectAll(
      newEvent,
      this.formFilter,
      this.originalColumns,
      'filters',
      'label',
    );
    if (!noUpdate) {
      this.updatePreferences(this.formFilter.get('filters').value);
    }
    if (!this.formFilter.get('filters').value.length) {
      this.selectAllActions(this._defaultColums, true);
      return;
    }
    this.onFiltersChange(this.formFilter.get('filters').value);
  }

  updatePreferences(filteredKeys) {
    if (!this.options || !this.formFilter || !this.preferences) {
      return;
    }
    this.preferences = {
      options: {
        ['tablecolumnView']: {
          [this.options.uniqKey]: filteredKeys,
        },
      },
    };
    this._coreStore.dispatch(
      new UserPreferencesStoreActions.UpdateRequestAction({
        preferences: this.preferences,
      }),
    );
  }

  refreshStatus(id?: number): void {
    if (id) {
      this.dataChecked[id] = !this.dataChecked[id];
    }
    const allCheckedFilter = Object.keys(this.dataChecked).every(
      (key) => this.dataChecked[key],
    );
    const allUnChecked = Object.keys(this.dataChecked).every(
      (key) => !this.dataChecked[key],
    );
    this.allChecked = allCheckedFilter;
    this.indeterminate = !allCheckedFilter && !allUnChecked;
  }

  checkAll(value: boolean): void {
    Object.keys(this.dataChecked).forEach((key) => {
      this.dataChecked[key] = value;
    });
    this.refreshStatus();
  }

  getExpandedRows() {
    return this.data.filter((row) => row.expand);
  }

  onExpand(expandStatus: boolean, item: any) {
    this.expand.emit({ expandStatus, item });
  }
}
