import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isNil } from 'lodash';
import {
  catchError,
  flatMap,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { effectHooks } from '../../helpers';
import { ReservationsService } from '../../services';

import * as fromActions from './actions';
import { ReservationDetailsService } from '../../services/reservation-details.service';
import { Store } from '@ngrx/store';
import { RootState } from '../root-state';
import { of } from 'rxjs';

@Injectable()
export class ReservationsStoreEffects {
  constructor(
    private dataService: ReservationsService,
    private dataServiceDetails: ReservationDetailsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    protected store$: Store<RootState>,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.load(payload).pipe(
          map(({ data, meta }: IResponseSuccess) => {
            return fromActions.loadSuccess({
              items: data,
              pagination: meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDashboardReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDasboardReservationsRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.load(payload).pipe(
          map(({ data, meta }: IResponseSuccess) => {
            return fromActions.loadDasboardReservationsSuccess({
              items: data,
              pagination: meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDasboardReservationsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(
        ({
          reservationId,
          restoreAvailability,
          force_operation,
          remove,
          onSuccess,
          onFailure,
        }) =>
          this.dataService
            .delete(reservationId, force_operation, restoreAvailability)
            .pipe(
              effectHooks({ onSuccess, onFailure }),
              map(() => {
                this.notifications.success('delete_success', 'reservation');
                return fromActions.deleteSuccess({
                  reservationId,
                  force_operation,
                  remove,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.deleteFailure(error));
              }),
            ),
      ),
    ),
  );

  deleteAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAccommodationRequest),
      flatMap(
        ({
          reservationId,
          reservationAccommodationIds,
          tabShifter,
          onFailure,
          onSuccess,
        }) =>
          this.dataService
            .deleteAccommodation(reservationId, reservationAccommodationIds)
            .pipe(
              effectHooks({ onSuccess, onFailure }),
              map(() => {
                this.notifications.success('delete_success', 'accommodation');
                return fromActions.deleteAccommodationSuccess({
                  reservationId,
                  tabShifter: isNil(tabShifter) ? -2 : tabShifter,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.deleteAccommodationFailure(error));
              }),
            ),
      ),
    ),
  );

  setRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setReadRequest),
      switchMap(({ reservationId, read, fromDashboard, skipNotify }) =>
        this.dataService.setRead(reservationId, read).pipe(
          map(() => {
            if (!skipNotify) {
              this.notifications.success('update_success', 'reservation');
            }
            return fromActions.setReadSuccess({
              reservationId,
              read,
              fromDashboard,
            });
          }),
          catchError((error) => {
            return of(fromActions.setReadFailure(error));
          }),
        ),
      ),
    ),
  );

  updateChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateChannelRequest),
      switchMap(({ reservationId, channelId, onSuccess, onFailure }) =>
        this.dataService.updateChannel(reservationId, channelId).pipe(
          map(() => {
            if (onSuccess) {
              onSuccess();
            }

            this.notifications.success('update_success', 'reservation');

            return fromActions.updateChannelSuccess();
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }
            this.errorHandler.handle(error);
            return of(fromActions.updateChannelFailure(error));
          }),
        ),
      ),
    ),
  );

  resendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendEmailRequest),
      switchMap(
        ({
          reservationId,
          roomReservationIDS,
          additionalEmails,
          sendEmailToCompany,
          sendEmailToCustomer,
          sendEmailToProperty,
          templateCustomId,
          templateSystemId,
        }) =>
          this.dataService
            .resendEmail(
              reservationId,
              roomReservationIDS,
              additionalEmails,
              sendEmailToCompany,
              sendEmailToCustomer,
              sendEmailToProperty,
              templateCustomId,
              templateSystemId,
            )
            .pipe(
              map(() => {
                this.notifications.success('notifications.generic_success');
                return fromActions.resendEmailSuccess();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.resendEmailFailure(error));
              }),
            ),
      ),
    ),
  );

  noShow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.noShowRequest),
      switchMap(({ reservationId }) =>
        this.dataService.noShow(reservationId).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.noShowSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.noShowFailure(error));
          }),
        ),
      ),
    ),
  );

  markCreditCardAsInvalid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markCreditCardAsInvalidRequest),
      switchMap(({ reservationId }) =>
        this.dataService.markCreditCardAsInvalid(reservationId).pipe(
          map(() => fromActions.markCreditCardAsInvalidSuccess()),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.markCreditCardAsInvalidFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteReservationDueInvalidCreditCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteReservationDueInvalidCreditCardRequest),
      switchMap(({ reservationId }) =>
        this.dataService
          .deleteReservationDueInvalidCreditCard(reservationId)
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.deleteReservationDueInvalidCreditCardSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.deleteReservationDueInvalidCreditCardFailure(error),
              );
            }),
          ),
      ),
    ),
  );

  synchronizesRoomReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.synchronizesRoomReservationRequest),
      switchMap((data) =>
        this.dataService.synchronizesRoomReservation(data).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.success('room_synchronized');

            return fromActions.synchronizesRoomReservationSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.synchronizesRoomReservationFailure(error));
          }),
        ),
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportFileRequest),
      switchMap((data) =>
        this.dataService.exportFile(data).pipe(
          map(({ data }) => {
            return fromActions.exportFileSuccess({
              exportId: data.export_ids[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFileError());
          }),
        ),
      ),
    ),
  );
  loadTotalDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadMissingTotalDetailsRequest),
      withLatestFrom(this.store$),
      switchMap(([{ reservationID, destination }, store]) => {
        if (
          (destination === 'reservations' &&
            !store?.reservations?.entities?.[reservationID]
              ?.totalDetailsLoaded) ||
          (destination === 'dashboard' &&
            !store?.reservations?.reservationsDashboard.find(
              ({ id }) => id === reservationID,
            )?.totalDetailsLoaded)
        ) {
          return this.dataServiceDetails.loadTotals(reservationID).pipe(
            map((totalDetails) => {
              return fromActions.loadMissingTotalDetailsSuccess({
                reservationID,
                totalDetails: totalDetails.data[0],
                totalDetailsLoaded: true,
                destination,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadMissingTotalDetailsFailure(error));
            }),
          );
        }
        return [fromActions.missingTotalDetailsStopLoading()];
      }),
    );
  });
}
