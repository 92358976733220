import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ICoreState } from '@app/core/+state/core.reducer';
import {
  UserPreferencesStoreActions,
  UserPreferencesStoreSelectors,
} from '@app/root-store/user-preferences-store';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { UserMe } from '../../models';
import { UserMeStoreSelectors } from '../../root-store/user-me-store';

@Component({
  selector: 'by-custom-menu-form-modal',
  templateUrl: './custom-menu-form-modal.component.html',
  styleUrls: ['./custom-menu-form-modal.component.scss'],
})
export class CustomMenuFormModalComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<CustomMenuFormModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input() data: any = this.nzDataModal.data;

  form: UntypedFormGroup;

  linkCustom = [];

  isEdit = false;
  noChange = false;

  preferencesData = {};
  linkMenuArray = [
    {
      link: '/dashboard',
      keyCombination: 'h',
      icon: 'fal fa-tachometer-alt-fast',
      label: 'home',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/tableau',
      keyCombination: 't',
      icon: 'fal fa-th',
      label: 'tableau',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/reservations/list',
      keyCombination: 'l',
      icon: 'fal fa-suitcase-rolling',
      label: 'reservations',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/crm/reservation/new',
      keyCombination: 'r',
      icon: 'fal fa-luggage-cart',
      label: 'new_reservation',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/price-quotations',
      keyCombination: 'p',
      icon: 'fal fa-file-search',
      label: 'quotes',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/crm/price-quotation/new',
      keyCombination: 'k',
      icon: 'fal fa-file-plus',
      label: 'new_estimate',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/rates-and-availabilities/daily-overview',
      keyCombination: 'y',
      icon: 'fal fa-calendar-edit',
      label: 'accommodations_overview',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/rates-and-availabilities/bulk-update',
      keyCombination: 'e',
      icon: 'fal fa-file-import',
      label: 'bulk_update',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/rates-and-availabilities/pricelists-rules/clone-rates',
      keyCombination: 'w',
      icon: 'fal fa-copy',
      label: 'clone_rates',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/rates-and-availabilities/rates-and-offers/rateplans-offers-bundles/rateplans',
      keyCombination: 'q',
      icon: 'fal fa-sack-dollar',
      label: 'rates_and_offers',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/documents/invoices',
      keyCombination: 'i',
      icon: 'fal fa-file-invoice',
      label: 'invoices',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/customers/people',
      keyCombination: 'n',
      icon: 'fal fa-users',
      label: 'customers',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/customers/companies',
      keyCombination: 'm',
      icon: 'fal fa-building',
      label: 'companies',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/configuration/accommodations',
      keyCombination: 'v',
      icon: 'fal fa-bed',
      label: 'accommodations',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/configuration/invoicing/fiscal-printers',
      keyCombination: 's',
      icon: 'fas fa-cash-register ',
      label: 'fiscal_printers',
      color: '#00a7ff',
      disabled: false,
      order: 0,
    },
    {
      link: '/documents/open-charges/active-charges',
      keyCombination: 'c',
      icon: 'fas fa-file-invoice',
      label: 'pass_charge',
      color: '#ffd43b',
      disabled: false,
      order: 0,
    },
  ];

  colorDefault = '#00a7ff';

  linkMenuArrayEdit = [];
  subs = new SubSink();
  userId: number;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private modal: NzModalRef,
    private _coreStore: Store<ICoreState>,
    private _notifications: NotificationService,
    private translate: TranslateService,
  ) {
    this.form = this._formBuilder.group({
      color: ['#00a7ff', [Validators.required]],
      label: ['', [Validators.required]],
      icon: [''],
      keyCombination: ['', [Validators.required]],
      link: ['', [Validators.required]],
      order: [],
    });
  }

  ngOnInit() {
    this.subs.add(
      this._coreStore
        .select(UserPreferencesStoreSelectors.selectUserPreferencesData)
        .subscribe((preference) => {
          this.linkMenuArrayEdit = this.linkMenuArray;
          if (!Object.keys(preference || {}).length) {
            this.linkCustom = [];
            return;
          }
          if (isEqual(this.preferencesData, preference['customLinkMenu'])) {
            return;
          }
          this.preferencesData = preference['customLinkMenu'];
          this.linkCustom = [];
          Object.keys(preference['customLinkMenu'] || {}).forEach((key) => {
            const link: any = preference['customLinkMenu'][key];
            if (Object.keys(link || {}).length) {
              this.linkCustom.push(link);
              this.linkMenuArrayEdit = this.linkMenuArrayEdit.map(
                (linkMenu) => {
                  if (linkMenu.link === link.link) {
                    return { ...linkMenu, disabled: true };
                  }
                  return { ...linkMenu };
                },
              );
            }
          });
          this.linkCustom.sort((a, b) => a.order - b.order);
        }),
    );

    this.subs.add(
      this._coreStore
        .select(UserMeStoreSelectors.selectData)
        .subscribe((userMe: UserMe) => {
          if (!userMe) {
            return;
          }
          this.userId = userMe.id;
        }),
    );
  }

  onChangeColor(event) {
    this.form.controls['color'].setValue(event);
    this.form.controls['color'].markAsDirty();
    this.form.controls['color'].markAsTouched();
  }

  changeLink(event) {
    if (this.noChange) {
      this.noChange = false;
      return;
    }
    const linkMenuFind = this.linkMenuArray.find((link) => link.link === event);
    if (linkMenuFind) {
      const { label, link, ...data } = linkMenuFind;
      this.form.patchValue(
        { ...data, label: this.translate.instant(label) },
        { onlySelf: true, emitEvent: false },
      );
    }
  }

  onSubmit() {
    const { keyCombination, link } = this.form.value;
    if (
      this.preferencesData &&
      this.preferencesData[keyCombination] &&
      !this.isEdit
    ) {
      this._notifications.push({
        title: this.translate.instant('warning'),
        content: this.translate.instant('duplicate_combination_key'),
        type: 'warning',
      });
      this.form.controls['keyCombination'].setValue(null);
      this.form.controls['keyCombination'].markAsDirty();
      this.form.controls['keyCombination'].markAsTouched();
      return;
    }
    const preferences = {
      options: {
        ['customLinkMenu']: {
          [keyCombination.toLowerCase()]: {
            ...this.form.value,
            order: this.linkCustom.length,
          },
        },
      },
    };
    this._coreStore.dispatch(
      new UserPreferencesStoreActions.UpdateRequestAction({
        preferences: preferences,
      }),
    );
    this.form.reset();
  }

  get isSubmitDisabled() {
    return this.form.invalid;
  }

  onDelete() {
    const { keyCombination } = this.form.value;
    const preferences = {
      options: {
        ['customLinkMenu']: {
          [keyCombination.toLowerCase()]: null,
        },
      },
    };
    this._coreStore.dispatch(
      new UserPreferencesStoreActions.UpdateRequestAction({
        preferences: preferences,
      }),
    );
    this.form.reset();
    this.isEdit = false;
    this.form.controls['color'].setValue(this.colorDefault);
  }
  onCancel() {
    this.form.reset();
    this.isEdit = false;
    this.noChange = false;
    this.form.controls['color'].setValue(this.colorDefault);
  }

  closeModal() {
    this.modal.close();
  }

  ngOnDestroy() {
    try {
      this.subs.unsubscribe();
    } catch (error) {}
  }

  editLink(data) {
    this.isEdit = true;
    this.noChange = true;
    this.form.patchValue(data, { onlySelf: true, emitEvent: false });
    this.form.controls['color'].setValue(data.color);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.linkCustom, event.previousIndex, event.currentIndex);
    this.linkCustom.forEach((link, index) => {
      const preferences = {
        options: {
          ['customLinkMenu']: {
            [link.keyCombination.toLowerCase()]: { ...link, order: index },
          },
        },
      };
      this._coreStore.dispatch(
        new UserPreferencesStoreActions.UpdateRequestAction({
          preferences: preferences,
        }),
      );
    });
  }

  resetAllCustomLink() {
    const preferences = {
      options: {
        ['customLinkMenu']: {},
      },
    };
    this._coreStore.dispatch(
      new UserPreferencesStoreActions.UpdateRequestAction({
        preferences: preferences,
      }),
    );
    this.form.reset();
  }
}
