<div nz-row class="by-mb-2">
  <div class="radio-group">
    <span>
      {{ 'you_want_to_add_a_new_guest' | translate | upperfirst }}
    </span>
    <nz-radio-group
      nzButtonStyle="solid"
      [(ngModel)]="createGuest"
      (ngModelChange)="onResetCustomer($event)"
      [nzButtonStyle]="'solid'"
      class="radio-group-control"
    >
      <label
        nz-radio-button
        class="radio-group-control-option"
        [nzValue]="true"
        >{{ 'yes' | translate }}</label
      >
      <label
        nz-radio-button
        class="radio-group-control-option"
        [nzValue]="false"
        >{{ 'no' | translate }}</label
      >
    </nz-radio-group>
  </div>
</div>
<form nz-form [nzLayout]="'vertical'" [formGroup]="guestDataForm">
  <div nz-row class="by-mb-2">
    <nz-form-item class="by-w-100">
      <nz-form-label [nzRequired]="!createGuest">
        {{ 'search_in_registry' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [nzErrorTip]="
          'validation_form.required'
            | translate: { param: 'customer' | translate }
        "
      >
        <by-customers-lookup
          class="lookup-select input-max-width"
          formControlName="customer_id"
          [allowClear]="true"
          [isDisabled]="createGuest"
        ></by-customers-lookup>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div nz-row [nzGutter]="16">
    <div nz-col nzMd="12" nzXs="24">
      <nz-form-item class="form-item-flex-direciotn-inherit">
        <nz-form-label
          nzRequired
          class="by-mr-1 label-date-picker"
          nzFor="from"
          >{{ 'date_from' | translate | capitalize }}</nz-form-label
        >
        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'date_from' | translate }
          "
        >
          <nz-date-picker
            byDisabledMobileKeyboard
            byDisabledDateFixer
            id="from"
            [nzShowToday]="false"
            [nzAllowClear]="false"
            class="by-w-100"
            [nzDisabledDate]="disabledStartDate"
            nzFormat="dd/MM/yyyy"
            formControlName="arrival_date"
            [nzPlaceHolder]="'date_from' | translate"
          >
          </nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzMd="12" nzXs="24">
      <nz-form-item class="form-item-flex-direciotn-inherit">
        <nz-form-label nzRequired class="by-mr-1 label-date-picker" nzFor="to">
          {{ 'date_to' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'date_to' | translate }
          "
        >
          <nz-date-picker
            byDisabledMobileKeyboard
            byDisabledDateFixer
            [nzShowToday]="false"
            [nzDisabledDate]="disabledEndDate"
            [nzAllowClear]="false"
            class="by-w-100"
            nzFormat="dd/MM/yyyy"
            id="to"
            formControlName="departure_date"
            [nzPlaceHolder]="'date_to' | translate"
          >
          </nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzFlex="auto">
      <nz-form-item>
        <nz-form-label class="by-mr-1" nzFor="guest_type_id" nzRequired>
          {{ 'guest_type_id' | translate | capitalize }}
        </nz-form-label>

        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'guest_type_id' | translate }
          "
        >
          <nz-select
            class="by-w-100"
            formControlName="guest_type_id"
            [nzDropdownMatchSelectWidth]="false"
            [nzPlaceHolder]="
              'placeholder.select'
                | translate: { param: 'guest_type_id' | translate }
            "
          >
            <nz-option
              *ngFor="let customerType of customerTypes"
              [nzDisabled]="customerType.disabled"
              [nzValue]="customerType.id"
              [nzLabel]="customerType.name"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzFlex="auto">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="guest_type_id">
          {{ 'exemption_city_tax' | translate | upperfirst }}
        </nz-form-label>
        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'exemption_city_tax' | translate }
          "
        >
          <nz-select
            class="by-w-100"
            formControlName="city_tax_exemption_id"
            [nzPlaceHolder]="
              'placeholder.select'
                | translate: { param: 'exemption_city_tax' | translate }
            "
          >
            <nz-option
              [nzValue]="0"
              [nzLabel]="'none' | translate | capitalize"
            ></nz-option>
            <nz-option
              *ngFor="let exemption of cityTaxExemptionData"
              [nzDisabled]="!exemption?.active"
              [nzValue]="exemption?.id"
              [nzLabel]="exemption?.name"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div *ngIf="createGuest" nz-row>
    <div nz-col nzFlex="auto">
      <nz-form-item>
        <nz-form-label [nzRequired]="createGuest" nzFor="language">
          {{ 'language' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control>
          <nz-select
            class="by-w-100"
            formControlName="language_id"
            [nzPlaceHolder]="
              'placeholder.select'
                | translate: { param: 'language' | translate }
            "
          >
            <nz-option
              *ngFor="let language of languages"
              [nzValue]="language.id"
              [nzLabel]="language.name"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
