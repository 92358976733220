import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_ACL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_ACL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        usersAcl: action.payload.items,
      };
    }

    case ActionTypes.LOAD_ACL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DETACH_PROPERTY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DETACH_PROPERTY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.DETACH_PROPERTY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.ATTACH_PROPERTY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.ATTACH_PROPERTY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.ATTACH_PROPERTY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      const { userId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        usersAcl: state.usersAcl.filter((user) => user.id !== userId),
      };
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.SEARCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
