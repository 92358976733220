import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createAction, props } from '@ngrx/store';

import { Reservation, ReservationTotals } from '../../models';
import { SearchParams } from '../../models/objects/search-params';
import { ExportFileRequestType } from '../../models/types/export-file-request';

export const loadRequest = createAction(
  '[Reservations] Load Request',
  props<SearchParams>(),
);

export const loadSuccess = createAction(
  '[Reservations] Load Success',
  props<{
    items: Reservation[];
    pagination: IPagination;
  }>(),
);

export const loadFailure = createAction(
  '[Reservations] Load Failure',
  props<{ error: any }>(),
);

export const loadDasboardReservationsRequest = createAction(
  '[Reservations] Load Dashboard Reservations Request',
  props<SearchParams>(),
);

export const loadDasboardReservationsSuccess = createAction(
  '[Reservations] Load Dashboard Reservations Success',
  props<{
    items: Reservation[];
    pagination: IPagination;
  }>(),
);

export const loadDasboardReservationsFailure = createAction(
  '[Reservations] Load Dashboard Reservations Failure',
  props<{ error: any }>(),
);

/**
 * @param force_operation se passato a 1 la prenotazione verrà cancellata fisicamente, altrimenti
 * la prenotazione verrà messa in status "Cancelled"
 */
export const deleteRequest = createAction(
  '[Reservations] Delete Request',
  props<{
    reservationId: number;
    restoreAvailability?: boolean;
    force_operation?: number;
    remove?: boolean;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const deleteSuccess = createAction(
  '[Reservations] Delete Success',
  props<{
    reservationId: number;
    force_operation?: number;
    remove?: boolean;
  }>(),
);

export const deleteFailure = createAction(
  '[Reservations] Delete Failure',
  props<{ error: any }>(),
);

/**
 * @description tabShifter rappresenta il numero di quante tab bisogna andare indietro dopo l'eliminazione.
 * Se viene valorizzato con -1 dopo l'eliminazione il dettaglio prenotazione tornerà alla prima tab
 */
export const deleteAccommodationRequest = createAction(
  '[Reservations] Delete Accommodation Request',
  props<{
    reservationId: number;
    reservationAccommodationIds: number[];
    tabShifter?: number;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const deleteAccommodationSuccess = createAction(
  '[Reservations] Delete Accommodation Success',
  props<{ reservationId: number; tabShifter: number }>(),
);

export const deleteAccommodationFailure = createAction(
  '[Reservations] Delete Accommodation Failure',
  props<{ error: any }>(),
);

export const setReadRequest = createAction(
  '[Reservations] Set Read Request',
  props<{
    reservationId: number;
    read: boolean;
    fromDashboard?: boolean;
    skipNotify?: boolean;
  }>(),
);

export const setReadSuccess = createAction(
  '[Reservations] Set Read Success',
  props<{
    reservationId: number;
    read: boolean;
    fromDashboard?: boolean;
  }>(),
);

export const setReadFailure = createAction(
  '[Reservations] Set Read Failure',
  props<{ error: any }>(),
);

export const resendEmailRequest = createAction(
  '[Reservations] Resend Email Request',
  props<{
    reservationId: number;
    roomReservationIDS: string[];
    sendEmailToCustomer: boolean;
    sendEmailToCompany: boolean;
    sendEmailToProperty: boolean;
    additionalEmails: string[];
    templateCustomId: number;
    templateSystemId: number;
  }>(),
);

export const resendEmailSuccess = createAction(
  '[Reservations] Resend Email Success',
);

export const resendEmailFailure = createAction(
  '[Reservations] Resend Email Failure',
  props<{ error: any }>(),
);

export const noShowRequest = createAction(
  '[Reservations] No Show Request',
  props<{
    reservationId: number;
  }>(),
);

export const noShowSuccess = createAction('[Reservations] No Show Success');

export const noShowFailure = createAction(
  '[Reservations] No Show Failure',
  props<{ error: any }>(),
);

export const markCreditCardAsInvalidRequest = createAction(
  '[Reservations] Mark Credit Card As Invalid Request',
  props<{
    reservationId: number;
  }>(),
);

export const markCreditCardAsInvalidSuccess = createAction(
  '[Reservations] Mark Credit Card As Invalid Success',
);

export const markCreditCardAsInvalidFailure = createAction(
  '[Reservations] Mark Credit Card As Invalid Failure',
  props<{ error: any }>(),
);

export const deleteReservationDueInvalidCreditCardRequest = createAction(
  '[Reservations] Delete Reservation Due Invalid Credit Card Request',
  props<{ reservationId: number }>(),
);

export const deleteReservationDueInvalidCreditCardSuccess = createAction(
  '[Reservations] Delete Reservation Due Invalid Credit Card Success',
);

export const deleteReservationDueInvalidCreditCardFailure = createAction(
  '[Reservations] Delete Reservation Due Invalid Credit Card Failure',
  props<{ error: any }>(),
);

export const synchronizesRoomReservationRequest = createAction(
  '[Reservation] Synchronizes Room Reservation Request',
  props<{
    keep_availability: number;
    reservation_accommodation_id: number;
  }>(),
);

export const synchronizesRoomReservationSuccess = createAction(
  '[Reservation] Synchronizes Room Reservation Success',
);

export const synchronizesRoomReservationFailure = createAction(
  '[Reservation] Synchronizes Room Reservation Failure',
  props<{ error: any }>(),
);

export const updateChannelRequest = createAction(
  '[Reservation] Update Channel Reservation Request',
  props<{
    channelId: number;
    reservationId: number;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const updateChannelSuccess = createAction(
  '[Reservation] Update Channel Reservation Success',
);

export const updateChannelFailure = createAction(
  '[Reservation] Update Channel Reservation Failure',
  props<{ error: any }>(),
);

export const setLoading = createAction(
  '[Reservations] Set Loading',
  props<{ loading: boolean }>(),
);

export const loadMissingTotalDetailsRequest = createAction(
  '[Reservation]  Load Missing Total Details Request ',
  props<{
    reservationID: number;
    destination: 'reservations' | 'dashboard';
  }>(),
);

export const loadMissingTotalDetailsSuccess = createAction(
  '[Reservation] Load Missing Total Details Success',
  props<{
    reservationID: number;
    totalDetails: ReservationTotals;
    totalDetailsLoaded: boolean;
    destination?: 'reservations' | 'dashboard';
  }>(),
);

export const loadMissingTotalDetailsFailure = createAction(
  '[Reservation] Load Missing Total Details Failure',
  props<{ error: any }>(),
);

export const missingTotalDetailsStopLoading = createAction(
  '[Reservation] Missing Total Details Stop Loading',
);

export const resetState = createAction('[Reservations] Reset State');

export const resetStateExcludingDashboard = createAction(
  '[Reservations] Reset State Excluding Dashboard',
);

export const exportFileRequest = createAction(
  '[Reservation] Export File Request',
  props<ExportFileRequestType>(),
);

export const exportFileSuccess = createAction(
  '[Reservation] Export File Success',
  props<{ exportId: number }>(),
);

export const exportFileError = createAction('[Reservation] Export File Error');
