import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: true,
})
export class HighlightSearch implements PipeTransform {
  transform(value: any, args: any, color: string = '#ffff00'): any {
    if (!args) {
      return value;
    }
    const re = new RegExp('(?<!<[^>])' + args + '(?![^<]*>)', 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value
      ? value.replace(
          re,
          `<mark style="background-color:${color}" class="color--red">${args}</mark>`,
        )
      : null;
  }
}
