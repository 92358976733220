import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IEmailTemplatePreviewContent } from '@app/models';
import { TranslateModule } from '@ngx-translate/core';

import {
  NgPipesModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SafeHtmlModule } from '../../../pipes/safe-html/safe-html.module';
import { NzAlertModule } from 'ng-zorro-antd/alert';

@Component({
  selector: 'by-send-email-modal-preview-message',
  templateUrl: './send-email-modal-preview-message.component.html',
  styleUrls: ['./send-email-modal-preview-message.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgPipesModule,
    NzFormModule,
    NgUpperFirstPipeModule,
    SafeHtmlModule,
    NzAlertModule,
  ],
})
export class SendEmailModalPreviewMessageComponent implements OnInit {
  @Input('emailTemplatePreviewContent')
  set _emailTemplatePreviewContent(
    emailTemplatePreviewContent: IEmailTemplatePreviewContent,
  ) {
    if (!emailTemplatePreviewContent) {
      return;
    }
    const { body } = emailTemplatePreviewContent;
    this.emailTemplatePreviewContent = {
      ...emailTemplatePreviewContent,
      body: body.replace(/href="(.+)"/, 'href="javascript:void(0)"'),
    };
  }

  @Input()
  emailTemplatePreviewDefaultLanguage: IEmailTemplatePreviewContent;

  emailTemplatePreviewContent: IEmailTemplatePreviewContent;

  constructor() {}

  ngOnInit() {}
}
