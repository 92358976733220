import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionFieldForm } from '@app/features/commons/property-role-details/property-role-form/property-role-form.component';
import {
  Permission,
  PermissionTranslations,
} from '@app/models/objects/permission';
import { isNil, omitBy } from 'lodash';

import { handleFormSubmit } from '../../core/helpers/handleFormSubmit';

@Component({
  selector: 'by-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss'],
})
export class PermissionsListComponent implements OnInit {
  @Input()
  externalForm: UntypedFormGroup;

  @Input()
  disable = false;

  @Input()
  set permissions(permissions: Permission) {
    this._permissions = permissions;
    this.permissionsForm = Object.keys(this._permissions).reduce(
      (acc, curr) => {
        const { create, delete: cancel, read, update } = this._permissions[
          curr
        ];
        acc[curr] = this.formBuilder.group({
          permissionType: this.setPermissionField({
            create,
            update,
            cancel,
            read,
          }),
        });
        return acc;
      },
      {},
    );
    this.checkAllPermissionsType();
  }

  @Input()
  set permissionsForPatchValue(permissionsForPatchValue: Permission) {
    if (
      !permissionsForPatchValue ||
      !Object.keys(this.permissionsForm).length
    ) {
      return;
    }
    Object.keys(permissionsForPatchValue).forEach((permissionName) => {
      const { create, update, delete: cancel, read } = permissionsForPatchValue[
        permissionName
      ];
      this.permissionsForm[permissionName].patchValue({
        permissionType: this.setPermissionField({
          create,
          update,
          cancel,
          read,
        }),
      });
    });
  }

  @Input()
  translations: PermissionTranslations;

  @Output()
  saveEvent = new EventEmitter<Permission>();

  permissionsForm: { [permissionName: string]: UntypedFormGroup };
  _permissions: Permission;
  @Input()
  isFormDirty = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {}

  save() {
    handleFormSubmit([this.externalForm], () => {
      const permissions = Object.keys(this.permissionsForm).reduce(
        (acc, curr) => {
          const { permissionType } = this.permissionsForm[curr].value;
          if (permissionType === 'nobody') {
            return acc;
          }
          acc[curr] = {
            create: permissionType === 'readingWriting' ? true : false,
            read:
              permissionType === 'readingWriting' ||
              permissionType === 'reading'
                ? true
                : false,
            update: permissionType === 'readingWriting' ? true : false,
            delete: permissionType === 'readingWriting' ? true : false,
          };
          return acc;
        },
        {},
      );
      this.saveEvent.emit(permissions);
      this.disable = true;
    });
  }

  setPermissionField(fileds: {
    create: boolean;
    update: boolean;
    cancel: boolean;
    read: boolean;
  }): PermissionFieldForm {
    const { cancel, read, update, create } = fileds;
    if (!cancel && !read && !update && !create) {
      return 'nobody';
    }
    if (create || update || cancel) {
      return 'readingWriting';
    }
    if (read && !cancel && !update && !create) {
      return 'reading';
    }
  }

  checkAllPermissionsType() {
    const permissionNames = Object.keys(this.permissionsForm || {});

    const foundPermissionRW = permissionNames.find((permissionName) => {
      const permissionValue = this.permissionsForm[permissionName].get(
        'permissionType',
      ).value;

      return permissionValue !== 'nobody';
    });

    if (!foundPermissionRW) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  cascadeSelection(type: string) {
    const permissionNames = Object.keys(this.permissionsForm || {});
    permissionNames.forEach((permissionName) => {
      this.permissionsForm[permissionName].patchValue({ permissionType: type });
    });
  }
}
