<div nz-row>
  <ng-container *ngIf="facilities && (facilities | keys).length">
    <div class="facilities-icon-container">
      <ng-container *ngFor="let facility of facilities | keyvalue">
        <nz-tag
          nz-tooltip
          [nzTooltipTitle]="facility.value.label"
          class="by-mt-5 by-mb-5 pointer"
          [nzColor]="facility.value.is_free ? '#58b968' : '#e67e22'"
          (click)="scollToFacility(facility.key)"
        >
          <i
            class="by-mr-1"
            [ngClass]="facility.value.icon || 'far fa-icons'"
          ></i>
          <span>{{ facility.value.label | truncate: 15:'...':true }}</span>
        </nz-tag>
      </ng-container>
    </div>
  </ng-container>
</div>

