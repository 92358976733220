<div nz-row *ngIf="form">
  <nz-collapse
    [nzBordered]="false"
    class="istat-guest-collapse card-with-shadow by-w-100"
  >
    <ng-container
      *ngFor="
        let reservationForm of form.get('reservations')['controls'];
        index as reservationIndex
      "
    >
      <nz-collapse-panel
        #panelReservation
        [hidden]="
          showSentGuest ? false : reservationForm.get('allGuestsSent').value
        "
        [nzHeader]="reservationHeaderTpl"
        [nzExtra]="reservationSwitchTpl"
        [nzExpandedIcon]="expandedIconReservationTpl"
        [ngClass]="'panel-custom-style istat-panel-custom-style'"
        [nzActive]="
          panelActivateReservation[reservationForm.get('reservationId').value]
        "
        (nzActiveChange)="
          expandReservation(reservationForm.get('reservationId').value, $event)
        "
      >
        <ng-template #reservationSwitchTpl>
          <ng-container [formGroup]="reservationForm">
            <div class="istat-guest-switch-container">
              <i
                *ngIf="
                  expiredDocumentRecords[reservationForm.value.reservationId]
                "
                nz-tooltip
                [nzTooltipTitle]="
                  'some_guests_have_expired_document' | translate | capitalize
                "
                class="fas fa-exclamation-triangle color--red by-mr-2"
              ></i>

              <i
                *ngIf="reservationForm.hasError('notSendable')"
                nz-tooltip
                [nzTooltipTitle]="'fill_in_missing_fields' | translate"
                class="icon-warning fas fa-exclamation-triangle by-mr-15"
              ></i>
              <nz-switch
                nz-tooltip
                [nzTooltipTitle]="reservationSwitchTooltipTpl"
                [nzDisabled]="reservationForm.get('atLeastOneGuestSent').value"
                (click)="$event.stopPropagation()"
                formControlName="send_to_external"
              ></nz-switch>
            </div>
            <ng-template #reservationSwitchTooltipTpl>
              <div class="tooltip">
                <span *ngIf="reservationForm.get('atLeastOneGuestSent').value">
                  {{
                    'send_to_external_message'
                      | translate: { param: 'reservation' | translate }
                      | upperfirst
                  }}
                </span>
                <span *ngIf="!reservationForm.get('atLeastOneGuestSent').value">
                  {{
                    (reservationForm.get('send_to_external').value
                      ? 'disable'
                      : 'enable'
                    )
                      | translate
                      | capitalize
                  }}
                  {{ 'sending' | translate }}
                </span>
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template #expandedIconReservationTpl>
          <i
            class="fas fa-caret-down rotation-arrow arrow-istat"
            [ngClass]="{ 'to-top': panelReservation.nzActive }"
          ></i>
        </ng-template>
        <ng-template #reservationHeaderTpl>
          <div class="inline">
            <span class="istat-guest-name">{{
              (reservationForm.get('bookerName').value || '') +
                ' ' +
                (reservationForm.get('bookerSurname').value || '')
            }}</span>
            <div class="info-title-istat">
              <span class="info-title-istat">
                {{ reservationForm.get('arrival_date').value | date: 'dd' }}

                {{
                  reservationForm.get('arrival_date').value
                    | date: 'LLL'
                    | uppercase
                }}

                {{ reservationForm.get('arrival_date').value | date: 'yyyy' }}
                {{ ' - ' }}

                {{ reservationForm.get('departure_date').value | date: 'dd' }}

                {{
                  reservationForm.get('departure_date').value
                    | date: 'LLL'
                    | uppercase
                }}

                {{ reservationForm.get('departure_date').value | date: 'yyyy' }}

                {{ ' - ' + ('reservation' | translate | capitalize) }}

                #{{ reservationForm.get('reference_number').value }}
                <a
                  class="istat-guest-link-reservation"
                  target="_blank"
                  href="/reservation/{{
                    reservationForm.get('reservationId').value
                  }}"
                  ><i class="fas fa-external-link"></i>
                </a>
              </span>
            </div>
          </div>
        </ng-template>
        <nz-collapse [nzBordered]="false" class="istat-guest-collapse">
          <ng-container
            *ngFor="
              let accommodationForm of reservationForm.get('accommodations')[
                'controls'
              ];
              index as accommodationIndex
            "
          >
            <nz-collapse-panel
              #panelAccommodation
              [hidden]="
                showSentGuest
                  ? false
                  : accommodationForm?.get('allGuestsSent')?.value
              "
              [nzHeader]="accommodationHeaderTpl"
              [nzExtra]="accommodationSwitchTpl"
              [nzExpandedIcon]="expandedIconAccommodationTpl"
              [ngClass]="'panel-custom-style mb-0'"
              [nzActive]="
                panelActivate[accommodationForm.get('roomreservation_id').value]
              "
              (nzActiveChange)="
                expandGuests(
                  accommodationForm.get('roomreservation_id').value,
                  $event
                )
              "
              [formGroup]="accommodationForm"
            >
              <ng-template #expandedIconAccommodationTpl>
                <i
                  class="fas fa-caret-down rotation-arrow arrow-istat arrow-istat-accommodation"
                  [ngClass]="{ 'to-top': panelAccommodation.nzActive }"
                ></i>
              </ng-template>
              <ng-template #accommodationSwitchTpl>
                <ng-container [formGroup]="accommodationForm">
                  <div class="by-mt-10 istat-room-switch">
                    <i
                      *ngIf="
                        expiredDocumentRecords
                          | get
                            : [
                                reservationForm.value.reservationId,
                                accommodationForm.value.roomreservation_id
                              ]
                      "
                      nz-tooltip
                      [nzTooltipTitle]="
                        'some_guests_have_expired_document'
                          | translate
                          | capitalize
                      "
                      class="fas fa-exclamation-triangle color--red by-mr-2"
                    ></i>

                    <i
                      *ngIf="accommodationForm.hasError('notSendable')"
                      nz-tooltip
                      [nzTooltipTitle]="
                        !accommodationForm.get('guests').length
                          ? ('empty_guest_message' | translate | upperfirst)
                          : ('fill_in_missing_fields' | translate)
                      "
                      class="icon-warning fas fa-exclamation-triangle by-mr-15"
                    ></i>
                    <nz-switch
                      nz-tooltip
                      [nzTooltipTitle]="accommodationSwitchTooltipTpl"
                      [nzDisabled]="
                        accommodationForm.get('atLeastOneGuestSent').value
                      "
                      formControlName="send_to_external"
                      (click)="$event.stopPropagation()"
                      nzSize="small"
                    ></nz-switch>
                  </div>
                  <ng-template #accommodationSwitchTooltipTpl>
                    <div class="tooltip">
                      <span
                        *ngIf="
                          accommodationForm.get('atLeastOneGuestSent').value
                        "
                      >
                        {{
                          'send_to_external_message'
                            | translate: { param: 'accommodation' | translate }
                            | upperfirst
                        }}
                      </span>
                      <span
                        *ngIf="
                          !accommodationForm.get('atLeastOneGuestSent').value
                        "
                      >
                        {{
                          (accommodationForm.get('send_to_external').value
                            ? 'disable'
                            : 'enable'
                          )
                            | translate
                            | capitalize
                        }}
                        {{ 'sending' | translate }}
                      </span>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-template>
              <ng-template #accommodationHeaderTpl>
                <div class="inline title">
                  <span
                    >#{{
                      accommodationForm.get('accommodation_tableau_label')
                        .value +
                        ' - ' +
                        accommodationForm.get('name').value
                    }}</span
                  >
                  <div>
                    <span class="info-title-istat">
                      {{ accommodationForm.get('guests').value.length }}
                      /
                      {{ accommodationForm.get('totalGuests').value }}
                      {{ 'guests' | translate | capitalize }}
                    </span>

                    <div
                      style="display: inline"
                      nz-tooltip
                      [nzTooltipTitle]="
                        isAtLeastOneGuestNew ||
                        !accommodationForm.get('send_to_external').value
                          ? addGuestTooltipTpl
                          : null
                      "
                    >
                      <ng-template #addGuestTooltipTpl>
                        <div class="tooltip">
                          <span *ngIf="isAtLeastOneGuestNew">
                            {{
                              'saves_the_guest_before_message'
                                | translate
                                | upperfirst
                            }}.
                          </span>
                          <span
                            *ngIf="
                              !accommodationForm.get('send_to_external').value
                            "
                          >
                            {{ 'enable_room' | translate | upperfirst }}.
                          </span>
                        </div>
                      </ng-template>
                      <div
                        nz-tooltip
                        [nzTooltipTitle]="
                          !isAtLeastOneGuestNew &&
                          accommodationForm.get('send_to_external').value
                            ? ('add_guest' | translate | capitalize)
                            : null
                        "
                        class="user-icon-container"
                        [ngClass]="{
                          'disable-click':
                            isAtLeastOneGuestNew ||
                            !accommodationForm.get('send_to_external').value
                        }"
                        *ngIf="
                          accommodationForm.get('totalGuests').value >
                          accommodationForm.get('guests').value.length
                        "
                        (click)="createAddGuestModal(accommodationForm)"
                      >
                        <i class="fas fa-user-plus"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-container formArrayName="guests">
                <ng-container
                  *ngIf="
                    accommodationForm.get('guests').controls.length;
                    else noGuestTpl
                  "
                >
                  <ng-container
                    *ngFor="
                      let guestForm of accommodationForm.get('guests')[
                        'controls'
                      ];
                      index as guestIndex
                    "
                  >
                    <div class="by-mt-2">
                      @if (panelAccommodation?.nzActive) {
                        <by-guest-card
                          [languages]="languages"
                          [hidden]="
                            showSentGuest
                              ? false
                              : guestForm.get('reportedStatus').value === 'sync'
                          "
                          [form]="guestForm"
                          [availableGuestType]="
                            availableGuestMap?.[
                              accommodationForm.get(
                                'reservation_accommodation_room_id'
                              )?.value
                            ]
                          "
                          [customerTypes]="customerTypes"
                          [documentTypes]="documentTypes"
                          [showSwitch]="
                            accommodationForm.get('send_to_external').value
                          "
                          [cityTaxExemptionData]="cityTaxExemptionData"
                          [loading]="saveGuestLoading"
                          [agencySpecificationData]="agencySpecificationData"
                          (removeGuest)="onRemoveGuest(guestForm, guestIndex)"
                          (saveGuest)="onSaveGuest(guestForm)"
                          (deleteGuestSent)="onDeleteGuestSent($event)"
                        />
                      }
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noGuestTpl>
                  <div
                    class="no-guest-template"
                    nz-tooltip
                    [nzTooltipTitle]="
                      isAtLeastOneGuestNew ||
                      !accommodationForm.get('send_to_external').value
                        ? noGuesttTooltipTpl
                        : null
                    "
                  >
                    <a
                      [ngClass]="{
                        'disable-click':
                          isAtLeastOneGuestNew ||
                          !accommodationForm.get('send_to_external').value
                      }"
                      (click)="createAddGuestModal(accommodationForm)"
                      ><i class="fad fa-user-plus"></i>
                      <span>{{
                        'guest_configuration' | translate | upperfirst
                      }}</span>
                    </a>
                  </div>
                  <ng-template #noGuesttTooltipTpl>
                    <div class="tooltip">
                      <span *ngIf="isAtLeastOneGuestNew">
                        {{
                          'saves_the_guest_before_message'
                            | translate
                            | upperfirst
                        }}.
                      </span>
                      <span
                        *ngIf="!accommodationForm.get('send_to_external').value"
                      >
                        {{ 'enable_room' | translate | upperfirst }}.
                      </span>
                    </div>
                  </ng-template>
                </ng-template>
              </ng-container>
            </nz-collapse-panel>
          </ng-container>
        </nz-collapse>
      </nz-collapse-panel>
    </ng-container>
  </nz-collapse>
</div>
