import {
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import {
  IstatAccommodation,
  IstatData,
  IstatGuestForm,
  IstatReservation,
} from '../../models';

type ReservationFormArrayName = 'reservations';
type AccommodationsFormArrayName = keyof Pick<
  IstatReservation,
  'accommodations'
>;
type GuestsFormArrayName = keyof Pick<IstatAccommodation, 'guests'>;

type FormArrayControlName =
  | ReservationFormArrayName
  | AccommodationsFormArrayName
  | GuestsFormArrayName;

export const sendableGuest = (form: UntypedFormGroup): ValidationErrors | null => {
  const { send_to_external } = form.value as IstatGuestForm;
  const someFieldInvalid = Object.keys(form.controls).some(
    (field) =>
      form.controls[field].invalid && form.value?.reportedStatus !== 'sync',
  );

  if (form.dirty) {
    return { notSendable: true };
  }

  if (someFieldInvalid && send_to_external) {
    return { notSendable: true };
  }

  return null;
};

export const sendableFormArray = (
  forms: UntypedFormArray,
): ValidationErrors | null => {
  return forms.controls.some((form: UntypedFormGroup) => form.hasError('notSendable'))
    ? { notSendable: true }
    : null;
};

export const sendableForm = (
  formArrayControlName: FormArrayControlName,
): ValidatorFn => {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    const { send_to_external } = form.value as IstatData;
    if (
      !(form.get(formArrayControlName) as UntypedFormArray).length &&
      send_to_external
    ) {
      return { notSendable: true };
    }
    return form.get(formArrayControlName).hasError('notSendable') &&
      send_to_external
      ? { notSendable: true }
      : null;
  };
};
