import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  AccommodationLookup,
  FacilitiesForCategoryObj,
  Facility,
  FacilityCategories,
  FacilityCategoriesObj,
} from '@app/models';
import { CreateAccommodationFacilitiesRequest } from '@app/models/requests/create-accommodation-facilities-request';
import { CreatePropertyFacilitiesRequest } from '@app/models/requests/create-property-facilities-request';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ui/services/notification.service';

import {
  detailsFacilityType,
  ResourceItem,
} from '../facilities-container/facilities-container.component';
import { FacilitiesFormsObj } from '../models/facilities-forms-obj.model';
import { FacilityData } from '../models/facility-data.model';

@Component({
  selector: 'by-facilities-form',
  templateUrl: './facilities-form.component.html',
  styleUrls: ['./facilities-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilitiesFormComponent implements OnInit {
  @Input()
  accommodations: AccommodationLookup;

  @Input()
  propertiesNames: { [property_id: number]: { name: string } };

  @Input()
  facilityCategories: FacilityCategories[] = [];

  @Input()
  facilityCategoriesObj: FacilityCategoriesObj;

  @Input()
  loading: boolean;

  @Input()
  resourceId: number;

  @Input()
  resourceName: number;

  @Input()
  facilityType: detailsFacilityType;

  @Input()
  disableSubmitButton = true;

  @Input()
  set generalFacilities(generalFacilities: FacilitiesForCategoryObj) {
    if (!generalFacilities || !Object.keys(generalFacilities).length) {
      return;
    }
    this._generalFacilities = generalFacilities;
    this.facilitiesFormsObj = Object.keys(this._generalFacilities).reduce(
      (acc, category_id) => {
        const { facilities } = this._generalFacilities[
          parseInt(category_id, 10)
        ];
        acc[parseInt(category_id, 10)] = Object.keys(facilities).reduce(
          (_acc, facility_id) => {
            const { icon, label } = facilities[parseInt(facility_id, 10)];
            _acc[parseInt(facility_id, 10)] = this.formBuilder.group({
              id: facility_id,
              is_free: 1,
              active: 0,
              category_id,
              icon,
              label,
              label_category: this.facilityCategoriesObj[
                parseInt(category_id, 10)
              ].label,
            });
            return _acc;
          },
          {},
        );
        return acc;
      },
      {},
    );
  }

  @Input()
  set resourceFacilities(resourceFacilities: Facility[]) {
    if (
      !this.facilitiesFormsObj ||
      !Object.keys(this.facilitiesFormsObj).length
    ) {
      return;
    }
    if (!resourceFacilities.length) {
      this.resetForm();
    }
    this._resourceFacilities = resourceFacilities;
    this._resourceFacilities.forEach((facility) => {
      const { category_id, id: facility_id, is_free } = facility;
      this.facilitiesFormsObj[category_id][facility_id].patchValue(
        {
          active: 1,
          is_free,
          facility_id,
        },
        { onlySelf: true, emitEvent: false },
      );
    });
  }

  @Input()
  resourcesToClone: ResourceItem[] = [];

  @Output()
  facilityChangedEvent = new EventEmitter<FacilityData>();

  @Output()
  syncAccommodationEvent = new EventEmitter<
    CreateAccommodationFacilitiesRequest
  >();

  @Output()
  syncPropertyEvent = new EventEmitter<CreatePropertyFacilitiesRequest>();

  @Output()
  cloneFacilitiesEvent = new EventEmitter<number>();

  _generalFacilities: FacilitiesForCategoryObj;
  _resourceFacilities: Facility[] = [];
  facilitiesFormsObj: FacilitiesFormsObj;
  resourceSelected: number;
  categoriesToFilter: any[] = [];
  categoriesToFilterObj: {
    [categoryId: number]: boolean;
  };
  isFilterEmpty = true;
  showClone = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private notification: NotificationService,
  ) {}

  ngOnInit() {}

  resetForm() {
    Object.keys(this.facilitiesFormsObj).forEach((categoryId) => {
      Object.keys(this.facilitiesFormsObj[parseInt(categoryId, 10)]).forEach(
        (facilityId) => {
          this.facilitiesFormsObj[parseInt(categoryId, 10)][
            parseInt(facilityId, 10)
          ].patchValue(
            {
              active: 0,
              is_free: 1,
            },
            { onlySelf: true, emitEvent: false },
          );
        },
      );
    });
  }

  onFacilityChanged(facility: FacilityData) {
    this.facilityChangedEvent.emit(facility);
    this.disableSubmitButton = false;
  }

  onSubmit() {
    if (this.facilityType === 'accommodation') {
      const accommodationDataToSend: CreateAccommodationFacilitiesRequest = Object.keys(
        this.facilitiesFormsObj,
      ).reduce(
        (acc, curr) => {
          const facilitiesToSend = Object.keys(
            this.facilitiesFormsObj[parseInt(curr, 10)],
          ).reduce((_acc, _curr) => {
            const { active, is_free } = this.facilitiesFormsObj[
              parseInt(curr, 10)
            ][parseInt(_curr, 10)].value;
            if (active) {
              _acc = [
                ..._acc,
                {
                  facility_id: parseInt(_curr, 10),
                  is_free,
                },
              ];
            }
            return _acc;
          }, []);
          acc = <CreateAccommodationFacilitiesRequest>{
            facilities: [...acc.facilities, ...facilitiesToSend],
          };
          return acc;
        },
        {
          facilities: [],
        },
      );
      this.syncAccommodationEvent.emit(accommodationDataToSend);
    }

    if (this.facilityType === 'property') {
      const propertyDataToSend: CreatePropertyFacilitiesRequest = Object.keys(
        this.facilitiesFormsObj,
      ).reduce(
        (acc, curr) => {
          const facilitiesToSend = Object.keys(
            this.facilitiesFormsObj[parseInt(curr, 10)],
          ).reduce((_acc, _curr) => {
            const { active, is_free } = this.facilitiesFormsObj[
              parseInt(curr, 10)
            ][parseInt(_curr, 10)].value;
            if (active) {
              _acc = [
                ..._acc,
                {
                  facility_id: parseInt(_curr, 10),
                  is_free,
                },
              ];
            }
            return _acc;
          }, []);
          acc = <CreatePropertyFacilitiesRequest>{
            property_id: {
              [this.resourceId.toString()]: [
                ...acc.property_id[this.resourceId.toString()],
                ...facilitiesToSend,
              ],
            },
          };
          return acc;
        },
        {
          property_id: {
            [this.resourceId.toString()]: [],
          },
        },
      );
      this.syncPropertyEvent.emit(propertyDataToSend);
    }
    this.disableSubmitButton = true;
  }

  onCloneFacilities(resourceId: number) {
    if (!resourceId) {
      return;
    }
    this.cloneFacilitiesEvent.emit(resourceId);
    this.notification.push({
      title: this.translateService.instant('done'),
      content: this.translateService.instant(
        'facilities_cloned_success_message',
      ),
      type: 'success',
    });
    this.disableSubmitButton = false;
    this.resetClone();
  }

  resetClone() {
    this.resourceSelected = null;
    this.showClone = false;
  }

  filter(categories: number[] | 'all') {
    if (!categories.length) {
      this.isFilterEmpty = true;
      return;
    }
    if (categories[categories.length - 1] === 'all') {
      this.categoriesToFilter = this.facilityCategories.map(
        (category) => category.id,
      );
      return;
    }

    this.categoriesToFilterObj = (categories as number[]).reduce(
      (acc, categoryId) => {
        acc = {
          ...acc,
          [categoryId]: true,
        };
        return acc;
      },
      {},
    );
    this.isFilterEmpty = false;
  }
}
