import { get } from 'lodash';
import moment from 'moment';

import { IReservationUser } from '../../../models';

export function userFactory(
  booker: any,
  languageName?: string,
  languageIsoCode?: string,
): IReservationUser {
  const {
    id,
    language_id,
    category_id,
    birthday_date,
    name,
    surname,
    sex,
    isFake,
    customer_company_id,
    companyCustomerId,
    privacy_check,
    privacy_date,
    privacy_ip,
    detail,
    is_company,
  } = booker;

  const domicileDetails = get(booker, ['customer', 'details'], []).find(
    ({ customer_address_type_id }) => customer_address_type_id === 1,
  );

  const customerDetails = detail || domicileDetails;

  const userFullAddressPlace = [
    customerDetails?.zip_code,
    customerDetails?.city_name,
    customerDetails?.county_name ? `(${customerDetails?.county_name})` : '',
  ]
    .filter((place) => place)
    .join(' ');

  let userFullAddress = [
    get(customerDetails, 'address', null),
    userFullAddressPlace,
    get(customerDetails, 'country_name', null),
  ];

  userFullAddress = userFullAddress.filter((item) => item);

  return {
    id,
    language: {
      id: language_id,
      iso_code: languageIsoCode,
      name: languageName,
    },
    category: { id: category_id, name: '' },
    birthday: new Date(birthday_date),
    name,
    isFake,
    surname,
    userFullAddress: userFullAddress.length ? userFullAddress : null,
    fullName:
      companyCustomerId === id ? surname : `${name || ''} ${surname || ''}`,
    sex,
    type: 'booker',
    privacy_policy: {
      accepted: !!privacy_check,
      date: moment(privacy_date, 'YYYY-MM-DD').toDate(),
      ip_address: privacy_ip,
    },
    typeGeneral: customer_company_id ? 'company' : booker.type,
    is_company,
    ...customerDetails,
  };
}
