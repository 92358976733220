import { UntypedFormGroup } from '@angular/forms';

export function nzSelectAll(
  event,
  form: UntypedFormGroup,
  allData: any[],
  formControlName: string,
  key: string,
  patchValue?: boolean,
) {
  if (event === null) {
    return;
  }
  form.markAsDirty();
  const all = event.filter((value) => value === 'all');
  if (!all.length) {
    form.controls[formControlName].setValue(event);
    return;
  }
  if (event.length === allData.length + 1) {
    form.controls[formControlName].setValue([]);
  } else {
    const allId = allData.map((element) => element[key]);
    if (patchValue) {
      form.patchValue({ [formControlName]: [...allId] });
      return;
    }
    form.controls[formControlName].setValue(allId);
  }
}
