<ng-container
  *ngIf="
    emailTemplatePreviewContent?.language_iso_code ||
    emailTemplatePreviewDefaultLanguage?.language_iso_code
  "
>
  <ng-container
    *ngIf="emailTemplatePreviewContent?.language_iso_code | safeHtml"
  >
    <form nz-form [nzLayout]="'vertical'" nzLayout="inline">
      <div nz-row>
        <nz-form-item>
          <nz-form-label>
            {{ 'subject' | translate | upperfirst }}:
          </nz-form-label>
          <nz-form-text class="by-mt-10">
            {{ emailTemplatePreviewContent?.subject }}
          </nz-form-text>
        </nz-form-item>
      </div>
      <div nz-row>
        <div [innerHTML]="emailTemplatePreviewContent?.body | safeHtml"></div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="!emailTemplatePreviewContent?.language_iso_code">
    <div nz-row>
      <nz-alert
        class="by-mt-10"
        nzType="warning"
        [nzMessage]="'send_email_warning_message' | translate"
      ></nz-alert>
    </div>
    <form nz-form [nzLayout]="'vertical'" nzLayout="inline">
      <div nz-row>
        <nz-form-item>
          <nz-form-label>
            {{ 'subject' | translate | upperfirst }}:
          </nz-form-label>
          <nz-form-text class="by-mt-10">
            {{ emailTemplatePreviewDefaultLanguage?.subject }}
          </nz-form-text>
        </nz-form-item>
      </div>
      <div nz-row>
        <div class="bdyEmail">
          <div
            [innerHTML]="emailTemplatePreviewDefaultLanguage?.body | safeHtml"
          ></div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>
