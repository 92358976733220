import { IEmailTemplateSystem } from '../../features/commons/email-templates/models/email-template-system.model';

export interface State {
  templates: IEmailTemplateSystem[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  templates: null,
  isLoading: false,
  error: null,
};
