import { Action, createReducer, on } from '@ngrx/store';
import { get, isNil, merge, uniqBy } from 'lodash';

import { handleSplit } from '../../helpers/handle-split';

import * as fromActions from './actions';
import { preselectGuestType } from './functions/preselect-guest-type';
import { reservationFactory } from './functions/reservation-factory';
import * as fromState from './state';

/**
 * @param tabIndex indice della tab da modificare, se valorizzato a null verrano
 * modificate tutte le tabs
 *
 * @param tabs il nodo tabsData dello store
 *
 * @param onFoundTab funzione di callback che si occupa dell'edit della tab
 *
 */
const editTab = (
  tabIndex: number,
  tabs: any[],
  onFoundTab: Function,
): any[] => {
  return tabs.map((tab, index) => {
    if (!isNil(tabIndex) && tabIndex !== index) {
      return { ...tab };
    }

    return onFoundTab(tab);
  });
};

const errorInTab = (state: fromState.State) => {
  const { tabsData } = state;
  return {
    tabsData: tabsData.map((tab) => ({
      ...tab,
      summary: { ...tab.summary, last_error: new Date() },
    })),
  };
};

function setAccommodationsMainGuest(accommodations, reservationBooker) {
  return accommodations?.map((accommodation) => {
    const guests = (accommodation.guests || []).map((guest) => {
      const { customer, guest_type_id, main_guest: isMainGuest } = guest;

      return {
        ...customer,
        type: isMainGuest
          ? 'main_guest'
          : guest_type_id === 1 || guest_type_id === 2 || guest_type_id === 3
            ? 'main_guest_by_guest_type'
            : 'guest',
      };
    });

    // Main guest
    const main_guest =
      guests.find(({ type }) => type === 'main_guest') ||
      guests.find(({ type }) => type === 'main_guest_by_guest_type') ||
      guests[0] ||
      reservationBooker;

    return { ...accommodation, main_guest };
  });
}

export const reducer = createReducer(
  fromState.initialState,

  /**
   * Reservation details reducers
   */
  on(fromActions.loadRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, data) => {
    const {
      reservation,
      propertyName,
      channelName,
      currencySymbol,
      currencyCode,
      languageName,
      languageIsoCode,
    } = data;

    const loadMediaCompleted = false;

    const [newData, allGuests, detailsData, totalDetails] = reservationFactory(
      reservation,
      loadMediaCompleted,
      propertyName,
      channelName,
      currencySymbol,
      currencyCode,
      languageName,
      languageIsoCode,
    );
    const { company, total_details, details } = newData;

    const customer_company_id =
      reservation.booker.id === get(company, 'customer_id')
        ? reservation.booker.id
        : null;
    const { type: typeBooker, name } = reservation.booker;

    let bookerData = {
      ...reservation.booker,
      customer_company_id,
      type: customer_company_id ? 'company' : typeBooker,
      company,
      isGuest: false,
      name: customer_company_id ? '' : name,
      guest_type_id: null,
    };

    const bookerId = bookerData.id;
    if (allGuests.length) {
      const indexGuestIsBooker = allGuests.findIndex(
        ({ id }) => id === bookerData.id,
      );
      if (indexGuestIsBooker >= 0) {
        bookerData = {
          ...bookerData,
          isGuest: true,
          guest_type_id: allGuests[indexGuestIsBooker].guest_type_id,
        };
      }
    }
    const allReservationGuests = reservation.accommodations.reduce(
      (acc, { guests }) => [...acc, ...guests],
      [],
    );
    const guestTypePreselected = preselectGuestType(
      allReservationGuests,
      reservation.arrival_date,
    );
    const reservationDetails = { ...state.reservationDetails, ...newData };

    const reservationAccommodations = setAccommodationsMainGuest(
      handleSplit(
        reservation.accommodations.map(
          ({ rooms, ...reservationAccommodation }) => {
            return {
              ...reservationAccommodation,
              rooms: rooms.map(({ days, ...room }) => {
                return {
                  ...room,
                  days: days.map((day) => {
                    return {
                      ...day,
                      rateplan_id:
                        day?.accommodation_rateplan?.my_property_rateplan
                          ?.my_rateplan?.id,
                    };
                  }),
                };
              }),
            };
          },
        ),
      ),
      bookerData,
    );

    return {
      ...state,
      reservationDetailsLoading: false,
      reservationDetails,
      reservationTotals: {
        ...totalDetails,
        payment_status: reservationDetails?.payment?.status,
      },
      reservationAccommodations,
      tabsData: detailsData,
      bookerId,
      allGuest: uniqBy([bookerData, ...allGuests], 'id'),
      keep_availability: newData.keep_availability,
      guestTypePreselected,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Reservation totals reducers
   */
  on(fromActions.loadTotalsRequest, (state) => ({
    ...state,
    reservationTotalsIsLoading: true,
    error: null,
  })),
  on(fromActions.loadTotalsSuccess, (state, { reservationTotals }) => {
    const { payment_status } = reservationTotals;
    return {
      ...state,
      reservationDetails: {
        ...state.reservationDetails,
        payment: {
          ...state?.reservationDetails?.payment,
          status: payment_status,
        },
      },
      reservationTotals,
      reservationTotalsIsLoading: false,
    };
  }),
  on(fromActions.loadTotalsFailure, (state, { error }) => ({
    ...state,
    reservationTotalsIsLoading: false,
    error,
  })),

  /**
   * Reservation keep availability
   */
  on(fromActions.setKeepAvailabilityRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromActions.setKeepAvailabilitySuccess,
    (state, { keep_availability }) => ({
      ...state,
      reservationDetails: {
        ...state.reservationDetails,
        keep_availability,
      },
      loading: false,
    }),
  ),
  on(fromActions.setKeepAvailabilityFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * Reservation load media accommodations
   */

  on(fromActions.loadMediaSuccess, (state, { medias }) => ({
    ...state,
    accommodationsMedias: medias,
    loadMediaCompleted: true,
  })),
  /**
   * Reservation payment change
   */
  on(fromActions.updatePaymentMethodRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromActions.updatePaymentMethodSuccess,
    (state, { changeOnlyPayment, payment_method_id }) => ({
      ...state,
      reservationDetails: {
        ...state.reservationDetails,
        credit_card_data_count: changeOnlyPayment
          ? changeOnlyPayment
          : state.reservationDetails.credit_card_data_count,
        payment_method_id: payment_method_id,
      },
      creditCard: null,
      loading: false,
    }),
  ),
  on(fromActions.updatePaymentMethodFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  /**
   * Update reservations
   */
  on(fromActions.updateReservationDetailsRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromActions.updateReservationDetailsSuccess,
    (state, { reservationData }) => ({
      ...state,
      reservationDetails: {
        ...state.reservationDetails,
        ...reservationData,
      },
      loading: false,
    }),
  ),
  on(fromActions.updateReservationDetailsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  /**
   * Update checkin/out
   */
  on(fromActions.updateCheckInOutRequest, (state) => ({
    ...state,
    loading: true,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.updateCheckInOutSuccess,
    (state, { tabIndex, checkinStatus }) => {
      const { tabsData } = state;
      return {
        ...state,
        loading: false,
        reservationDetailsLoading: false,
        tabsData: editTab(tabIndex, tabsData, (tab) => {
          const {
            checkin_hour: checkinHour,
            checkout_hour: checkoutHour,
            ...data
          } = checkinStatus;

          let newSummaryTab = {
            ...tab.summary,
            ...data,
            checkinHour,
            checkoutHour,
          };

          return {
            ...tab,
            summary: newSummaryTab,
          };
        }),
      };
    },
  ),
  on(fromActions.updateCheckInOutFailure, (state, { error }) => {
    return {
      ...state,
      ...errorInTab(state),
      error,
      loading: false,
      reservationDetailsLoading: false,
    };
  }),

  /**
   * Update status Reservation
   */
  on(fromActions.updateStatusRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.updateStatusSuccess,
    (state, { status, expiration_date, availability_option }) => {
      const { tabsData } = state;
      return {
        ...state,
        reservationDetailsLoading: false,
        reservationDetails: {
          ...state.reservationDetails,
          cancellation_date: status === 'Cancelled' ? new Date() : null,
        },
        tabsData: editTab(null, tabsData, (tab) => {
          return {
            ...tab,
            summary: {
              ...tab.summary,
              status,
              expiration_date,
              availability_option: isNil(availability_option)
                ? tab.summary.availability_option
                : availability_option,
            },
          };
        }),
      };
    },
  ),
  on(fromActions.updateStatusFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Update customer data Reservation
   */
  on(fromActions.updateCustomerRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(fromActions.updateCustomerSuccess, (state, { tabIndex, customerData }) => {
    const { tabsData } = state;
    return {
      ...state,
      tabsData: editTab(tabIndex, tabsData, (tab) => {
        return {
          ...tab,
          user: {
            ...tab.user,
            ...customerData,
          },
        };
      }),
    };
  }),
  on(fromActions.updateCustomerFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  /**
   * Update keep Accommodation
   */
  on(fromActions.setKeepAccommodationRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.setKeepAccommodationSuccess,
    (state, { keep_accommodation, roomreservation_id }) => {
      const { tabsData, reservationAccommodations } = state;

      const tabIndex = tabsData.findIndex(
        (tab) => tab.summary.roomreservation_id_original === roomreservation_id,
      );

      return {
        ...state,
        reservationDetailsLoading: false,
        reservationAccommodations: reservationAccommodations.map(
          (accommodation) => ({
            ...accommodation,
            keep_accommodation:
              accommodation.roomreservation_id === roomreservation_id
                ? keep_accommodation
                : accommodation.keep_accommodation,
          }),
        ),
        tabsData: editTab(tabIndex, tabsData, (tab) => {
          return {
            ...tab,
            summary: {
              ...tab.summary,
              keep_accommodation,
            },
          };
        }),
      };
    },
  ),
  on(fromActions.setKeepAccommodationFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  on(
    fromActions.setCityTaxReportExclusionSuccess,
    (state, { city_tax_report_exclusion, roomreservation_id }) => {
      const { tabsData, reservationAccommodations } = state;

      const tabIndex = tabsData.findIndex(
        (tab) => tab.summary.roomreservation_id_original === roomreservation_id,
      );

      return {
        ...state,
        reservationAccommodations: reservationAccommodations.map(
          (accommodation) => ({
            ...accommodation,
            city_tax_report_exclusion:
              accommodation.roomreservation_id === roomreservation_id
                ? city_tax_report_exclusion
                : accommodation.city_tax_report_exclusion,
          }),
        ),
        tabsData: editTab(tabIndex, tabsData, (tab) => {
          return {
            ...tab,
            summary: {
              ...tab.summary,
              city_tax_report_exclusion,
            },
          };
        }),
      };
    },
  ),

  /**
   * Update Accommodation more
   */
  on(fromActions.updateReservationAccommodationMoreRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.updateReservationAccommodationMoreSuccess,
    (state, { request }) => {
      const { key, value, roomreservation_id } = request;

      const { tabsData, reservationAccommodations } = state;

      const tabIndex = tabsData.findIndex(
        (tab) => tab.summary.roomreservation_id_original === roomreservation_id,
      );

      return {
        ...state,
        reservationDetailsLoading: false,
        reservationAccommodations: reservationAccommodations.map(
          (accommodation) => ({
            ...accommodation,
            more_fields: {
              ...accommodation.more,
              [key]:
                accommodation.roomreservation_id === roomreservation_id
                  ? value
                  : accommodation?.more_fields?.[key],
            },
          }),
        ),
        tabsData: editTab(tabIndex, tabsData, (tab) => {
          return {
            ...tab,
            summary: {
              ...tab.summary,
              more_fields: {
                ...tab.more_fields,
                [key]: value,
              },
            },
          };
        }),
      };
    },
  ),
  on(
    fromActions.updateReservationAccommodationMoreFailure,
    (state, { error }) => ({
      ...state,
      reservationDetailsLoading: false,
      error,
    }),
  ),

  /**
   * Load Accommodation more
   */
  on(fromActions.loadReservationAccommodationMoreRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.loadReservationAccommodationMoreSuccess,
    (state, { moreFields }) => {
      const { tabsData, reservationAccommodations } = state;

      let newState = { ...state };

      Object.entries(moreFields || {}).forEach(
        ([roomReservationID, more_fields]) => {
          const tabIndex = tabsData.findIndex(
            (tab) =>
              tab.summary.roomreservation_id_original === roomReservationID,
          );

          newState = {
            ...state,
            reservationDetailsLoading: false,
            reservationAccommodations: reservationAccommodations.map(
              (accommodation) => ({
                ...accommodation,
                more_fields: {
                  ...accommodation.more,
                  ...(accommodation.roomreservation_id === roomReservationID
                    ? more_fields
                    : accommodation?.more_fields),
                },
              }),
            ),
            tabsData: editTab(tabIndex, tabsData, (tab) => {
              return {
                ...tab,
                summary: {
                  ...tab.summary,
                  more_fields,
                },
              };
            }),
          };
        },
      );

      return newState;
    },
  ),
  on(fromActions.loadReservationAccommodationMoreFailure, (state) => ({
    ...state,
    reservationDetailsLoading: false,
    error: null,
  })),

  /**
   * Update keep Accommodation All Accommodations
   */
  on(fromActions.setEntireReservationsKeepAccommodationRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(
    fromActions.setEntireReservationsKeepAccommodationSuccess,
    (state, { keep_accommodation }) => {
      const { tabsData, reservationAccommodations } = state;

      return {
        ...state,
        reservationDetailsLoading: false,
        reservationAccommodations: reservationAccommodations.map(
          (accommodation) => ({
            ...accommodation,
            keep_accommodation,
          }),
        ),
        tabsData: tabsData.map((tab) => {
          return {
            ...tab,
            summary: {
              ...tab.summary,
              keep_accommodation,
            },
          };
        }),
      };
    },
  ),
  on(fromActions.setKeepAccommodationFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Update note
   */
  on(fromActions.updateNoteRequest, (state) => ({
    ...state,
    error: null,
  })),
  on(
    fromActions.updateNoteSuccess,
    (state, { tabIndex, noteData, typeNote }) => {
      const { tabsData } = state;

      return {
        ...state,
        tabsData: editTab(tabIndex, tabsData, (tab) => ({
          ...tab,
          summary: {
            ...tab.summary,
            notes: {
              ...tab.summary.notes,
              [typeNote]: noteData,
            },
          },
        })),
      };
    },
  ),
  on(fromActions.updateNoteFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  /**
   * Load Logs
   */
  on(fromActions.loadLogsRequest, (state) => ({
    ...state,
    reservationLogsLoading: true,
    error: null,
  })),
  on(fromActions.loadLogsSuccess, (state, { logsData }) => ({
    ...state,
    logs: logsData,
    reservationLogsLoading: false,
  })),
  on(fromActions.loadLogsFailure, (state, { error }) => ({
    ...state,
    reservationLogsLoading: false,
    error,
  })),

  /**
   * Load Warnings
   */
  on(fromActions.loadWarningsSuccess, (state, { warnings }) => ({
    ...state,
    warnings,
  })),

  /**
   * Split
   */
  on(fromActions.splitReservationRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(fromActions.splitReservationSuccess, (state) => ({
    ...state,
    reservationDetailsLoading: false,
  })),
  on(fromActions.splitReservationFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Update Check-in Check-out
   */
  on(fromActions.updateCheckinCheckoutDateRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(fromActions.updateCheckinCheckoutDateSuccess, (state) => ({
    ...state,
    reservationDetailsLoading: false,
  })),
  on(fromActions.updateCheckinCheckoutDateFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Change price request
   */
  on(fromActions.changePriceRequest, (state) => ({
    ...state,
    reservationDetailsLoading: true,
    error: null,
  })),
  on(fromActions.changePriceSuccess, (state) => ({
    ...state,
    reservationDetailsLoading: false,
  })),
  on(fromActions.changePriceFailure, (state, { error }) => ({
    ...state,
    reservationDetailsLoading: false,
    error,
  })),

  /**
   * Load cc details
   */
  on(fromActions.loadCreditCardSuccess, (state, { data }) => {
    let { expire_date } = data;

    // Pezza per le API che in alcuni casi tornano expire_date nel formato MMYY anziché MM-YY / MM/YY
    if (!/-/g.test(expire_date) && !/\//g.test(expire_date) && expire_date) {
      const month = expire_date.substr(0, 2);
      const year = expire_date.substr(2, 2);
      expire_date = `${month}-${year}`;
    }

    return { ...state, creditCard: { ...data, expire_date } };
  }),

  on(fromActions.createScaConfirmationRequestRequest, (state, { request }) => {
    return merge({}, state, {
      creditCard: {
        ...state.creditCard,
        customer: {
          ...state.creditCard.customer,
          ...request.customer,
        },
      },
    });
  }),

  on(
    fromActions.createScaConfirmationRequestSuccess,
    (state, { scaConfirmationRequest }) => {
      return merge({}, state, {
        creditCard: {
          ...state.creditCard,
          sca_requests: [
            scaConfirmationRequest,
            ...state.creditCard.sca_requests,
          ],
        },
      });
    },
  ),

  on(fromActions.resetCreditCard, (state) => ({
    ...state,
    creditCard: null,
  })),

  /**
   * Update checkin/out date failure
   */
  on(fromActions.updateCheckinCheckoutDateFailure, (state, { error }) => ({
    ...state,
    ...errorInTab(state),
    error,
  })),

  /**
   * Set Housekeeper Users
   */
  on(
    fromActions.setHousekeeperUsersSuccess,
    (state, { request, tabIndex }) => ({
      ...state,
      tabsData: editTab(tabIndex, state.tabsData, (tab) => {
        return {
          ...tab,
          summary: {
            ...tab.summary,
            housekeeper_users_id: request.housekeepers,
          },
        };
      }),
    }),
  ),

  /**
   * Set Housekeeper Users
   */
  on(
    fromActions.setHousekeeperRecordSuccess,
    (state, { request, tabIndex }) => ({
      ...state,
      tabsData: editTab(tabIndex, state.tabsData, (tab) => {
        return {
          ...tab,
          summary: {
            ...tab.summary,
            clean_status: get(
              request.record,
              'clean',
              tab.summary.clean_status,
            ),
          },
        };
      }),
    }),
  ),

  /**
   * Update tab
   */
  on(fromActions.updateTab, (state, { tabIndex, data }) => {
    const { tabsData } = state;

    return {
      ...state,
      tabsData: editTab(tabIndex, tabsData, (tab) => {
        return merge({}, tab, data);
      }),
    };
  }),

  /**
   * Set generated bills
   */
  on(fromActions.setGeneratedBills, (state, { generated_bills }) => {
    return {
      ...state,
      reservationDetails: {
        ...state.reservationDetails,
        generated_bills,
      },
    };
  }),

  /**
   * Reset state reducer
   */
  on(fromActions.resetState, (state, { keys }) => {
    const { initialState } = fromState;

    if (!keys || !keys.length) {
      return { ...initialState };
    }

    const resettedState = keys.reduce(
      (resState, key) => (resState = { ...resState, [key]: initialState[key] }),
      {},
    );

    return {
      ...state,
      ...resettedState,
    };
  }),
  on(fromActions.setExpandedGuest, (state, expandedGuests) => {
    return {
      ...state,
      guestsExpandMap: {
        ...state.guestsExpandMap,
        ...expandedGuests,
      },
    };
  }),
  on(fromActions.setGuestToScrollGuest, (state, { guestId }) => {
    return {
      ...state,
      guestToScroll: guestId,
    };
  }),
  on(fromActions.addReservationAccommodationRequest, (state) => ({
    ...state,
    reservationDetailsAddRoomLoading: true,
    error: null,
  })),
  on(fromActions.addReservationAccommodationSuccess, (state) => ({
    ...state,
    reservationDetailsAddRoomLoading: false,
  })),
  on(fromActions.addReservationAccommodationFailure, (state, { error }) => ({
    ...state,
    reservationDetailsAddRoomLoading: false,
    error,
  })),
);

export function reservationDetailsCustomReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
