<div nz-row nzJustify="center">
  <div
    nz-col
    class="box-image"
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="24"
    nzXl="24"
  >
    <div class="image-container">
      <nz-empty
        *ngIf="!media?.media"
        [nzNotFoundContent]="'image_not_found' | translate | upperfirst"
      >
      </nz-empty>
      <img
        [src]="media?.formats_size?.medium"
        *ngIf="media?.formats_size?.medium"
      />
    </div>
  </div>
</div>
<div nz-row *ngIf="facilitiesResources" class="by-mt-4">
  <div
    *ngFor="let categoryId of facilitiesResources | keys"
    class="by-mb-2 full-width"
  >
    <by-card
      [shadow]="false"
      header="small"
      animation="animation"
      [showStart]="true"
      layout="reservation"
      [title]="facilityCategoriesObj[categoryId]?.label | upperfirst"
    >
      <ng-template #content>
        <by-facilities-summary-category
          [facilities]="facilitiesResources[categoryId]?.facilities"
        ></by-facilities-summary-category>
      </ng-template>
    </by-card>
  </div>
  <by-legend-colors
    [colorsDefault]="defaultFacilityLegendColor"
    [disableChange]="true"
  >
  </by-legend-colors>
</div>
