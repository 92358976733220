import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { Action } from '@ngrx/store';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import {
  CreateCustomer,
  Customer,
  CustomerApiData,
  CustomerListItem,
  IAddressTypeRoot,
  ICustomerTypeRoot,
  IDocumentTypeRoot,
} from '../../models';
import { SearchParams } from '../../models/objects/search-params';
import { AttchGuestToReservationRequest } from '../../models/requests/attach-guest-to-reservation-data.request';
import { ExportFileRequestType } from '../../models/types/export-file-request';

export enum ActionTypes {
  LOAD_REQUEST = '[Customers] Load Request',
  LOAD_FAILURE = '[Customers] Load Failure',
  LOAD_SUCCESS = '[Customers] Load Success',

  LOAD_DETAILS_REQUEST = '[Customers] Load Details Request',
  LOAD_DETAILS_FAILURE = '[Customers] Load Details Failure',
  LOAD_DETAILS_SUCCESS = '[Customers] Load Details Success',

  CREATE_REQUEST = '[Customers] Create Request',
  CREATE_FAILURE = '[Customers] Create Failure',
  CREATE_SUCCESS = '[Customers] Create Success',

  LOAD_DOCUMENT_TYPE_REQUEST = '[Customers] Load Document Types Data Request',
  LOAD_DOCUMENT_TYPE_FAILURE = '[Customers] Load Document Types Data Failure',
  LOAD_DOCUMENT_TYPE_SUCCESS = '[Customers] Load Document Types Data Success',

  LOAD_ADDRESS_TYPE_REQUEST = '[Customers] Load Address Types Data Request',
  LOAD_ADDRESS_TYPE_FAILURE = '[Customers] Load Address Types Data Failure',
  LOAD_ADDRESS_TYPE_SUCCESS = '[Customers] Load Address Types Data Success',

  LOAD_CUSTOMER_TYPES_REQUEST = '[Customers] Load Customers Types Data Request',
  LOAD_CUSTOMER_TYPES_FAILURE = '[Customers] Load Customers Types Data Failure',
  LOAD_CUSTOMER_TYPES_SUCCESS = '[Customers] Load Customers Types Data Success',

  LOAD_CUSTOMER_CATEGORIES_REQUEST = '[Customers] Load Customers Categories Request',
  LOAD_CUSTOMER_CATEGORIES_FAILURE = '[Customers] Load Customers Categories Failure',
  LOAD_CUSTOMER_CATEGORIES_SUCCESS = '[Customers] Load Customers Categories Success',

  SEARCH_REQUEST = '[Customers] Search Request',
  SEARCH_FAILURE = '[Customers] Search Failure',
  SEARCH_SUCCESS = '[Customers] Search Success',

  DELETE_REQUEST = '[Customers] Delete Request',
  DELETE_FAILURE = '[Customers] Delete Failure',
  DELETE_SUCCESS = '[Customers] Delete Success',

  UPDATE_REQUEST = '[Customers] UPDATE Request',
  UPDATE_FAILURE = '[Customers] UPDATE Failure',
  UPDATE_SUCCESS = '[Customers] UPDATE Success',

  UPDATE_GUEST_TYPE_REQUEST = '[Customers] UPDATE Guest Type Request',
  UPDATE_GUEST_TYPE_FAILURE = '[Customers] UPDATE Guest Type Failure',
  UPDATE_GUEST_TYPE_SUCCESS = '[Customers] UPDATE Guest Type Success',

  UPDATE_PRIVACY_REQUEST = '[Customers] UPDATE Privacy Request',
  UPDATE_PRIVACY_FAILURE = '[Customers] UPDATE Privacy Failure',
  UPDATE_PRIVACY_SUCCESS = '[Customers] UPDATE Privacy Success',

  ORDER_REQUEST = '[Customers] Order Request',
  ORDER_SUCCESS = '[Customers] Order Success',
  ORDER_FAILURE = '[Customers] Order Failure',

  UPLOAD_REQUEST = '[Customers] Upload Request',
  UPLOAD_SUCCESS = '[Customers] Upload Success',
  UPLOAD_FAILURE = '[Customers] Upload Failure',

  RESET_UPLOAD_ERRORS = '[Customers] Reset Upload State',

  RESET_STATE = '[Customers] Reset State',

  EXPORT_FILE_REQUEST = '[Customers] Export File',
  EXPORT_FILE_SUCCESS = '[Customers] Export File Success',
  EXPORT_FILE_ERROR = '[Customers] Export File Error',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      properties: number[];
      page?: number;
      order?: string;
      searchParams?: {
        name?: string;
        surname?: string;
      };
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: { items: CustomerListItem[]; pagination: IPagination },
  ) {}
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(public payload: { properties?: number[]; customerId: number }) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { customerDetails: CustomerApiData }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { customerId: number }) {}
}
export class SearchRequestAction implements Action {
  readonly type = ActionTypes.SEARCH_REQUEST;
  constructor(public payload: SearchParams) {}
}
export class SearchFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UploadSuccessAction implements Action {
  readonly type = ActionTypes.UPLOAD_SUCCESS;
}

export class UploadRequestAction implements Action {
  readonly type = ActionTypes.UPLOAD_REQUEST;
  constructor(
    public payload: {
      properties: number[];
      file: File;
      selectedDateFormat: string;
      onSuccess: () => void;
      onError: () => void;
    },
  ) {}
}
export class UploadFailureAction implements Action {
  readonly type = ActionTypes.UPLOAD_FAILURE;
  constructor(
    public payload: {
      error: string;
      uploadErrors: { [row: string]: string[] };
    },
  ) {}
}

export class SearchSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { items: Customer[] }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { customerId: number }) {}
}

export class LoadDocumentTypesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DOCUMENT_TYPE_REQUEST;
}

export class LoadDocumentTypesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DOCUMENT_TYPE_SUCCESS;
  constructor(public payload: { customerDocumentTypes: IDocumentTypeRoot[] }) {}
}

export class LoadDocumentTypesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DOCUMENT_TYPE_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class LoadAddressTypesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ADDRESS_TYPE_REQUEST;
}

export class LoadAddressTypesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ADDRESS_TYPE_SUCCESS;
  constructor(public payload: { customerAddressTypes: IAddressTypeRoot[] }) {}
}

export class LoadAddressTypesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ADDRESS_TYPE_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class LoadCustomerCategoriesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_CATEGORIES_REQUEST;
}

export class LoadCustomerCategoriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_CATEGORIES_SUCCESS;
  constructor(
    public payload: { customerCategories: { id: number; name: string }[] },
  ) {}
}

export class LoadCustomerCategoriesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_CATEGORIES_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class LoadCustomerTypesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_TYPES_REQUEST;
}

export class LoadCustomerTypesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_TYPES_SUCCESS;
  constructor(public payload: { customerTypes: ICustomerTypeRoot[] }) {}
}

export class LoadCustomerTypesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CUSTOMER_TYPES_FAILURE;
  constructor(public payload: { error: Error }) {}
}

/**
 * @description l'effetto legge direttamente il campo "id" all'interno della request,
 * è possibile sovrascrivere questa lettura passando customer_id. Seby Maesano.
 */
export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      request: Partial<CreateCustomer>;
      customer_id?: number;
      attachToReservation?: AttchGuestToReservationRequest;
      onSuccess?: () => void;
      onFailure?: () => void;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateGuestTypeSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_GUEST_TYPE_SUCCESS;
}

export class UpdateGuestTypeRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_GUEST_TYPE_REQUEST;
  constructor(
    public payload: {
      guest_type_id: number;
      res_acc_room_guest_id: number;
      city_tax_exemption_id?: number;
    },
  ) {}
}

export class UpdateGuestTypeFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_GUEST_TYPE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdatePrivacySuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_PRIVACY_SUCCESS;
}

export class UpdatePrivacyRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_PRIVACY_REQUEST;
  constructor(
    public payload: {
      customer_id: number;
      privacy_check: boolean;
      promotion_check: boolean;
    },
  ) {}
}

export class UpdatePrivacyFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_PRIVACY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { customer: Customer }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class ExportFileRequest implements Action {
  readonly type = ActionTypes.EXPORT_FILE_REQUEST;
  constructor(public payload: ExportFileRequestType) {}
}
export class ExportFileSucces implements Action {
  readonly type = ActionTypes.EXPORT_FILE_SUCCESS;
  constructor(public payload: { exportIdFile: number }) {}
}
export class ExportFileError implements Action {
  readonly type = ActionTypes.EXPORT_FILE_ERROR;
}

export class CreateCustomerFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class CreateCustomerRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      customer: Partial<CreateCustomer>;
      property_id?: number[];
      attachToReservation?: AttchGuestToReservationRequest;
      onComplete?: (customer: Customer) => any;
    },
  ) {}
}

export class CreateCustomerSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { newCustomer: Customer }) {}
}

export class ResetUploadErrorsAction implements Action {
  readonly type = ActionTypes.RESET_UPLOAD_ERRORS;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadDetailsRequestAction
  | LoadDetailsFailureAction
  | LoadDetailsSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | UpdateGuestTypeRequestAction
  | UpdateGuestTypeFailureAction
  | UpdateGuestTypeSuccessAction
  | SearchRequestAction
  | SearchFailureAction
  | SearchSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | LoadDocumentTypesFailureAction
  | LoadDocumentTypesSuccessAction
  | LoadDocumentTypesRequestAction
  | LoadCustomerTypesFailureAction
  | LoadCustomerTypesSuccessAction
  | LoadCustomerTypesRequestAction
  | LoadAddressTypesFailureAction
  | LoadAddressTypesSuccessAction
  | LoadAddressTypesRequestAction
  | ResetSuccessAction
  | CreateCustomerFailureAction
  | CreateCustomerRequestAction
  | CreateCustomerSuccessAction
  | LoadCustomerCategoriesRequestAction
  | LoadCustomerCategoriesSuccessAction
  | LoadCustomerCategoriesFailureAction
  | UpdatePrivacyRequestAction
  | UpdatePrivacySuccessAction
  | UpdatePrivacyFailureAction
  | UploadRequestAction
  | UploadSuccessAction
  | UploadFailureAction
  | ResetUploadErrorsAction
  | ExportFileSucces;
