<div class="facilities-container">
  <ng-container
    *ngIf="facilitiesFormsObj && (facilitiesFormsObj | keys).length"
  >
    <ng-container
      *ngIf="facilityType === 'property' ? resourcesToClone.length : true"
    >
      <div nz-row nzJustify="start" class="by-mt-4">
        <div class="by-mr-2 by-mb-2 text-bold">
          {{
            'clone_message'
              | translate
                : {
                    param: facilityType | translate,
                    param1: 'facilities' | translate
                  }
              | upperfirst
          }}
        </div>
        <nz-radio-group class="option-container" [(ngModel)]="showClone">
          <label nz-radio [nzValue]="true">
            {{ 'yes' | translate | capitalize }}
          </label>
          <label nz-radio [nzValue]="false">
            {{ 'no' | translate | capitalize }}
          </label>
        </nz-radio-group>
      </div>
    </ng-container>
    <div
      nz-row
      *ngIf="showClone"
      class="by-mb-2"
      nzJustify="start"
      class="by-mt-4"
      style="display: flex; align-items: center"
    >
      <ng-container *ngIf="facilityType === 'accommodation'">
        <div class="by-mr-2 by-mb-2 text-bold">
          {{
            'clone_message_explained'
              | translate
                : {
                    param: facilityType | translate,
                    param1: 'facilities' | translate
                  }
              | upperfirst
          }}
        </div>
        <nz-select
          class="by-mb-2"
          nzAllowClear
          style="width: 80%"
          nzPlaceHolder="{{
            'placeholder.select'
              | translate
                : {
                    param: 'accommodation' | translate
                  }
          }}"
          [(ngModel)]="resourceSelected"
        >
          <ng-container *ngFor="let propertyId of accommodations | keys">
            <ng-container
              *ngIf="accommodations[propertyId].length > 1; else onlyoption"
            >
              <nz-option-group [nzLabel]="nameProperty">
                <ng-template #nameProperty>
                  <i class="by-mr-2 fal fa-home-lg-alt"></i>
                  {{
                    propertiesNames[propertyId] &&
                      propertiesNames[propertyId]['name']
                  }}
                </ng-template>
                <nz-option
                  *ngFor="let accommodation of accommodations[propertyId]"
                  [nzLabel]="accommodation.name"
                  [nzValue]="accommodation.id"
                ></nz-option>
              </nz-option-group>
            </ng-container>
            <ng-template #onlyoption>
              <nz-option
                *ngFor="let accommodation of accommodations[propertyId]"
                [nzLabel]="accommodation.name"
                [nzValue]="accommodation.id"
              ></nz-option>
            </ng-template>
          </ng-container>
        </nz-select>
      </ng-container>
      <ng-container *ngIf="facilityType === 'property'">
        <div class="by-mr-2 text-bold">
          {{
            'clone_message_explained'
              | translate
                : {
                    param: facilityType | translate,
                    param1: 'facilities' | translate
                  }
              | upperfirst
          }}
        </div>
        <nz-select
          class="by-mb-2"
          style="width: 250px"
          [(ngModel)]="resourceSelected"
          nzPlaceHolder="{{
            'placeholder.select'
              | translate
                : {
                    param: facilityType | translate
                  }
          }}"
        >
          <nz-option
            *ngFor="let resource of resourcesToClone"
            [nzValue]="resource.id"
            [nzLabel]="resource.name"
          ></nz-option>
        </nz-select>
      </ng-container>
      <button
        class="by-mr-2 by-ml-2"
        nzSize="small"
        nz-button
        nzType="primary"
        (click)="onCloneFacilities(resourceSelected)"
      >
        {{ 'save' | translate | capitalize }}
      </button>
      <button
        nzSize="small"
        nz-button
        nzType="primary"
        nzDanger
        (click)="resetClone()"
      >
        {{ 'cancel' | translate | capitalize }}
      </button>
    </div>
    <nz-divider></nz-divider>
    <div nz-row class="filter-input-container">
      <div class="text-bold">
        {{ 'filter_for_categories' | translate | upperfirst }}:
      </div>
      <nz-select
        nzAllowClear
        style="width: 100%"
        [ngModel]="categoriesToFilter"
        nzMode="tags"
        nzPlaceHolder="{{
          'placeholder.select'
            | translate
              : {
                  param: 'categories' | translate
                }
        }}"
        (ngModelChange)="filter($event)"
      >
        <nz-option nzCustomContent nzValue="all">
          <span class="filterSelectAllSpan">
            {{ 'select_all' | translate }}
          </span>
        </nz-option>
        <nz-option
          *ngFor="let category of facilityCategories"
          [nzLabel]="category.label"
          [nzValue]="category.id"
        ></nz-option>
      </nz-select>
    </div>
    <nz-divider></nz-divider>
    <ng-container *ngFor="let catefgoryId of _generalFacilities | keys">
      <div
        class="by-mt-4"
        nz-row
        [hidden]="!(isFilterEmpty ? true : categoriesToFilterObj[catefgoryId])"
      >
        <by-section-box
          [id]="'categoryId' + catefgoryId"
          [title]="_generalFacilities[catefgoryId].label"
          class="by-w-100"
        >
          <div nz-row nzJustify="start" nzGutter="16">
            <ng-container
              *ngFor="
                let facilityId of _generalFacilities[catefgoryId].facilities
                  | keys
              "
            >
              <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
                <div class="by-mt-2">
                  <by-facility
                    [id]="'facilityId' + facilityId"
                    (facilityChangedEvent)="onFacilityChanged($event)"
                    [form]="facilitiesFormsObj[catefgoryId][facilityId]"
                  ></by-facility>
                </div>
              </div>
            </ng-container>
          </div>
        </by-section-box>
      </div>
    </ng-container>
    <by-fixed-bottom-bar [width100]="true">
      <div></div>
      <div>
        <button
          nz-button
          nzType="primary"
          [disabled]="disableSubmitButton"
          class="by-mr-2"
          (click)="onSubmit()"
        >
          <span>{{ 'save' | translate }}</span>
        </button>
      </div>
    </by-fixed-bottom-bar>
  </ng-container>
</div>
