import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {} from '@angular/router';
import {
  selectAllProperties,
  selectCurrentPropertyId,
} from '@app/core/+state/core.reducer';
import { IProperty } from '@app/features/commons/properties/models/property.model';
import {
  AccommodationLookup,
  FacilitiesForCategoryObj,
  Facility,
  FacilityCategories,
  FacilityCategoriesObj,
} from '@app/models';
import { CreateAccommodationFacilitiesRequest } from '@app/models/requests/create-accommodation-facilities-request';
import { CreatePropertyFacilitiesRequest } from '@app/models/requests/create-property-facilities-request';
import {
  AccommodationsStoreActions,
  AccommodationsStoreSelectors,
} from '@app/root-store/accommodations-store';
import { FacilitiesStoreActions } from '@app/root-store/facilities-store';
import {
  selectFacilitiesForCategoryObj,
  selectFacilitiesFromResource,
  selectFacilitiesLoading,
  selectFacilityCategories,
  selectFacilityCategoriesObj,
} from '@app/root-store/facilities-store/selectors';
import { RootState } from '@app/root-store/root-state';
import { selectUserMePermissionsFeatureProperties } from '@app/root-store/user-me-permissions-store/selectors';
import { Store } from '@ngrx/store';
import { intersectionWith, isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { combineLatest, distinctUntilChanged, filter } from 'rxjs/operators';

import { FacilitiesFormComponent } from '../facilities-form/facilities-form.component';
import { FacilityData } from '../models/facility-data.model';

export type detailsFacilityType = 'accommodation' | 'property';

export interface ResourceItem {
  id: number;
  name: string;
}

@Component({
  selector: 'by-facilities-container',
  templateUrl: './facilities-container.component.html',
  styleUrls: ['./facilities-container.component.scss'],
})
export class FacilitiesContainerComponent implements OnInit, OnDestroy {
  @Input()
  resourceName: number;

  @Input()
  resourceId: number;

  @Input()
  facilityType: detailsFacilityType;

  @Input()
  set data(data: { resourceId: number; facilityType: detailsFacilityType }) {
    if (!data || !data.resourceId || !data.facilityType) {
      return;
    }
    this.resourceId = data.resourceId;
    this.facilityType = data.facilityType;
    this.loadData();
  }

  @Output()
  facilityChangedEvent = new EventEmitter<FacilityData>();

  generalFacilities$: Observable<FacilitiesForCategoryObj>;
  resourceFacilities$: Observable<Facility[]>;
  properties$: Observable<number[]>;
  accommodations$ = this.store.select(
    AccommodationsStoreSelectors.selectAccommodationsProperties,
  );
  accommodations: AccommodationLookup;
  propertiesNames: { [property_id: number]: string };
  loading$: Observable<boolean>;

  generalFacilities: FacilitiesForCategoryObj;
  resourceFacilities: Facility[] = [];
  resourcesToClone: ResourceItem[];
  accommodationsToCloned: AccommodationLookup;

  facilityCategories: FacilityCategories[] = [];
  facilityCategoriesObj: FacilityCategoriesObj;
  facilityCategories$: Observable<FacilityCategories[]>;
  facilityCategoriesObj$: Observable<FacilityCategoriesObj>;

  generalFacilitiesSubscription: Subscription;
  resourceFacilitiesSubscription: Subscription;
  propertiesSubscription: Subscription;
  accommodationSubscription: Subscription;
  facilityCategoriesSubscription: Subscription;
  facilityCategoriesObjSubscription: Subscription;
  currentPropertySubscription: Subscription;

  @ViewChild(FacilitiesFormComponent, { static: true })
  facilitiesFormComponent: FacilitiesFormComponent;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {}

  loadData() {
    this.store.dispatch(
      new FacilitiesStoreActions.LoadCategoriesRequestAction(),
    );
    this.facilityCategories$ = this.store.select(selectFacilityCategories);
    this.facilityCategoriesObj$ = this.store.select(
      selectFacilityCategoriesObj,
    );
    this.facilityCategoriesSubscription = this.facilityCategories$.subscribe(
      (categories) => {
        if (!categories || !categories.length) {
          return;
        }
        this.store.dispatch(
          new FacilitiesStoreActions.LoadGeneralRequestAction({
            type: this.facilityType,
          }),
        );
        this.facilityCategories = categories;
      },
    );
    this.facilityCategoriesObjSubscription = this.facilityCategoriesObj$.subscribe(
      (categoriesObj) => {
        this.facilityCategoriesObj = categoriesObj;
      },
    );

    this.generalFacilities$ = this.store.select(
      selectFacilitiesForCategoryObj(this.facilityType),
    );
    this.resourceFacilities$ = this.store.select(
      selectFacilitiesFromResource(this.facilityType),
    );
    this.properties$ = this.store
      .select(
        selectUserMePermissionsFeatureProperties(
          this.facilityType === 'accommodation' ? 'accommodations' : 'property',
        ),
      )
      .pipe(distinctUntilChanged((a, b) => isEqual(a, b)));

    this.loading$ = this.store.select(selectFacilitiesLoading);

    this.generalFacilitiesSubscription = this.generalFacilities$.subscribe(
      (generalFacilities) => {
        if (!generalFacilities || !Object.keys(generalFacilities).length) {
          return;
        }
        this.generalFacilities = generalFacilities;
        this.store.dispatch(
          new FacilitiesStoreActions.LoadResourceFacilitiesRequestAction({
            type: this.facilityType,
            id: this.resourceId,
          }),
        );
      },
    );

    this.resourceFacilitiesSubscription = this.resourceFacilities$.subscribe(
      (resourceFacilities) => {
        this.resourceFacilities = resourceFacilities;
      },
    );

    this.propertiesSubscription = this.properties$
      .pipe(
        combineLatest(this.store.select(selectAllProperties)),
        filter(
          ([properties, propertiesData]: [number[], IProperty[]]) =>
            !!(properties && properties.length) &&
            !!(propertiesData && propertiesData.length),
        ),
      )
      .subscribe(([properties, propertiesData]: [number[], IProperty[]]) => {
        const newProperties = intersectionWith(
          propertiesData,
          properties,
          (a, b) => a.id === b,
        );
        if (
          this.facilityType === 'accommodation' &&
          properties &&
          properties.length
        ) {
          this.store.dispatch(
            new AccommodationsStoreActions.LoadLookupRequestAction({
              propertyIds: newProperties.map((property) => property.id),
            }),
          );
          this.propertiesNames = newProperties.reduce((acc, property) => {
            acc[property.id] = { name: property.name };
            return acc;
          }, {});
        } else {
          this.resourcesToClone = newProperties
            .filter((property) => property.id !== this.resourceId)
            .map((property) => {
              return { id: property.id, name: property.name };
            });
        }
      });

    this.accommodationSubscription = this.accommodations$.subscribe(
      (accommodaitons) => {
        if (
          this.facilityType === 'accommodation' &&
          accommodaitons &&
          Object.keys(accommodaitons).length
        ) {
          this.accommodations = accommodaitons;
          // TO-DO rimuovere e procurarsi il selectedProperty in un altro modo
          const currentProperty$ = this.store.select(selectCurrentPropertyId);
          this.currentPropertySubscription = currentProperty$.subscribe(
            (property) => {
              accommodaitons = {
                ...accommodaitons,
                [property.id]: accommodaitons[property.id].filter(
                  (accommodaiton) => accommodaiton.id !== this.resourceId,
                ),
              };
              this.accommodations = { ...accommodaitons };
            },
          );
        }
      },
    );
  }

  syncAccommodationFacilities(data: CreateAccommodationFacilitiesRequest) {
    this.store.dispatch(
      new FacilitiesStoreActions.CreateAccommodationFacilitiesRequestAction({
        accommodationId: this.resourceId,
        data,
      }),
    );
  }

  syncPropertyFacilities(data: CreatePropertyFacilitiesRequest) {
    this.store.dispatch(
      new FacilitiesStoreActions.CreatePropertyFacilitiesRequestAction({
        data,
      }),
    );
  }

  cloneFacilities(resourceId: number) {
    this.store.dispatch(
      new FacilitiesStoreActions.LoadResourceFacilitiesRequestAction({
        type: this.facilityType,
        id: resourceId,
      }),
    );
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.resourceFacilitiesSubscription &&
      this.resourceFacilitiesSubscription.unsubscribe();
    // tslint:disable-next-line:no-unused-expression
    this.generalFacilitiesSubscription &&
      this.generalFacilitiesSubscription.unsubscribe();
    // tslint:disable-next-line:no-unused-expression
    this.propertiesSubscription && this.propertiesSubscription.unsubscribe();
    // tslint:disable-next-line:no-unused-expression
    this.facilityCategoriesObjSubscription &&
      this.facilityCategoriesObjSubscription.unsubscribe();
    // tslint:disable-next-line:no-unused-expression
    this.facilityCategoriesSubscription &&
      this.facilityCategoriesSubscription.unsubscribe();
    // tslint:disable-next-line:no-unused-expression
    this.currentPropertySubscription &&
      this.currentPropertySubscription.unsubscribe();
  }
}
