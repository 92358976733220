import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { FacilityCategoriesResponseSuccess } from '@app/models';
import { GetFacilitiesResponse } from '@app/models/responses/get-facilities-responce';
import { GetGeneralFacilitiesResponse } from '@app/models/responses/get-general-facilities.response';
import { FacilitiesService } from '@app/services';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class FacilitiesStoreEffects {
  constructor(
    private facilitiesService: FacilitiesService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private _notifications: NotificationService,
    private _translate: TranslateService,
  ) {}

  loadCategoriesEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadCategoriesRequestAction>(
        featureActions.ActionTypes.LOAD_CATEGORIES_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadCategoriesRequestAction,
          { core: ICoreState },
        ]) => {
          return this.facilitiesService.loadFacilityCatefgories().pipe(
            map(
              ({ data }: FacilityCategoriesResponseSuccess) =>
                new featureActions.LoadCategoriesSuccessAction({
                  facilityCategories: data,
                }),
            ),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.update_failure',
                  {
                    param: this._translate.instant('addon'),
                  },
                ),
                type: 'error',
              });
              return of(
                new featureActions.LoadCategoriesFailureAction({ error }),
              );
            }),
          );
        },
      ),
    ),
  );

  loadGeneralEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadGeneralRequestAction>(
        featureActions.ActionTypes.LOAD_GENERAL_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadGeneralRequestAction,
          { core: ICoreState },
        ]) => {
          const { type } = action.payload;
          return this.facilitiesService.loadFacilitiesGeneral(type).pipe(
            map(
              ({ data }: GetGeneralFacilitiesResponse) =>
                new featureActions.LoadGeneralSuccessAction({
                  facilityCategories: data,
                  type,
                }),
            ),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.update_failure',
                  {
                    param: this._translate.instant('addon'),
                  },
                ),
                type: 'error',
              });
              return of(new featureActions.LoadGeneralFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadFromResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadResourceFacilitiesRequestAction>(
        featureActions.ActionTypes.LOAD_RESOUCE_FACILITIES_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadResourceFacilitiesRequestAction,
          { core: ICoreState },
        ]) => {
          const { id, type } = action.payload;

          return this.facilitiesService
            .loadFacilitiesFromResource(id, type)
            .pipe(
              map((response: GetFacilitiesResponse) => {
                return new featureActions.LoadResourceFacilitiesSuccessAction({
                  facilities: response.data,
                  type,
                });
              }),
              catchError((error) => {
                this._notifications.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.update_failure',
                    {
                      param: this._translate.instant('addon'),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new featureActions.LoadResourceFacilitiesFailureAction({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  createFromPropertyEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreatePropertyFacilitiesRequestAction>(
        featureActions.ActionTypes.CREATE_PROPERTY_FACILITIES_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CreatePropertyFacilitiesRequestAction,
          { core: ICoreState },
        ]) => {
          const { data } = action.payload;
          return this.facilitiesService.createForProperty(data).pipe(
            map(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.generic_success',
                ),
                type: 'success',
              });
              return new featureActions.CreatePropertyFacilitiesSuccessAction();
            }),
            catchError((error) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.update_failure',
                  {
                    param: this._translate.instant(
                      'notifications.generic_failure',
                    ),
                  },
                ),
                type: 'error',
              });
              return of(
                new featureActions.CreatePropertyFacilitiesFailureAction({
                  error,
                }),
              );
            }),
          );
        },
      ),
    ),
  );

  createFromAccommodationEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateAccommodationFacilitiesRequestAction>(
        featureActions.ActionTypes.CREATE_ACCOMMODATION_FACILITIES_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.CreateAccommodationFacilitiesRequestAction,
          { core: ICoreState },
        ]) => {
          const { accommodationId, data } = action.payload;
          return this.facilitiesService
            .createForAccommodation(accommodationId, data)
            .pipe(
              map(() => {
                this._notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.generic_success',
                  ),
                  type: 'success',
                });
                return new featureActions.CreateAccommodationFacilitiesSuccessAction();
              }),
              catchError((error) => {
                this._notifications.push({
                  title: this._translate.instant('error'),
                  content: this._translate.instant(
                    'notifications.update_failure',
                    {
                      param: this._translate.instant(
                        'notifications.generic_failure',
                      ),
                    },
                  ),
                  type: 'error',
                });
                return of(
                  new featureActions.CreateAccommodationFacilitiesFailureAction(
                    {
                      error,
                    },
                  ),
                );
              }),
            );
        },
      ),
    ),
  );
}
