import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '@app/ui/ui.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { LookupModule } from '../../components/lookup/lookup.module';
import { IsInArrayModule } from '../../pipes/is-in-array/is-in-array-module';
import { PlacesModule } from '../places/places.module';

import { FiltersEffects } from './+state/filters.effects';
import * as fromFilters from './+state/filters.reducer';
import { FiltersComponent } from './filters/filters.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    StoreModule.forFeature('filters', fromFilters.reducer),
    EffectsModule.forFeature([FiltersEffects]),
    HttpClientModule,
    TranslateModule,
    NgPipesModule,
    FormsModule,
    PlacesModule,
    IsInArrayModule,
    LookupModule,
  ],
  declarations: [FiltersComponent],
  exports: [FiltersComponent],
})
export class FiltersModule {}
