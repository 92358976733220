import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { IEmailTemplateSystem } from '../../features/commons/email-templates/models/email-template-system.model';
import { EmailTemplateSystemService } from '../../services/email-templates-system.service';

import * as fromActions from './actions';

@Injectable()
export class EmailTemplatesSystemStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private dataService: EmailTemplateSystemService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId, params }) =>
        this.dataService.load(propertyId, params).pipe(
          map(({ data }: IResponseSuccess<IEmailTemplateSystem[]>) => {
            return fromActions.loadSuccess({ templates: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
