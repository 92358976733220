import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Dictionary, Reservation } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getIsDashboardLoading = (state: State): boolean =>
  state.isDashboardLoading;

export const getIsLoadingTotalDetails = (state: State): boolean =>
  state.isLoadingTotalDetails;

export const getReservationDashboard = (state: State): any[] =>
  state.reservationsDashboard;

export const getPagination = (state: State): IPagination => state.pagination;

export const getExportFileId = (state: State) => state.exportFileId;

export const getDashboardPagination = (state: State): IPagination =>
  state.reservationsDashboardPagination;

export const getReservationDetails = (state: State) => state.entities;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('reservations');

export const selectAllItems: (state: object) => Reservation[] =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: string) =>
  createSelector(selectAllItems, (items: Reservation[]) => {
    if (items) {
      return items.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError,
);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);

export const selectIsDashboardLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsDashboardLoading);

export const selectDashboard: MemoizedSelector<object, any> = createSelector(
  selectState,
  getReservationDashboard,
);

export const selectPagination: MemoizedSelector<object, IPagination> =
  createSelector(selectState, getPagination);

export const selectExportFileId = createSelector(selectState, getExportFileId);

export const selectIsLoadingTotalDetails: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoadingTotalDetails);

export const selectReservationsDashboardPagination: MemoizedSelector<
  object,
  IPagination
> = createSelector(selectState, getDashboardPagination);

export const selectReservationDetails: MemoizedSelector<
  object,
  Dictionary<Reservation>
> = createSelector(selectState, getReservationDetails);
