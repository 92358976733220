<div nz-col nzSpan="24">
  <nz-tabset
    *ngIf="translationsForms && supportedLangs"
    [nzTabPosition]="'top'"
    [nzType]="'card'"
  >
    <nz-tab
      *ngFor="let supportedLang of supportedLangs; let index = index"
      [nzTitle]="tabTitle"
    >
      <ng-template #tabTitle>
        <nz-badge [nzDot]="translationsForms[supportedLang].invalid">
          <div>{{ supportedLang }}</div>
        </nz-badge>
      </ng-template>
      <form [formGroup]="translationsForms[supportedLang]" nz-form>
        <nz-form-item style="width: 100%" *ngIf="nameActive">
          <nz-form-control
            [nzErrorTip]="
              'validation_form.required'
                | translate: { param: 'name' | translate }
            "
            style="width: 100%"
          >
            <nz-form-label nzRequired [nzFor]="'name' + index">{{
              'name' | translate | capitalize
            }}</nz-form-label>
            <input nz-input formControlName="name" [id]="'name' + index" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 100%" *ngIf="descriptionActive">
          <nz-form-control style="width: 100%">
            <nz-form-label [nzFor]="'description' + index">{{
              'description' | translate | capitalize
            }}</nz-form-label>
            <by-text-editor-new formControlName="description">
            </by-text-editor-new>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-tab>
  </nz-tabset>
</div>
