import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

import { ISendEmailModalData } from '../models/send-email-modal-data';
import { SendEmailModalContainerComponent } from '../send-email-modal-container/send-email-modal-container.component';

@Injectable()
export class SendEmailModalService {
  modal: NzModalRef;
  constructor(
    private _modalService: NzModalService,
    private _translate: TranslateService,
  ) {}

  createComponentModal(
    data: ISendEmailModalData,
    onSubmit: (
      modalRef: NzModalRef<SendEmailModalContainerComponent>,
      stopLoading: () => void,
    ) => void,
  ): Observable<SendEmailModalContainerComponent> {
    let isLoading = false;

    this.modal = this._modalService.create<
      SendEmailModalContainerComponent,
      Partial<SendEmailModalContainerComponent>
    >({
      nzContent: SendEmailModalContainerComponent,
      nzData: {
        data,
      },
      // nzWrapClassName: 'send-email-modal-wrap',
      nzClassName: 'send-email-modal-wrap',
      nzFooter: [
        {
          label: upperFirst(this._translate.instant('close')),
          onClick: () => {
            this.modal.destroy();
          },
        },
        {
          label: upperFirst(
            this._translate.instant(data?.submitLabel || 'send'),
          ),
          type: 'primary',
          loading: () => isLoading,
          onClick: (componentInstance) => {
            if (componentInstance.emailTemplateCustomIdSelected) {
              onSubmit(this.modal, () => (isLoading = false));
            }
          },
          disabled: (componentInstance) =>
            componentInstance.emailTemplateCustomIdSelected ? false : true,
        },
      ],
    });

    return this.modal.afterClose.asObservable();
  }
}
