import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HighlightSearch } from './highlight-search.pipe';

@NgModule({
  declarations: [HighlightSearch],
  imports: [CommonModule],
  exports: [HighlightSearch],
})
export class HighlightSearchModule {}
