import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { GeneralModules } from '../../models/objects/modules';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectModulesState = createFeatureSelector<State>('modules');

export const selectAllItems: (state: object) => GeneralModules[] =
  featureAdapter.getSelectors(selectModulesState).selectAll;

export const selectActiveModulesError = createSelector(
  selectModulesState,
  getError,
);

export const selectActiveModulesIsLoading = createSelector(
  selectModulesState,
  getIsLoading,
);
