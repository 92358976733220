import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';

import { FacilityData } from '../models/facility-data.model';

@Component({
  selector: 'by-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityComponent implements OnInit, OnDestroy {
  @Input()
  set form(form: UntypedFormGroup) {
    if (!form) {
      return;
    }
    this._form = form;
    this._form.valueChanges.subscribe(() => {
      this.onFacilityChanged();
    });
  }

  @Output()
  facilityChangedEvent = new EventEmitter<FacilityData>();

  formSubscription: Subscription;
  _form: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}

  onFacilityChanged() {
    this.facilityChangedEvent.emit(this._form.value as FacilityData);
  }

  ngOnDestroy() {
    try {
      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
      }
    } catch (error) {}
  }
}
