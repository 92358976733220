<div [ngClass]="byClass">
  <div
    class="popover-price-container"
    [ngStyle]="{
      background: currentColor ? background : null,
      borderColor: currentColor || null
    }"
  >
    <div class="popover-price-title">
      <span>{{
        reservationTotals?.payment_status + '-details' | translate | upperfirst
      }}</span>
    </div>

    <ul class="popover-price-list">
      <li>
        <span class="by-flex">
          {{ 'rooms' | translate }}
        </span>
        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: reservationTotals.total_accommodations
            }
          "
        ></ng-container>
      </li>

      <li>
        <span>{{ 'addons' | translate }}</span>
        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: addonsTotalPrice
            }
          "
        ></ng-container>
      </li>

      <li>
        <span>
          {{ 'taxes' | translate }}
        </span>
        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: reservationTotals?.total_taxes
            }
          "
        ></ng-container>
      </li>
      <li *ngIf="reservationTotals?.virtual_stamp as virtualStampPrice">
        <span>
          {{ 'virtual_stamp' | translate }}
        </span>
        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: virtualStampPrice
            }
          "
        ></ng-container>
      </li>

      <li *ngIf="+reservationTotals?.moved_in as movedIn">
        <span>
          {{ 'transfers_in' | translate }}
        </span>

        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: movedIn
            }
          "
        ></ng-container>
      </li>

      <li>
        <span>{{
          (+reservationTotals?.total_discount ? 'subtotal' : 'total')
            | translate
        }}</span>
        <strong>
          <i
            *ngIf="+reservationTotals?.moved_out as movedOut"
            class="fas fa-info-circle"
            nz-tooltip
            [nzTooltipTitle]="movedOutTemplate"
          ></i>

          <ng-template #movedOutTemplate>
            <div class="text-align--end">
              {{ 'transfers_out' | translate | upperfirst }}:

              <ng-container
                *ngTemplateOutlet="
                  reservationTotalsContent;
                  context: {
                    $implicit: reservationTotals?.moved_out
                  }
                "
              ></ng-container>
            </div>

            <div
              class="text-align--end by-fs-11 italic"
              *ngFor="let item of reservationTotals?.out | keyvalue"
            >
              {{ item.key | translate | upperfirst }}:

              <ng-container
                *ngTemplateOutlet="
                  reservationTotalsContent;
                  context: {
                    $implicit: item.value
                  }
                "
              ></ng-container>
            </div>
          </ng-template>

          <ng-container
            *ngTemplateOutlet="
              reservationTotalsContent;
              context: {
                $implicit: reservationTotals?.total_price
              }
            "
          ></ng-container>
        </strong>
      </li>

      <li>
        <span>
          {{ 'applied_discounts' | translate }}
        </span>

        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: reservationTotals?.total_discount
            }
          "
        ></ng-container>
      </li>

      <li *ngIf="+reservationTotals?.trashed_invoiced as trashedBills">
        <span>
          {{ 'trashed_bills' | translate }}
        </span>

        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: trashedBills
            }
          "
        ></ng-container>
      </li>

      <li *ngIf="+reservationTotals?.total_discount">
        <span>{{ 'total' | translate }}</span>
        <strong>
          <ng-container
            *ngTemplateOutlet="
              reservationTotalsContent;
              context: {
                $implicit: totalPrice
              }
            "
          ></ng-container>
        </strong>
      </li>

      <li [ngStyle]="{ color: highlightsPaymentStatus ? color : null }">
        <span>
          {{ 'paid' | translate }}
        </span>
        <ng-container
          *ngTemplateOutlet="
            reservationTotalsContent;
            context: {
              $implicit: reservationTotals.paid
            }
          "
        ></ng-container>
      </li>

      <li>
        <span>{{ 'to pay' | translate }}</span>
        <strong>
          <ng-container
            *ngTemplateOutlet="
              reservationTotalsContent;
              context: {
                $implicit: reservationTotals.to_pay
              }
            "
          ></ng-container>
        </strong>
      </li>
    </ul>
  </div>
</div>

<ng-template #reservationTotalsContent let-value>
  <nz-skeleton
    class="by-flex by-flex-end ant-skeleton"
    [class.skeleton-reservation-totals-content]="loadingTotalDetails"
    nzSize="small"
    [nzActive]="true"
    [nzLoading]="loadingTotalDetails"
    [nzParagraph]="{ rows: 1, width: '60px' }"
    [nzTitle]="false"
  >
    <span>{{ +value | toCurrency: currencySymbol }}</span>
  </nz-skeleton>
</ng-template>
