import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { UserRootService } from '@app/services/users.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';

@Injectable()
export class UsersStoreEffects {
  constructor(
    private dataService: UserRootService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  deleteEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteRequestAction) => {
        return this.dataService
          .deleteUser(action.payload.userId, action.payload.propertyId)
          .pipe(
            map(({ data }: any) => {
              return new featureActions.DeleteSuccessAction({
                userId: action.payload.userId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  loadAclRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAclRequestAction>(
        featureActions.ActionTypes.LOAD_ACL_REQUEST,
      ),
      switchMap((action: featureActions.LoadAclRequestAction) => {
        return this.dataService.loadUsersAcl(action.payload.propertyId).pipe(
          map(({ data }: any) => {
            return new featureActions.LoadAclSuccessAction({
              items: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadAclFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  searchRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SearchRequestAction>(
        featureActions.ActionTypes.SEARCH_REQUEST,
      ),
      switchMap((action: featureActions.SearchRequestAction) => {
        return this.dataService.search(action.payload.searchParams).pipe(
          map(
            ({ data }: any) =>
              new featureActions.SearchSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SearchFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  attachRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AttachPropertyRequestAction>(
        featureActions.ActionTypes.ATTACH_PROPERTY_REQUEST,
      ),
      switchMap((action: featureActions.AttachPropertyRequestAction) => {
        const { showNotification, data } = action.payload;
        return this.dataService.attachProperty(action.payload.data).pipe(
          map(() => {
            if (showNotification) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.generic_success',
                ),
                type: 'success',
              });
            }
            return new featureActions.AttachPropertySuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              new featureActions.AttachPropertyFailureAction({ error }),
            );
          }),
        );
      }),
    ),
  );

  detachRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DetachPropertyRequestAction>(
        featureActions.ActionTypes.DETACH_PROPERTY_REQUEST,
      ),
      switchMap((action: featureActions.DetachPropertyRequestAction) => {
        const { showNotification, data } = action.payload;
        return this.dataService.detachProperty(data).pipe(
          map(
            () => {
              if (showNotification) {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.generic_success',
                  ),
                  type: 'success',
                });
              }
              return new featureActions.DetachPropertySuccessAction({
                data: action.payload.data,
              });
            },
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.DetachPropertyFailureAction({ error }),
              );
            }),
          ),
        );
      }),
    ),
  );
}
