export const DEFAULT_FACILITIES_LEGEND_COLORS = [
  {
    title: 'unfree',
    color: '#e67e22',
  },
  {
    title: 'free',
    color: '#58b968',
  },
];
