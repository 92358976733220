import { Component, Input, OnInit, inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrls: ['./media-preview-modal.component.scss'],
})
export class MediaPreviewModalComponent implements OnInit {
  readonly nzDataModal: Partial<MediaPreviewModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input() url: string = this.nzDataModal.url;

  constructor() {}

  ngOnInit() {}
}
