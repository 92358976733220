import { IReservationLog } from '../../features/commons/reservation-details/models/reservation-log.model';
import { ReservationWarning } from '../../features/commons/reservation-details/models/reservation-warning';
import {
  IReservationDataCustom,
  IReservationDetail,
} from '../../features/commons/reservation-details/models/reservation.model';
import { IShowCreditCard, ReservationTotals } from '../../models';
import { ReservationGuest } from '../../models/objects/reservation-guest';

export interface State {
  reservationTotals: ReservationTotals;
  error: any;
  reservationDetails: IReservationDataCustom;
  reservationAccommodations: any[];
  tabsData: IReservationDetail[];
  allGuest: ReservationGuest[];
  bookerId: number;
  creditCard: IShowCreditCard;
  loadMediaCompleted: boolean;
  accommodationsMedias: { [accommodationId: number]: any };
  logs: IReservationLog[];
  warnings: ReservationWarning[];

  reservationTotalsIsLoading: boolean;
  reservationDetailsLoading: boolean;
  reservationLogsLoading: boolean;
  reservationDetailsAddRoomLoading: boolean;
  guestTypePreselected: number;
  guestsExpandMap: Record<string | number, boolean>;
  guestToScroll: string | number;
}

export const initialState: State = {
  error: null,
  reservationTotals: null,
  reservationDetails: null,
  reservationAccommodations: [],
  tabsData: null,
  allGuest: [],
  bookerId: null,
  creditCard: null,
  loadMediaCompleted: false,
  accommodationsMedias: {},
  logs: [],
  warnings: [],

  reservationTotalsIsLoading: false,
  reservationDetailsLoading: false,
  reservationLogsLoading: false,
  reservationDetailsAddRoomLoading: false,
  guestTypePreselected: null,
  guestsExpandMap: null,
  guestToScroll: null,
};
