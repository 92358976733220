import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'by-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit, OnDestroy {
  @Input()
  set fieldToDisabled(fieldToDisabled: {
    read: boolean;
    delete: boolean;
    create: boolean;
    update: boolean;
  }) {
    Object.keys(fieldToDisabled).forEach((key) => {
      this._fieldToDisabled[key] = false;
    });
  }

  @Input()
  disable = false;

  @Input()
  permissionName: string;

  @Input()
  isFirstRow = false;

  @Input()
  set permission(permission: KeyValue<string, UntypedFormGroup>) {
    if (!permission) {
      return;
    }
    this._permission = permission;

    if (this.permissionFormSubscriptions) { return; }

    this.permissionFormSubscriptions = this._permission.value.valueChanges.subscribe(
      (value) => {
        this.valueChangeEvent.emit(value);
      },
    );
  }

  @Output()
  valueChangeEvent = new EventEmitter();

  @Output()
  cascadeSelectionEvent = new EventEmitter();

  permissionFormSubscriptions: Subscription;
  _permission: KeyValue<string, UntypedFormGroup>;
  _fieldToDisabled: {
    read: boolean;
    delete: boolean;
    create: boolean;
    update: boolean;
  } = {
    create: true,
    delete: true,
    read: true,
    update: true,
  };

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.permissionFormSubscriptions) {
      this.permissionFormSubscriptions.unsubscribe();
    }
    this._permission.value.reset();
  }

  cascadeSelection(type: string) {
    this.cascadeSelectionEvent.emit(type);
  }
}
