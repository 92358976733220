<div class="by-mb-2">
  <form
    nz-form
    [formGroup]="_permission.value"
    *ngIf="_permission && permissionName"
  >
    <div nz-row nzJustify="space-between">
      <div class="by-w-33 by-clamp-text-1">
        <span
          nz-tooltip
          [nzTooltipTitle]="permissionName | translate | upperfirst"
          >{{ permissionName | translate | uppercase }}</span
        >
      </div>
      <nz-radio-group
        class="radio-button-style"
        formControlName="permissionType"
      >
        <label nz-radio nzValue="nobody"
          >{{ 'nobody' | translate | capitalize }}
          <i
            class="far fa-arrow-down"
            *ngIf="isFirstRow"
            (click)="cascadeSelection('nobody')"
          ></i
        ></label>
        <label
          [nzDisabled]="_fieldToDisabled['read']"
          nz-radio
          nzValue="reading"
          >{{ 'reading' | translate | capitalize }}
          <i
            class="far fa-arrow-down"
            *ngIf="isFirstRow"
            (click)="cascadeSelection('reading')"
          ></i
        ></label>
        <label
          [nzDisabled]="
            _fieldToDisabled['create'] &&
            _fieldToDisabled['update'] &&
            _fieldToDisabled['delete']
          "
          nz-radio
          nzValue="readingWriting"
          >{{ 'reading_writing' | translate | capitalize }}
          <i
            class="far fa-arrow-down"
            *ngIf="isFirstRow"
            (click)="cascadeSelection('readingWriting')"
          ></i
        ></label>
      </nz-radio-group>
    </div>
  </form>
</div>
