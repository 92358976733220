import { IUserRoot } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getUsersAcl = (state: State): IUserRoot[] => state.usersAcl;

export const selectUsersState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('users');

export const selectAllUsersItems: (
  state: object,
) => IUserRoot[] = featureAdapter.getSelectors(selectUsersState).selectAll;

export const selectUsersById = (id: string) =>
  createSelector(
    selectAllUsersItems,
    (users: IUserRoot[]) => {
      if (users) {
        return users.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectUsersAcl: MemoizedSelector<object, any> = createSelector(
  selectUsersState,
  getUsersAcl,
);

export const selectUsersError: MemoizedSelector<object, any> = createSelector(
  selectUsersState,
  getError,
);

export const selectUsersIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectUsersState,
  getIsLoading,
);
