import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { flattenDeep } from 'lodash';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { FiltersService } from '../services/filters.service';

import {
  FiltersActionTypes,
  LoadAccommodationsFailure,
  LoadAccommodationsRequest,
  LoadAccommodationsSuccess,
  LoadChannelsFailure,
  LoadChannelsRequest,
  LoadChannelsSuccess,
  LoadRateplansFailure,
  LoadRateplansSuccess,
} from './filters.actions';

@Injectable()
export class FiltersEffects {
  loadAccommodations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActionTypes.LoadAccommodationsRequest),
      exhaustMap(({ payload: { propertiesIds } }: LoadAccommodationsRequest) =>
        this.filtersService.loadAccommodations(propertiesIds).pipe(
          map(
            ({ data: accommodations }: IResponseSuccess) =>
              new LoadAccommodationsSuccess({ propertiesIds, accommodations }),
          ),
          catchError((error: Error) =>
            of(new LoadAccommodationsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadRateplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActionTypes.LoadAccommodationsSuccess),
      exhaustMap((action: LoadAccommodationsSuccess) => {
        const { accommodations } = action.payload;
        const accommodationsIds = flattenDeep(
          Object.keys(accommodations || {}).map((key) => accommodations[key]),
        ).map((accommodation) => accommodation.id);
        return this.filtersService.loadRateplans(accommodationsIds).pipe(
          map(
            ({ data: rateplans }: IResponseSuccess) =>
              new LoadRateplansSuccess({
                rateplans,
                accommodationsIds,
              }),
          ),
          catchError((error: Error) => of(new LoadRateplansFailure({ error }))),
        );
      }),
    ),
  );

  loadChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FiltersActionTypes.LoadChannelsRequest,
        FiltersActionTypes.LoadAccommodationsSuccess,
      ),
      exhaustMap((action: LoadChannelsRequest | LoadAccommodationsSuccess) =>
        this.filtersService.loadChannels(action.payload.propertiesIds).pipe(
          map(
            ({ data: channels }: IResponseSuccess) =>
              new LoadChannelsSuccess({ channels }),
          ),
          catchError((error: Error) => of(new LoadChannelsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private filtersService: FiltersService,
  ) {}
}
