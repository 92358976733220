<div nz-row nzJustify="space-between" class="by-mb-2">
  <div>
    <div class="search-box">
      <nz-input-group
        [nzSuffix]="suffixIconSearch"
        *ngIf="options | get : 'quick_search'"
      >
        <form nz-form [nzLayout]="'horizontal'" [formGroup]="_quickSearch">
          <input
            type="text"
            nz-input
            formControlName="query"
            [placeholder]="'search' | translate | capitalize"
          />
        </form>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i class="fal fa-search" *ngIf="!loading"></i>
        <nz-spin [nzSpinning]="loading" nzSize="small"></nz-spin>
      </ng-template>
    </div>
    <ng-container *ngTemplateOutlet="topLeftTemplate"></ng-container>
  </div>

  <div class="filters">
    <ng-container *ngIf="!(options && options?.noDefaultColums) && !noSelect">
      <nz-select
        #multiSelectButton
        [nzDropdownRender]="render"
        style="width: 280px"
        id="selectAllNoValue"
        [nzMaxTagCount]="0"
        [nzMaxTagPlaceholder]="selectedCount"
        [formControl]="filtersControl"
        [ngModel]="formFilter.get('filters').value"
        (ngModelChange)="selectAllActions($event)"
        nzAllowClear
        nzMode="multiple"
        nzPlaceHolder="{{
          (formFilter.get('filters').value.length
            ? 'columns_selected'
            : 'select_columns'
          )
            | translate
            | capitalize
        }}  {{
          formFilter.get('filters').value.length
            ? ': ' + formFilter.get('filters').value.length
            : null
        }}"
        [nzShowArrow]="true"
      >
        <nz-option nzCustomContent nzValue="all">
          <span style="color: #2f9bfe !important">{{
            'select_all' | translate | capitalize
          }}</span>
        </nz-option>
        <nz-option
          *ngFor="let column of originalColumns | where : notNil"
          [nzValue]="column.label"
          [nzLabel]="column.label | translate | capitalize"
        ></nz-option>
        <ng-template #selectedCount>
          <span class="ant-select-selection__placeholder">
            {{
              (formFilter.get('filters').value.length
                ? 'columns_selected'
                : 'select_columns'
              )
                | translate
                | capitalize
            }}
            {{
              formFilter.get('filters').value.length
                ? ': ' + formFilter.get('filters').value.length
                : null
            }}
          </span>
        </ng-template>
      </nz-select>
      <ng-template #render>
        <nz-divider style="margin: 4px 0"></nz-divider>
        <div class="container-button-multiselect">
          <button
            nz-button
            (click)="multiSelectButton.nzOpen = false"
            nzType="primary"
          >
            {{ 'button-multiselect-ok' | translate }}
          </button>
        </div>
      </ng-template>
    </ng-container>

    <button
      *ngIf="options | get : 'print'"
      nz-button
      nzType="primary"
      class="by-ml-10 white-text ant-btn-icon-only"
      (click)="
        export.emit({
          export_format: 'pdf',
          action_type: 'print',
          export_columns: formFilter.get('filters').value || [],
          expanded_rows: getExpandedRows()
        })
      "
      nz-tooltip
      [nzTooltipTitle]="'print' | translate | upperfirst"
    >
      <i class="fal fa-print"></i>
    </button>
    <div
      nz-tooltip
      [nzTooltipTitle]="'export' | translate | upperfirst"
      *ngIf="options | get : 'export'"
    >
      <button
        nz-button
        class="by-ml-10 ant-btn-icon-only"
        nz-dropdown
        [nzDropdownMenu]="menu"
        nzTrigger="click"
      >
        <i class="fal fa-cloud-download"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            (click)="
              export.emit({
                export_format: 'pdf',
                action_type: 'export',
                export_columns: formFilter.get('filters').value || [],
                expanded_rows: getExpandedRows()
              })
            "
          >
            <i class="fal fa-file-pdf"></i> PDF
          </li>
          <li
            nz-menu-item
            (click)="
              export.emit({
                export_format: 'csv',
                action_type: 'export',
                export_columns: formFilter.get('filters').value || [],
                expanded_rows: getExpandedRows()
              })
            "
          >
            <i class="fal fa-file-csv"></i> CSV
          </li>
          <li
            nz-menu-item
            (click)="
              export.emit({
                export_format: 'xlsx',
                action_type: 'export',
                export_columns: formFilter.get('filters').value || [],
                expanded_rows: getExpandedRows()
              })
            "
          >
            <i class="fal fa-file-spreadsheet"></i> XLSX
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>

    <button
      *ngIf="buttonNewPath && buttonNewPath !== ''"
      nz-button
      nzType="primary"
      class="by-ml-10"
      [routerLink]="[buttonNewPath]"
    >
      <i class="fal fa-plus-circle"></i>
      {{ 'new' | translate | capitalize }}
    </button>

    <!-- <nz-select
    [formControl]="filtersControl"
    formControlName="filters"
    style="width: 280px;"
    nzMode="multiple"
    [nzPlaceHolder]="'select_columns' | translate | capitalize"
  >
    <nz-option
      *ngFor="let column of originalColumns | where: notNil"
      [nzValue]="column.label"
      [nzLabel]="column.label | translate | capitalize"
    ></nz-option>
  </nz-select> -->
  </div>
</div>

<nz-spin [nzSpinning]="loading">
  <nz-table
    nzHideOnSinglePage
    #table
    [nzData]="data"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [ngClass]="[options?.class || '']"
    class="customizable-table"
  >
    <thead (nzSortChange)="sort($event)">
      <tr>
        <ng-container *ngIf="isExpandTable">
          <th nzShowExpand></th>
        </ng-container>
        <ng-container
          *ngFor="
            let column of filteredColumns;
            let first = first;
            let last = last
          "
        >
          <th
            scope="col"
            [nzShowSort]="options?.sortable && !column?.noSort"
            [nzColumnKey]="column.path"
            *ngIf="column.label"
            [attr.colspan]="column?.options?.colspan || 1"
            [ngClass]="[column.thClass || '']"
            [nzLeft]="first && options?.firstColumnFixed ? '0px' : null"
            [nzRight]="
              last && options?.lastColumnFixed && !actions ? '0px' : null
            "
          >
            <span>{{
              (column.hideLabel ? '' : column.label) | translate | capitalize
            }}</span>
          </th>
        </ng-container>
        <ng-container *ngIf="enableCheckBox">
          <th
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="checkAll($event)"
            nzShowCheckbox
            scope="col"
          ></th>
        </ng-container>
        <ng-container *ngIf="actions && actions.length">
          <th
            scope="col"
            [nzRight]="options?.lastColumnFixed ? '0px' : null"
          ></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of table.data; let index = index">
        <tr>
          <td
            *ngIf="isExpandTable"
            nzShowExpand
            [(nzExpand)]="item.expand"
            (nzExpandChange)="onExpand($event, item)"
          ></td>
          <ng-container
            *ngFor="
              let column of filteredColumns;
              let first = first;
              let last = last
            "
          >
            <ng-container
              *ngIf="
                !column.checkCondition ||
                  (column.checkCondition && column.checkCondition(item));
                else voidTd
              "
            >
              <td
                [ngClass]="{
                  'text-align--center':
                    column.type === 'icon_link' ||
                    column.type === 'reservation_id' ||
                    column.type === 'icon'
                }"
                class="{{ column.tdClass || '' }} {{
                  item | get : column.pathClass
                }}"
                [nzLeft]="first && options?.firstColumnFixed ? '0px' : null"
                [nzRight]="
                  last && options?.lastColumnFixed && !actions ? '0px' : null
                "
              >
                <ng-template #titleTpl>
                  <ul style="list-style-type: none; padding-left: 0px">
                    <ng-container
                      *ngIf="(item | get : column.pathTooltip).data.length > 0"
                    >
                      <li
                        *ngFor="
                          let data of (item | get : column.pathTooltip).data
                        "
                      >
                        {{ data | translate | upperfirst }}
                      </li>
                    </ng-container>
                  </ul>
                </ng-template>
                <span *ngIf="column.type === 'text'">
                  <ng-container *ngIf="!column?.upperfirst; else upperfirst">
                    {{ item | get : column.path }}
                  </ng-container>
                  <ng-template #upperfirst>
                    {{ item | get : column.path | upperfirst }}
                  </ng-template>
                  <ng-container *ngIf="column.isIcon">
                    <i
                      *ngIf="(item | get : column.pathIcon).show"
                      style="margin-left: 5px"
                      [class]="(item | get : column.pathIcon)?.iconClass"
                      [ngStyle]="{
                        color: (item | get : column.pathIcon)?.iconColor
                      }"
                    ></i>
                  </ng-container>
                  <ng-container>
                    <i
                      [ngStyle]="(item | get : column.pathTooltip).style"
                      nz-tooltip
                      [nzTooltipTitle]="
                        (item | get : column.pathTooltip).data.length
                          ? titleTpl
                          : null
                      "
                      *ngIf="
                        column.isTooltip &&
                        (item | get : column.pathTooltip).show &&
                        (item | get : column.pathTooltip).data.length
                      "
                      [class]="(item | get : column.pathTooltip).iconClass"
                    ></i>
                  </ng-container>
                </span>

                <ng-container *ngIf="column.type === 'vertical_list'">
                  <div style="display: flex; flex-direction: column">
                    <div *ngFor="let item of (item | get : column.path) || []">
                      {{ item }}
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="column.type === 'reservation_id'">
                  <i
                    class="fas fa-asterisk pointer reference-icon"
                    nz-tooltip
                    [nzTooltipTitle]="item | get : column.path"
                  ></i>
                </ng-container>

                <span *ngIf="column.type === 'translated_text'">
                  {{ item | get : column.path | translate | upperfirst }}
                </span>

                <ng-container *ngIf="column.type === 'select'">
                  <nz-select
                    style="width: 200px"
                    nzShowSearch
                    nzAllowClear
                    [nzPlaceHolder]="'select' | translate | upperfirst"
                    [ngModel]="
                      item | get : column.selectData.default_value_path
                    "
                    (ngModelChange)="
                      onActionTrigger(
                        column.emitKey,
                        index,
                        false,
                        column.emitProps
                      )
                    "
                  >
                    <ng-container
                      *ngFor="
                        let option of (item
                          | get : column.selectData.options_array_path) || []
                      "
                    >
                      <nz-option
                        [nzLabel]="option.label"
                        nzValue="option.value"
                      ></nz-option>
                    </ng-container>
                  </nz-select>
                </ng-container>

                <ng-container
                  *ngIf="
                    column.isIconTooltip && (item | get : column.pathTooltip)
                  "
                >
                  <i
                    nz-tooltip
                    [nzTooltipTitle]="
                      item | get : column.pathTooltip | translate | upperfirst
                    "
                    [class]="
                      (item | get : column.pathIcon)?.iconClass ||
                      column.iconClass
                    "
                    [ngStyle]="{
                      color: (item | get : column.pathIcon)?.iconColor,
                      'margin-left': column.type === 'icon' ? '0' : '5px'
                    }"
                  ></i>
                </ng-container>

                <ng-container *ngIf="column.type === 'badge'">
                  <nz-badge
                    *ngIf="column.isBadge"
                    [nzStatus]="
                      (item | get : column.pathBadge).status
                        ? 'success'
                        : 'error'
                    "
                    [nzText]="
                      (item | get : column.pathBadge).status
                        ? ((item | get : column.pathBadge).success
                          | translate
                          | upperfirst)
                        : ((item | get : column.pathBadge).error
                          | translate
                          | upperfirst)
                    "
                  ></nz-badge>
                </ng-container>
                <ng-container *ngIf="column.type === 'guests'">
                  <div class="guests">
                    <div>
                      <i class="fas fa-user by-mr-3"></i>
                      <span>{{ item | get : column.path }}</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="column.type === 'dateRange'">
                  <div class="to_from_dates">
                    <div>
                      <span>{{ item | get : column.path }}</span>
                      <span>{{ item | get : column.path2 }}</span>
                    </div>
                  </div>
                </ng-container>

                <by-currency-format
                  *ngIf="column.type === 'currency'"
                  [currencyValue]="item | get : column.path"
                  [currencySymbol]="currencySymbol"
                ></by-currency-format>

                <ng-container *ngIf="column.type === 'active_badge'">
                  <nz-badge
                    [nzStatus]="
                      !!(item | get : column.path) ? 'success' : 'error'
                    "
                    [nzText]=""
                  ></nz-badge>
                </ng-container>

                <ng-container *ngIf="column.type === 'active_icons'">
                  <div style="width: 100%; text-align: center">
                    <i
                      nz-tooltip
                      [nzTooltipTitle]="
                        column?.hideTitle
                          ? ''
                          : ((!!(item | get : column.path)
                              ? 'active'
                              : 'not_active'
                            )
                            | translate
                            | upperfirst)
                      "
                      [ngStyle]="{
                        color: !!(item | get : column.path)
                          ? '#33de40'
                          : '#f5222d'
                      }"
                      class="fas fa-circle"
                    ></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="column.type === 'status_payment'">
                  <div style="width: 100%; text-align: center">
                    <i
                      nz-tooltip
                      [nzTooltipTitle]="
                        item | get : column.path | translate | upperfirst
                      "
                      [ngStyle]="{
                        color:
                          colorsDefaultPayment &&
                          colorsDefaultPayment[item | get : column.path]
                      }"
                      class="fas fa-circle"
                    ></i>
                  </div>
                </ng-container>

                <a
                  *ngIf="
                    column.type === 'icon_link' && (item | get : column.path)
                  "
                  [href]="item | get : column.path"
                  target="_blank"
                  nz-tooltip
                  [nzTooltipTitle]="column.label | translate | upperfirst"
                >
                  <i
                    class="{{ column.iconClass }}"
                    [ngStyle]="{ property: column.iconColor }"
                    style="font-size: 16px"
                  ></i>
                </a>

                <span *ngIf="column.type === 'date'">
                  {{ item | get : column.path | date : 'dd/MM/yyyy' }}
                </span>

                <span *ngIf="column.type === 'long_date'">
                  {{ item | get : column.path | date : 'dd' }}
                  {{
                    item
                      | get : column.path
                      | date : 'MMMM'
                      | lowercase
                      | translate
                      | capitalize
                  }}
                  {{ item | get : column.path | date : 'y' }}
                </span>

                <span
                  (click)="
                    onActionTrigger(
                      column.emitKey,
                      index,
                      false,
                      column.emitProps
                    )
                  "
                  *ngIf="column.type === 'link'"
                  class="text-like-link"
                  >{{ item | get : column.path }}</span
                >
              </td>
              <ng-container *ngIf="column.type === 'dateRange'">
                <td class="nights">
                  <div>
                    <i class="fas fa-moon by-mr-3"></i>
                    <span>{{ item | get : column.path3 }}</span>
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="enableCheckBox">
            <td
              (nzCheckedChange)="refreshStatus(item.id)"
              nzShowCheckbox
              [nzChecked]="dataChecked[item.id]"
              scope="col"
            ></td>
          </ng-container>
          <ng-container
            *ngIf="
              (options.propertyIdPath &&
                canWrite[item | get : options.propertyIdPath]) ||
              !options.propertyIdPath
            "
          >
            <ng-container *ngIf="actions && actions.length">
              <td nzRight="0px" class="ellipsis-actions-td">
                <div
                  class="pointer"
                  nz-dropdown
                  nzTrigger="click"
                  [nzDropdownMenu]="menu"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </div>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <ng-container *ngFor="let action of actions; let i = index">
                      <li
                        nz-menu-item
                        *ngIf="
                          !action.checkCondition ||
                          (action.checkCondition && action.checkCondition(item))
                        "
                        [nzCondition]="
                          !action.confirm ||
                          (item?.actionsToDisable
                            ? (item?.actionsToDisable)[i]
                            : null)
                        "
                        nz-popconfirm
                        [nzPopconfirmTitle]="'are_you_sure' | translate"
                        [nzDisabled]="
                          (action?.checkboxList &&
                            !allChecked &&
                            !indeterminate) ||
                          (item?.actionsToDisable
                            ? (item?.actionsToDisable)[i]
                            : false) ||
                          action?.disabled
                        "
                        (nzOnConfirm)="
                          (action?.checkboxList &&
                            !allChecked &&
                            !indeterminate) ||
                          action?.disabled
                            ? null
                            : onActionTrigger(
                                action.emitKey,
                                index,
                                item?.actionsToDisable
                                  ? (item?.actionsToDisable)[i]
                                  : false
                              )
                        "
                      >
                        <i
                          [ngStyle]="{ color: action?.color }"
                          [class]="action?.icon"
                          aria-hidden="true"
                        ></i>
                        {{ action.label | translate | capitalize }}
                      </li>
                    </ng-container>
                  </ul>
                </nz-dropdown-menu>
              </td>
            </ng-container>
          </ng-container>
        </tr>
        <ng-container *ngIf="isExpandTable && item?.expand">
          <ng-container
            *ngTemplateOutlet="exapandTmpl; context: { item: item }"
          ></ng-container></ng-container
      ></ng-container>
    </tbody>
  </nz-table>
</nz-spin>
<nz-pagination
  class="by-mt-2"
  style="float: right"
  (nzPageIndexChange)="onPageChange($event)"
  [nzHideOnSinglePage]="true"
  [nzPageIndex]="pagination?.current_page"
  [nzPageSize]="pagination?.per_page"
  [nzTotal]="pagination?.total"
></nz-pagination>

<ng-template #voidTd></ng-template>
