import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction(
  '[Document Scan] Load Request',
  props<{
    image: any;
    countryName: string;
  }>(),
);

export const loadSuccess = createAction(
  '[Document Scan] Load Success',
  props<{ response: any }>(),
);

export const loadFailure = createAction(
  '[Document Scan] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Document Scan] Reset State');
