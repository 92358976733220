import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  ICoreState,
  selectAllProperties,
  selectLanguages,
  selectPropertySelectedLanguage,
} from '@app/core/+state/core.reducer';
import { ILanguage } from '@app/core/models/api/generics/languages/language.model';
import { IProperty } from '@app/features/commons/properties/models/property.model';
import {
  IEmailTemplateCustom,
  IEmailTemplatePreviewContent,
} from '@app/models';
import {
  EmailTemplatesCustomStoreActions,
  EmailTemplatesCustomStoreSelectors,
  EmailTemplatesCustomStoreState,
} from '@app/root-store/email-templates-custom-store';
import { ILoadTemplatePreviewEventData } from '@app/shared/whatsapp-modal/models/load-template-preview-event-data.model';
import { Store, select } from '@ngrx/store';
import {
  Observable,
  combineLatest,
  debounceTime,
  startWith,
  switchMap,
} from 'rxjs';
import { SubSink } from 'subsink';

import { ISendEmailModalData } from '../models/send-email-modal-data';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { UserPreferencesStoreSelectors } from '../../../root-store/user-preferences-store';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClickToSearchComponent } from '../../../components/click-to-search/click-to-search.component';
import { TranslateModule } from '@ngx-translate/core';

import {
  NgPipesModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { PageHeaderModule } from '../../../components/page-header/page-header.module';
import { SendEmailModalListComponent } from '../send-email-modal-list/send-email-modal-list.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SendEmailModalPreviewMessageComponent } from '../send-email-modal-preview-message/send-email-modal-preview-message.component';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'by-send-email-modal-container',
  templateUrl: './send-email-modal-container.component.html',
  styleUrls: ['./send-email-modal-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    ClickToSearchComponent,
    FormsModule,
    TranslateModule,
    NgPipesModule,
    PageHeaderModule,
    SendEmailModalListComponent,
    SendEmailModalPreviewMessageComponent,
    NzGridModule,
    NzFormModule,
    ReactiveFormsModule,
    NgUpperFirstPipeModule,
    NzSelectModule,
  ],
})
export class SendEmailModalContainerComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<SendEmailModalContainerComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  set data(data: ISendEmailModalData) {
    this.reservationId = data.reservationId;
    this.propertyId = data && data.propertyId;
    this.propertiesIds = data && data.propertiesIds;
    if (this.propertyId) {
      this.loadTemplatecustom(this.propertyId);
    }
  }

  emailTemplatesCustomError$: Observable<string>;
  emailTemplatesCustomIsLoading$: Observable<boolean>;
  emailTemplatePreviewContent$: Observable<IEmailTemplatePreviewContent>;
  emailTemplatePreviewDefaultLanguage$: Observable<IEmailTemplatePreviewContent>;
  languages$: Observable<ILanguage[]>;
  propertySelectedLanguage$: Observable<string>;

  reservationId: number;
  propertiesIds: number[];
  propertyId: number;
  emailTemplateCustomIdSelected: number;
  public propertiesNames: {
    [propertyId: number]: string;
  };

  subs = new SubSink();

  selectedLanguage = null;

  emailTemplatePreviewContent: IEmailTemplatePreviewContent;

  search = this.fb.control('');

  userPreference$ = this.store.select(
    UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
      'messageTemplateOrder',
    ),
  );

  emailTemplatesCustom$ = combineLatest([
    this.userPreference$,
    this.search.valueChanges.pipe(startWith(''), debounceTime(300)),
  ]).pipe(
    switchMap(([{ orderByEmail }, search]) => {
      return this.store.select(
        EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomSearch(
          'email',
          orderByEmail,
          search,
        ),
      );
    }),
  );

  selectedTemplate: IEmailTemplateCustom;

  constructor(
    private store: Store<EmailTemplatesCustomStoreState.State>,
    private _store: Store<ICoreState>,
    private fb: FormBuilder,
  ) {
    this.data = this.nzDataModal.data;
  }

  ngOnInit() {
    this.emailTemplatesCustomError$ = this.store.select(
      EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomError,
    );
    this.emailTemplatesCustomIsLoading$ = this.store.select(
      EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomIsLoading,
    );
    this.propertySelectedLanguage$ = this.store.select(
      selectPropertySelectedLanguage,
    );

    this.languages$ = this._store.select(selectLanguages);
    this.subs.add(
      this._store
        .select(selectAllProperties)
        .subscribe((properties: IProperty[]) => {
          if (!properties) {
            return;
          }
          if (!this.propertyId) {
            this.propertyId = properties[0].id;
            this.load(this.propertyId);
          }
          this.propertiesNames = properties.reduce((acc, { id, name }) => {
            acc[id] = name;
            return acc;
          }, {});
        }),
    );
  }

  loadEmailTemplateCustomPreview(data: ILoadTemplatePreviewEventData) {
    const { template, lang_iso_code, propertySelectedLangIsoCode } = data;

    this.selectedTemplate = template;

    this.selectedLanguage = lang_iso_code;

    this.emailTemplateCustomIdSelected = template.id;
    this.emailTemplatePreviewContent$ = this.store.select(
      EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomPreviewByLang(
        data.lang_iso_code,
      ),
    );

    this.subs.add(
      this.emailTemplatePreviewContent$.subscribe((preview) => {
        this.emailTemplatePreviewContent = preview;
      }),
    );

    this.emailTemplatePreviewDefaultLanguage$ = this.store.select(
      EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomPreviewByLang(
        propertySelectedLangIsoCode,
      ),
    );

    this.store.dispatch(
      new EmailTemplatesCustomStoreActions.LoadPreviewRequestAction({
        reservationId: this.reservationId,
        emailTemplateCustomId: data.template.id,
        lang_iso_code,
      }),
    );
  }

  send(onComplete?: () => void) {
    this.store.dispatch(
      new EmailTemplatesCustomStoreActions.SendRequestAction({
        reservationId: this.reservationId,
        emailTemplateCustomId: this.emailTemplateCustomIdSelected,
        langIsoCode: this.selectedLanguage,
        onComplete,
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  load(propertyId) {
    if (!propertyId || propertyId === this.propertyId) {
      return;
    }
    this.propertyId = propertyId;
    this.loadTemplatecustom(propertyId);
  }

  loadTemplatecustom(propertyId: number) {
    this.store.dispatch(
      new EmailTemplatesCustomStoreActions.LoadRequestAction({ propertyId }),
    );
  }

  onReloadTemplateList() {
    this.loadTemplatecustom(this.propertyId);
  }
}
