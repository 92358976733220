import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { getBase64 } from '@app/core/helpers/get-base-64';
import { RootState } from '@app/root-store/root-state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { ICustomerType } from '../../features/commons/customer-details/models/customer-type.model';
import { DocumentScanResult } from '../../models';
import {
  DocumentScanStoreActions,
  DocumentScanStoreSelectors,
} from '../../root-store/document-scan-store';
import { NotificationService } from '../../ui/services/notification.service';

@Component({
  selector: 'by-image-text-extract',
  templateUrl: './image-text-extract.component.html',
  styleUrls: ['./image-text-extract.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageTextExtractComponent implements OnInit, OnDestroy {
  @Input()
  buttonTpl: TemplateRef<any>;

  @Input()
  set documentTypes(data: ICustomerType[]) {
    this.documentTypesFiltered = data.filter((documentType) => {
      return [23, 24, 31, 33].includes(documentType.id);
    });
  }

  @Input()
  countryId = 1;

  @Input()
  countryName = 'italia';

  @Output()
  textDataOcrEvent = new EventEmitter<DocumentScanResult>();

  loading$ = this.store.select(DocumentScanStoreSelectors.selectIsLoading);

  file: string;

  documentTypeId = 24;

  documentTypesFiltered: ICustomerType[] = [];

  showWebcam = false;

  isModalVisible = false;

  isMobile = false;

  webcamImage: WebcamImage = null;

  radioValue: 'webcam' | 'device' = 'device';

  fileTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];

  private trigger: Subject<void> = new Subject<void>();

  private subs = new SubSink();

  hasCameraPermission = true;

  constructor(
    private store: Store<RootState>,
    private deviceDetector: DeviceDetectorService,
    private notification: NotificationService,
    private translate: TranslateService,
  ) {
    this.subs.add(
      this.store
        .select(DocumentScanStoreSelectors.selectData)
        .subscribe((data) => {
          if (!data) {
            return;
          }
          this.finishScanSave(data);
        }),
    );
  }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
  }

  onUpload(file: File) {
    getBase64(file, (image) => (this.file = image));
  }

  extractText() {
    if (!this.file) {
      return;
    }

    this.store.dispatch(
      DocumentScanStoreActions.loadRequest({
        image: this.file,
        countryName: this.countryName,
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(DocumentScanStoreActions.resetState());
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
      this.showWebcam = false;
      this.webcamImage = null;
      this.hasCameraPermission = false;
      console.warn('Camera access was not allowed by user!');
    }
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.file = webcamImage.imageAsBase64;
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
    this.webcamImage = null;
    this.file = null;
  }

  deleteWebcamFile() {
    this.file = null;
    this.webcamImage = null;
  }

  changeRadioValue(event) {
    this.showWebcam = false;
    if (event === 'webcam') {
      this.showWebcam = true;
    }
    this.file = null;
    this.webcamImage = null;
  }

  finishScanSave(dataApi) {
    const {
      expire_date,
      released_on,
      birth_date,
      nic,
      document_number,
      places,
      birth_places,
      ...allData
    } = dataApi;

    let placesExtract = {};
    if (places) {
      placesExtract = {
        places: {
          countryId: get(places, ['country', 'id'], 1),
          countyId: get(places, ['county', 'id']),
          stateId: get(places, ['state', 'id']),
          cityId: get(places, ['city', 'id']),
          country: get(places, ['country', 'name']),
          county: get(places, ['county', 'name']),
          state: get(places, ['state', 'name']),
          city: get(places, ['city', 'name']),
        },
      };
    }
    let birthPlacesExtract = {};
    if (birth_places) {
      birthPlacesExtract = {
        birth_places: {
          countryId: get(birth_places, ['country', 'id'], 1),
          countyId: get(birth_places, ['county', 'id']),
          stateId: get(birth_places, ['state', 'id']),
          cityId: get(birth_places, ['city', 'id']),
          country: get(birth_places, ['country', 'name']),
          county: get(birth_places, ['county', 'name']),
          state: get(birth_places, ['state', 'name']),
          city: get(birth_places, ['city', 'name']),
        },
      };
    }

    const dataForm = {
      ...allData,
      ...placesExtract,
      ...birthPlacesExtract,
      birthday_date: moment(birth_date, 'YYYY-MM-DD').toDate(),
      document: {
        document_type_id: this.documentTypeId,
        expire_date: moment(expire_date, 'YYYY-MM-DD').toDate(),
        released_on: moment(released_on, 'YYYY-MM-DD').toDate(),
        document_number,
      },
    };

    this.textDataOcrEvent.emit(dataForm);

    const extractSuccess = dataForm?.name && dataForm?.surname;

    const content = extractSuccess
      ? 'text_extracted'
      : 'text_extract_low_quality_warning';

    const type = extractSuccess ? 'success' : 'warning';

    const title = extractSuccess ? 'passaport_scan' : 'warning';

    this.notification.push({
      title: this.translate.instant(title),
      content: this.translate.instant(content),
      type,
    });

    this.closeModal();
  }

  closeModal() {
    this.deleteWebcamFile();
    this.isModalVisible = false;
    this.showWebcam = false;
  }

  showModal() {
    this.isModalVisible = true;
    this.showWebcam = true;
  }

  onChangePlace(data) {
    this.countryName = get(data, 'country');
  }
}
