<div
  *ngIf="_form"
  [formGroup]="_form"
  nz-row
  class="by-border-bottom facility-content-row facility"
>
  <div class="by-mb-2" nz-row class="align-items-baseline">
    <i
      class="by-mr-2 by-mb-2"
      [ngClass]="
        _form.get('icon').value ? _form.get('icon').value : 'far fa-icons'
      "
    ></i>
    {{ _form.get('label').value }}
  </div>
  <div nz-row class="align-items-baseline">
    <nz-radio-group formControlName="active" style="margin-bottom: 5px;">
      <label
        [ngClass]="_form.get('active').value === 1 ? 'selected' : null"
        class="by-border-gray radio-option"
        nz-radio
        [nzValue]="1"
      >
        {{ 'yes' | translate | capitalize }}
      </label>
      <label class="by-border-gray radio-option" nz-radio [nzValue]="0">
        {{ 'no' | translate | capitalize }}
      </label>
    </nz-radio-group>
    <nz-select
      style="width: 150px;"
      formControlName="is_free"
      nzPlaceHolder="{{
        'placeholder.select'
          | translate
            : {
                param: 'accommodation' | translate
              }
      }}"
    >
      <nz-option
        [nzValue]="1"
        [nzLabel]="'free' | translate | capitalize"
      ></nz-option>
      <nz-option
        [nzValue]="0"
        [nzLabel]="'unfree' | translate | capitalize"
      ></nz-option>
    </nz-select>
  </div>
</div>
