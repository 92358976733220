<by-facilities-form
  [resourceName]="resourceName"
  [resourceFacilities]="resourceFacilities"
  [generalFacilities]="generalFacilities"
  [resourcesToClone]="resourcesToClone"
  [facilityType]="facilityType"
  [resourceId]="resourceId"
  [facilityCategories]="facilityCategories"
  [facilityCategoriesObj]="facilityCategoriesObj"
  [accommodations]="accommodations"
  [propertiesNames]="propertiesNames"
  (syncAccommodationEvent)="syncAccommodationFacilities($event)"
  (syncPropertyEvent)="syncPropertyFacilities($event)"
  (cloneFacilitiesEvent)="cloneFacilities($event)"
  (facilityChangedEvent)="facilityChangedEvent.emit($event)"
></by-facilities-form>
