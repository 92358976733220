import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@app/models';

import { formatDate } from '../../helpers';

@Pipe({
  name: 'eventLabel',
})
export class EventLabelPipe implements PipeTransform {
  constructor() {}
  transform(event: Event): string {
    if (!event) {
      return null;
    }

    return `${event.title} (${formatDate(
      new Date(event.date_from),
    )} - ${formatDate(new Date(event.date_to))})`;
  }
}
