import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { DocumentScanService } from '../../services/document-scan.service';

import * as fromActions from './actions';

@Injectable()
export class DocumentScanStoreEffects {
  constructor(
    private dataService: DocumentScanService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ image, countryName }) => {
        return this.dataService.request(image, countryName).pipe(
          map((response: any) => {
            return fromActions.loadSuccess({
              response: get(response, 'data[0]', null),
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
