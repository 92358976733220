import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { IResponse } from '@app/core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor(private http: HttpClient) {}

  loadAccommodations(proeprtiesIds: number[]) {
    return this.http.get<IResponse>(
      `accommodations/lookup?${generateParamArray(
        'property_id',
        proeprtiesIds,
      )}&order=order:ASC`,
    );
  }

  loadRateplans(accommodationIds: number[]) {
    return this.http.get<IResponse>(
      `rateplans/lookup?${generateParamArray(
        'accommodation_id',
        accommodationIds,
      )}`,
    );
  }

  loadChannels(proeprtiesIds: number[]) {
    return this.http.get<IResponse>(
      `channelmanager/active_channels?${generateParamArray(
        'property_id',
        proeprtiesIds,
      )}`,
    );
  }
}
