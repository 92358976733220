import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-crm-edit-total-price-form',
  templateUrl: './crm-edit-total-price-form.component.html',
  styleUrls: ['./crm-edit-total-price-form.component.scss'],
})
export class CrmEditTotalPriceFormComponent implements OnInit {
  readonly nzDataModal: Partial<CrmEditTotalPriceFormComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  set totalPrice(total: number) {
    this.totalControl.patchValue(+total.toFixed(2));
  }
  @Input() totalPriceWithoutDiscount: number =
    this.nzDataModal.totalPriceWithoutDiscount || 0;

  @Input() currencySymbol: string = this.nzDataModal.currencySymbol || '';

  @Input() discountExists: boolean = this.nzDataModal.discountExists || false;

  @Input() addonsTotal: number = (this.nzDataModal.addonsTotal = 0);

  @Input() shoAddonsTotal: boolean = this.nzDataModal.shoAddonsTotal || true;

  dailyRatesTotalControl = this.formBuilder.control(0, Validators.required);

  totalControl = this.formBuilder.control(0, Validators.required);

  constructor(private formBuilder: UntypedFormBuilder) {
    this.totalPrice = this.nzDataModal.totalPrice;
  }

  ngOnInit() {}

  formatterCurrency = (value: number) => `${this.currencySymbol} ${value}`;
  parserCurrency = (value: string) =>
    value.replace(`${this.currencySymbol} `, '');
}
