import { createAction, props } from '@ngrx/store';

import { GeneralModules } from '../../models/objects/modules';

export const loadRequest = createAction('[Modules] Load Request');

export const loadSuccess = createAction(
  '[Modules] Load Success',
  props<{ items: GeneralModules[] }>(),
);

export const loadFailure = createAction(
  '[Modules] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Modules] Reset State');
